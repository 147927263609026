.product_tags{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px;
    @media (max-width: 800px){
        display: none;
    }
    .tags{
        width: auto;
        height: 24px;
        border-radius: 50px;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        @include font-primary;
        font-size: 12px;
        line-height: 1;
        font-weight: 500;
        @include letter-spacing(50);
        padding: 0 10px;
    
        &:first-child{
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
        }
        &.featured{
            background-color: #EBE6DE;
            color: #A38C66;
        }
        &.offer{
            background-color: #FF8484;
            color: #fff;
        }    
        &.bestSeller{
            background-color: #A38C66;
            color: #fff;
        }
    }
}


.product_card{
    border: 1px solid #E6E6E6;
    border-radius: 5px;

    .product_image{
        width: 100%;
        padding-top: 100%;
        position: relative;

        .product_tags{
            position: absolute;
            z-index: 2;
            top: 10px;
            left: 0;
            width: 100%;
        }

        .image_container{
            position: absolute;
            z-index: 1;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
            width: 90%;
            height: 90%;

            img{
                padding-top: 10%;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }

    .product_info{
        background-color: #F7F7F7;
        padding: 15px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;

        .product_qty{
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            text-transform: uppercase;
            @include letter-spacing(50);
            margin: 0 0 15px;
            color: #969191;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            @media (max-width: 600px){
                text-align: left;
                justify-content: flex-start;
            }

            span{
                position: relative;
                &:first-child{
                    &::after{
                        content: "|";
                        position: absolute;
                        z-index: 1;
                        right: -10px;
                    }
                }
            }
        }
        .product_title{
            text-align: center;
            margin: 0 0 20px;
            min-height: 48px;
            @include vs-spacing(font-size, 18px, 14px);
            @include vs-spacing(line-height, 24px, 18px);
            @media (max-width: 600px){
                text-align: left;
                margin: 0 0 10px;
                min-height: 60px;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .product_price{
            font-size: 14px;
            line-height: 1;
            font-weight: 600;
            text-align: center;
            margin: 0;
            @media (max-width: 600px){
                text-align: left;
            }
        }
    }

    
    .js_add_to_cart, .in_cart{
        position: absolute;
        bottom: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 5;
        width: 28px;
        height: auto;
        opacity: 0;
        transition: $transition;

        @media (max-width: 1200px){
            width: 20px;
        }
    }
    .in_cart{
        z-index: 6;
        display: none;
    }

    &:hover{
        .js_add_to_cart, .in_cart{
            opacity: 1;
        }
        &.prd_in_cart{
            .js_add_to_cart{
                display: none;
            }
            .in_cart{
                display: block;
            }
        }
    }

}

.product_card_mini{
    background-color: #fff;
    border-radius: 3px;
    padding: 2px;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 15px;
    a{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 15px;
    }
    .product_image{
        width: 80px;
        height: 80px;

        .image_wrapper{
            width: 80px;
            height: 80px;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .product_text{
        width: calc(100% - 95px);
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 10px;
        padding-right: 20px;

        .product_title{
            @include font-primary;
            font-size: 16px;
            line-height: 1;
            margin: 0;
            font-weight: 400;
            color: #1D1D1B;
        }
        .product_cost{
            @include font-primary;
            font-size: 14px;
            line-height: 1;
            margin: 0;
            font-weight: 600;
        }
    }
    .js_add_to_cart_mini, .in_cart_mini{
        position: absolute;
        bottom: 8px;
        right: 8px;
        cursor: pointer;
        z-index: 5;
    }
    .in_cart_mini{
        z-index: 6;
        display: none;
    }

    &.prd_in_cart{
        .js_add_to_cart_mini{
            display: none;
        }
        .in_cart_mini{
            display: block;
        }
    }
}