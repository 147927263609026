.featured_article{
    background-color: #13262F;
    padding: 0 0 50px;
    width: 100%;
    overflow: hidden;
    @media (max-width: 800px){
        background-color: #fff;
    }
    @media (max-width: 600px){
        padding: 0 0 10px;
    }

    .container{
        position: relative;

        &:nth-child(2){
            @media (max-width: 800px){                
                width: 100%;
            }
        }

        .hometitleBar, .article_featured{
            position: relative;
            z-index: 2;
        }
    }
    .bgWhiteAfter{
        &:first-child{
            &::after{                
                content: "";
                background-color: #fff;
                position: absolute;
                z-index: 0;
                width: 140%;
                height: 100%;
                top: 0;
                left: -20%;
                @media (max-width: 800px){ 
                    display: none;
                }
            }
        }
        &:nth-child(2){
            &::after{                
                content: "";
                background-color: #fff;
                position: absolute;
                z-index: 0;
                width: 140%;
                height: 30%;
                top: 0;
                left: -20%;
                @media (max-width: 800px){ 
                    display: none;
                }
            }
        }
    }
    .article_featured{     
        @media (max-width: 800px){ 
            background-color: #13262F;
        }   
        .inner{
            position: relative;
            .img{
                width: 100%;
                padding-bottom: 50%;
                position: relative;
                border-radius: 5px;
                overflow: hidden;
                @media (max-width: 800px){ 
                    border-radius: 0;
                }

                img{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .txt{
                background-color: #13262F;
                position: relative;
                z-index: 5;
                width: 65%;
                margin: -115px auto 0;
                padding: 24px 30px;
                min-height: 230px;
                transition: $transition;
    
                @media (max-width: 1600px){
                    width: 80%;
                }
                @media (max-width: 1200px){
                    width: 90%;
                }
                @media (max-width: 800px){
                    width: 100%;
                    margin: 0;
                    min-height: min-content;
                    padding-bottom: 20px;
                }

                h4{
                    font-size: 14px;
                    line-height: 1;
                    @include letter-spacing(100);
                    color: #A38C66;
                    font-weight: 500;
                    text-align: center;
                    margin: 0 0 30px;
                    text-transform: uppercase;
                    @media (max-width: 1200px){
                        margin: 0 0 20px;
                    }
                }
                h3{
                    @include font-secondary;
                    font-weight: 400;
                    text-align: center;
                    margin: 0 auto 20px;
                    max-width: 80%;
                    color: #fff;
                    @include vs-spacing(font-size, 48px, 32px);
                    @include vs-spacing(line-height, 52px, 34px);
                    @media (max-width: 1200px){
                        max-width: 100%;
                    }
                }
                p{
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(100);
                    text-align: center;
                    margin: 0;
                    text-transform: uppercase;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 15px;
                    color: #fff;
                }
            }
        }
    }
    .article_grid{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 36px;
        padding: 30px 0 0;
        @media (max-width: 800px){ 
            padding: 30px 16px;
            grid-template-columns: 1fr;
            background-color: #13262F;
        }

        a{            
            &:last-child .article_card{
                &::after{
                    display: none;
                }
            }
        }

        .article_card{
            position: relative;
            @media (max-width: 800px){ 
                display: flex;
                justify-content: flex-start;
                width: 100%;
                gap: 15px;
            }
            &::after{
                content: "";
                background-color: #36464E;
                width: 1px;
                height: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                right: -18px;
                @media (max-width: 800px){ 
                   display: none; 
                }
            }
            .image_wrapper{
                width: 100%;
                @media (max-width: 800px){ 
                    width: 35%;
                    min-width: 35%;
                    border-radius: 5px;
                    overflow: hidden;
                }

                .img_inner{
                    width: 100%;                    
                    padding-top: 65%;
                    position: relative;
                    @media (max-width: 800px){
                        padding-top: 95%;
                        border-radius: 5px;
                        overflow: hidden;
                    }
                }

                img{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .text_wrapper{
                padding: 22px 0 0;
                @media (max-width: 800px){
                    padding: 0;
                }
                .article_info{
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(80);
                    color: #A38C66;
                    margin: 0 0 24px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 6px;
                    @media (max-width: 800px){
                        margin: 0 0 15px;
                    }
                }
                .article_title{
                    @include font-secondary;
                    line-height: 1;
                    margin: 0;
                    color: #fff;
                    @include vs-spacing(font-size, 32px, 18px);
                    @include vs-spacing(line-height, 38px, 24px);
                }
            }
        }
    }
}