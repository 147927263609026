@mixin article_text {   
   p{
    @include font-secondary;
    margin: 0;
    padding: 0 0 45px;
    @include letter-spacing(-20);
    @include vs-spacing(font-size, 24px, 20px);
    @include vs-spacing(line-height, 32px, 28px);
   }
   h2{
    @include font-secondary;
    margin: 0;
    padding: 0 0 20px;
    font-weight: 600;
    @include letter-spacing(-20);
    @include vs-spacing(font-size, 34px, 25px);
    @include vs-spacing(line-height, 36px, 28px);
   }
   h3{
    @include font-secondary;
    font-size: 25px;
    line-height: 1;
    padding: 0 0 20px;
    font-weight: 600;
    @include letter-spacing(-20);
    margin: 0;
   }

   ul, ol{
    margin: 0;
    padding: 0 0 45px;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;

    li{
        @include font-secondary;
        font-size: 23px;
        line-height: 1.25;
        padding-left: 30px;
    }
   }
   ul{
    li{
        position: relative;
        &::before{
            content: "";
            display: inline-block;
            width: 7px;
            min-width: 7px;
            height: 7px;
            background-color: #A38C66;
            transform: rotate(45deg);
            position: absolute;
            z-index: 2;
            left: 3px;
            top: 12px;
        }
    }
   }
   ol{
    list-style-type: decimal;
    position: relative;
    left: 24px;

    li{
        padding-left: 8px;
        &::marker{
            @include font-secondary;
            font-size: 23px;
            line-height: 1;
            font-weight: 600;
            color: #A38C66;
        }
    }
   }
}

.article_internal_components{
    padding: 60px 0 45px;
    border-bottom: 1px solid #E6E6E6;
    width: 100%;
    overflow: hidden;
    .article_internal{
        width: 100%;
        max-width: 900px;
        display: block;
        margin: 0 auto;

        @include article_text;

        h2, h3, p, ul, ol{
            padding: 0 80px 45px;
            @media (max-width: 600px){
             padding: 0 0 35px;
            }
        }


        p:first-of-type::first-letter {
            line-height: 1;
            @include vs-spacing(font-size, 65px, 42px);
        }

        .article_text_block{
            &:first-of-type{
                p:first-of-type::first-letter {
                    line-height: 1;
                    @include vs-spacing(font-size, 65px, 42px);
                }
            }
        }
        ul, ol{
            @media (max-width: 600px){
                padding-right: 20px;
            }
            p{
                padding: 0;

                &:first-of-type::first-letter {
                    @include letter-spacing(-20);
                    @include vs-spacing(font-size, 24px, 20px);
                    @include vs-spacing(line-height, 32px, 28px);
                }
            }
        }
    }
}