.article_hero{
    padding: 60px 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-bottom: 1px solid #E6E6E6;
    @media (max-width: 800px){
        padding: 30px 0 0;
    }
    .container{
        @media (max-width: 600px){
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }
    }
    .article_title{
        @include font-secondary;
        margin: 0 auto 20px;
        text-align: center;
        transition: $transition;
        max-width: 50%;
        @include vs-spacing(font-size, 48px, 34px);
        @include vs-spacing(line-height, 52px, 36px);

        @media (max-width: 1200px){
            max-width: 80%;
        }
        @media (max-width: 800px){
            max-width: 90%;
        }
        @media (max-width: 600px){
            max-width: 100%;
            order: 1;
        }
    }
    .article_exerpt{
        @include font-secondary;
        margin: 0 auto 20px;
        text-align: center;
        transition: $transition;
        max-width: 50%;
        @include letter-spacing(-20);
        color: #969191;
        @include vs-spacing(font-size, 24px, 22px);
        @include vs-spacing(line-height, 32px, 30px);
        @media (max-width: 1200px){
            max-width: 80%;
        }
        @media (max-width: 800px){
            max-width: 90%;
        }
        @media (max-width: 600px){
            max-width: 100%;
            order: 3;
            padding: 20px 0 0;
        }

    }
    .article_hero_image{
        display: block;
        margin: 0 auto;
        width: 96%;
        @media (max-width: 800px){
            width: calc(100% + 32px);
            position: relative;
            left: -16px;
        }
        @media (max-width: 600px){
            order: 2;
        }

        .image_wrapper{
            width: 100%;
            position: relative;
            padding-top: 45%;
            border-radius: 5px;
            overflow: hidden;
            @media (max-width: 800px){
                border-radius: 0;
            }

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .articel_info{
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        width: 96%;
        padding: 15px 0;
        @media (max-width: 800px){
            width: calc(100% + 32px);
            position: relative;
            left: -16px;
            padding: 15px 10px;
        }
        @media (max-width: 600px){
            order: 4;
            border-top: 1px solid #E6E6E6;
        }

        ul{
            display: flex;
            justify-content: center;
            align-items: center;
            @media (max-width: 1100px){
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;
                width: 100%;
            }

            li{
                display: grid;
                grid-template-columns: 1fr;
                gap: 10px;
                min-width: 240px;
                padding: 0 20px;
                font-size: 18px;
                line-height: 1;
                @include letter-spacing(30);
                border-right: 1px solid #E6E6E6;
                @media (max-width: 1100px){
                    min-width: inherit;
                    padding: 0 10px;
                }

                &:last-child{
                    border: 0;
                }
                &:nth-child(2){
                    @media (max-width: 1100px){
                        border: 0;
                    }
                }

                span{
                    font-weight: 600;
                    &:last-child{
                        font-weight: 400;
                        font-size: 14px;
                        color: #969191;
                    }
                }
            }
        }
    }
}