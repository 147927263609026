.explore_recipe_categories{
    background-color: #F4F3EE;
    padding: 25px 0 50px;
    margin-bottom: 60px;
    @media (max-width: 600px){
        padding: 0 0 50px;
        margin-bottom: 20px;
    }

    .explore_recipe_grid{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 30px;
        padding: 20px 0 36px;
        border-top: 1px solid #EBE6DE;
        border-bottom: 1px solid #EBE6DE;

        @media (max-width: 900px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 600px){
            grid-template-columns: 1fr;
        }

        .recipe_card{ 
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 15px;
            width: 100%;

            .image_wrapper{
                width: 35%;
                min-width: 35%;

                .img{
                    width: 100%;
                    padding-top: 100%;
                    position: relative;
                    border-radius: 5px;
                    overflow: hidden;

                    img{
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            .text_wrapper{
                .category{
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(80);
                    color: #A38C66;
                    text-transform: uppercase;
                    margin: 0 0 20px;
                }
                .recipe_title{
                    @include font-secondary;
                    @include letter-spacing(-20);
                    margin: 0;
                    @include crop-text(3);
                    @include vs-spacing(font-size, 20px, 18px);
                    @include vs-spacing(line-height, 25px, 22px);
                }
            }
        }
    }

    .explore_categories{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        padding-top: 50px;
        @media (max-width: 900px){
            grid-template-columns: 1fr;
        }
        @media (max-width: 600px){
            padding-top: 10px;
        }

        .slider{
            grid-area: 1 / 2 / 2 / 6;
            @media (max-width: 900px){
                grid-area: 1 / 1 / 2 / 2;
                min-width: 100%;
            }
        }
    }
    
    .categories{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        @media (max-width: 900px){
            display: none;
        }

        .home_section_title{
            max-width: 80%;
        }

        .bottom{
            ul{
                display: grid;
                grid-template-columns: 1fr;
                gap: 18px;
                margin-bottom: 30px;
                li{
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(50);
                    text-transform: capitalize;
                }
            }
        }
    }
    .category_link{        
        &:first-child{
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        &:last-child{
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .featured_category{
        position: relative;
        overflow: hidden;
        @media (max-width: 1200px){
            border-radius: 5px;
            overflow: hidden;
        }
        .image_wrapper{
            width: 100%;
            padding-top: 135%;
            position: relative;

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .featured_cat_overlay{
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text_wrapper{
            position: absolute;
            z-index: 5;
            bottom: 0;
            left: 0;
            width: 100%;

            .category_title{
                @include font-secondary;
                font-size: 32px;
                line-height: 1;
                @include letter-spacing(50);
                margin: 0 0 18px;
                color: #fff;
                width: 100%;
                text-align: center;
                text-transform: uppercase;
            }
            .read_more{
                font-size: 12px;
                line-height: 1;
                font-weight: 500;
                text-transform: uppercase;
                @include letter-spacing(100);
                display: inline-flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                gap: 10px;
                width: 100%;
                margin: 0 0 24px;
            }
        }
    }

    .mobile_category_slider{
        padding-top: 20px;
        display: none;
        @media (max-width: 900px){
            display: block;
        }
       .swiper-slide{
        width: auto;

        a{
            border: 1px solid #969191;
            color: #969191;
            height: 44px;
            padding: 0 22px; 
            border-radius: 3px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            @include letter-spacing(10);
        }
       } 
    }
}