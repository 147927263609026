.productslider{
    padding: 0 0 60px;
    @media (max-width: 600px){
        padding: 0 0 50px;
    }
}

.slider{
    position: relative;

    .swiper-button-next-custom, .swiper-button-prev-custom{
        position: absolute;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        background-color: #fff;
        border: 1px solid #E6E6E6;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        cursor: pointer;
        @media (max-width: 800px){
            display: none;
        }
    }
    .swiper-button-prev-custom{
        right: auto;
        left: -20px;
    }
    .swiper-button-disabled{
        pointer-events: none;

        img, svg{
            opacity: 0.5;
        }
    }
    .swiper-button-lock{
        display: none;
    }

    .swiper{
        @media (max-width: 1000px){
            overflow: visible;
        }
    }
}

