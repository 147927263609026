.product_details_info{
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6;

    ul{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 70px;

        @media (max-width: 1000px){
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            height: min-content;
        }

        li{
            display: grid;
            grid-template-columns: 1fr;
            min-width: 220px;
            gap: 20px;
            border-right: 1px solid #E6E6E6;
            padding: 0 20px;
            @media (max-width: 1000px){
                border-right: 0;
                padding: 25px 0;
                min-width: inherit;
            }
            &:first-child{
                padding-left: 0;
                @media (max-width: 1000px){
                    border-bottom: 1px solid #E6E6E6;
                }
            }
            &:nth-child(2){
                @media (max-width: 1000px){
                    border-bottom: 1px solid #E6E6E6;
                }
            }
            &:last-child{
                border-right: 0;
                @media (max-width: 1000px){
                    display: none;
                }
            }

            span{
                &:first-child{
                    font-size: 18px;
                    line-height: 1;
                    font-weight: 600;
                    @include letter-spacing(30);
                }
                &:last-child{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 400;
                    @include letter-spacing(30);
                    color: #969191;
                }
            }
            
            &.review{
                span{
                    &:first-child{
                        color: #A38C66;
                    }
                }
                .bottom{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    span{                        
                        font-size: 14px;
                        line-height: 1;
                        font-weight: 400;
                        @include letter-spacing(30);
                        color: #A38C66;

                        &:last-child{
                            color: #13262F;
                            cursor: pointer;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}