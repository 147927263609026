.featured_product_slider{
    padding: 0 0 60px;
    @media (max-width: 600px){
        padding: 0 0 10px;
    }
    .inner{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 20px;
        width: 100%;

        .slider{
          width: calc(100% - 480px); 
          
          @media (max-width: 900px){
            width: 100%;
          }
        }

        a.cat_link{
            display: block;
            @media (max-width: 900px){
              width: 100%;
            }
        }

        .category_range{
            width: 460px;
            height: inherit;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            @media (max-width: 900px){
              width: 100%;
            }

            .recipe_image{
                width: 100%;
                padding-top: 512px;
                border-radius: 5px;
                overflow: hidden;
                @media (max-width: 900px){
                    padding-top: 100%;
                }

                svg{
                    position: absolute;
                    z-index: 3;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: auto;
                }

                img{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .recipe_text{
                position: absolute;
                z-index: 5;
                bottom: 0;
                left: 0;
                width: 100%;
                padding: 0 0 30px;

                .recipe_title{
                    @include font-secondary;
                    font-size: 32px;
                    line-height: 1;
                    color: #fff;
                    margin: 0 0 16px;
                    text-align: center
                }
                .readMore{
                    @include font-primary;
                    font-size: 12px;
                    line-height: 1;
                    font-weight: 500;
                    text-transform: uppercase;
                    @include letter-spacing(100);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    color: #fff;
                    gap: 10px;
                    width: 100%;
                }
            }
        }
    }
}