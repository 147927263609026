.megamenu{
    position: fixed;
    z-index: 95;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.open{
        display: block;
    }

    .megamenu_overlay{
        position: absolute;
        z-index: 96;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0);
        backdrop-filter: blur(2px);
    }
    .menu{
        position: absolute;
        z-index: 97;
        top: 120px;
        width: 100%;
        background-color: #fff;
        height: 360px;
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
        display: flex;
        gap: 45px;
        padding: 40px 30px;

        .menu_section{
            flex: 1;
            max-width: 280px;

            h3{
                font-size: 20px;
                line-height: 1;
                font-weight: 600;
                margin: 0 0 25px;
                color: #0E0E0E;
            }
        }
        .multi_column{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            height: auto;
            min-height: 190px;
            max-height: 190px;
            overflow-y: auto;
            @include scroll_style;

            li{
                font-size: 16px;
                line-height: 1;
                font-weight: 400;
                max-height: 20px;
                color: #222222;
            }
        }
        .shop_all{
            display: inline-block;
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            margin-top: 20px;
            color: #A38C66;
        }
    }
}

#cms-top ~ .megamenu{
    .menu{
        top: 166px;
    }
}