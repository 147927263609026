header{
    position: fixed;
    z-index: 99;
    top: 0;
    left: 0;
    width: 100%;
    height: 120px;
    display: grid;
    grid-template-columns: 1fr;
    background-color: #fff;
    overflow: hidden;
    @include font-primary;
    transition: $transition;
    @media (max-width: 720px){
        height: fit-content;
        max-height: 165px;
    }

    &.scrolled{
        box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.1);
    }

    .top_bar{
        width: 100%;
        height: 70px;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #F2F2F2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        gap: 50px;
        @media (max-width: 1200px){
            padding: 0 16px;
        }
        @media (max-width: 720px){
            justify-content: space-between;
            height: min-content;
            gap: 12px;
            padding: 20px 16px 5px;
            border-bottom: 0;
        }

        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            @media (max-width: 720px){
                order: 1;
            }

            .logo img{
                @media (max-width: 1200px){
                    height: 26px;
                    width: auto;
                }
            }
        }
        
        .mobile_menu{
            display: none;
            appearance: none;
            border: 0;
            background-color: transparent;
            @media (max-width: 1200px){
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .searchbar{
            flex-grow: 1;
            transition: $transition;
            @media (max-width: 720px){
                order: 3;
                min-width: 100%;
            }

            form{
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                height: 70px;
                @media (max-width: 720px){
                    height: min-content;
                }

                input{
                    background-color: #F7F7F7;
                    width: 100%;
                    border-radius: 10px;
                    border-color: transparent;
                    padding: 0 50px 0 18px;
                    line-height: 1;
                    font-weight: 500;
                    @include vs-spacing(font-size, 18px, 16px);
                }
                button{
                    border: 0;
                    background-color: transparent;
                    position: absolute;
                    z-index: 5;
                    top: 50%;
                    right: 10px;
                    transform: translateY(-50%);
                }
            }
        }

        .userAction{
            min-width: 100px;
            @media (max-width: 720px){
                order: 2;
            }
            ul{                
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 20px;

                li{                    
                    @include font-primary;
                    font-size: 18px;
                    line-height: 1;
                    font-weight: 500;
                    position: relative;
                    
                    &.sign_up_btn a{
                        background-color: #A38C66;
                        height: 40px;
                        border-radius: 50px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        color: #fff;
                        padding: 0 30px;

                        @media (max-width: 1200px){
                            display: none;
                        }
                    }

                    .basketLines{
                        position: absolute;
                        z-index: 2;
                        font-size: 12px;
                        line-height: 1;
                        font-weight: 600;
                        top: -7px;
                        right: 2px;
                        color: #000;
                        opacity: 0.6;
                        transition: $transition;
                    }

                    img{
                        width: auto;
                        height: 18px;
                        margin: 0 5px;
                        opacity: 0.6;
                        transition: $transition;
                    }

                    &:hover{
                        img,span{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
    .bottom_bar{
        width: 100%;
        height: 50px;
        padding: 0 30px;
        box-sizing: border-box;
        border-bottom: 1px solid #F2F2F2;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: 1200px){
            grid-template-columns: 1fr;
            padding: 0 16px;
        }

        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 50px;
            gap: 20px;
            @media (max-width: 1200px){
                display: none;
            }

            .menu{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
                cursor: pointer;
                line-height: 1;
                font-weight: 500;
                @include vs-spacing(font-size, 16px, 14px);

                span{
                    display: inline-block;
                    margin-right: 15px;
                    font-weight: 500;
                }
            }

            .info{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 20px;
                gap: 30px;
                box-sizing: border-box;
                border-left: 1px solid #F2F2F2;
                height: 50px;
                line-height: 1;
                font-weight: 400;
                @include vs-spacing(font-size, 16px, 14px);

                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }
            }
        }

        .right{
            display: flex;
            justify-content: flex-end;
            align-items: center;

            @media (max-width: 1200px){
                display: none;
            }

            .nav{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 30px;

                li{                    
                    @include font-primary;
                    line-height: 1;
                    font-weight: 500;
                    @include vs-spacing(font-size, 16px, 14px);

                    a.offer{
                        color: #E12C2C;
                    }
                }
            }
        }
        .bottom{
            display: none;
            @media (max-width: 1200px){
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
            .info{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding: 0 20px 0 0;
                gap: 30px;
                box-sizing: border-box;
                height: 50px;
                line-height: 1;
                font-weight: 500;
                @include vs-spacing(font-size, 16px, 14px);

                div{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                }

                .address{
                    @media (max-width: 600px){
                        display: none;
                    }
                }
            }
        }
    }
}

#cms-top + header{
    top: 46px;
}