footer{
    background-color: #F4F3EE;
    padding: 60px 0 30px;
    width: 100%;
    overflow: hidden;
    @media (max-width: 1200px){
        padding: 40px 0 30px;
    }

    .logo{
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .newsletter{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 0;
        @media (max-width: 1200px){
            padding: 20px 16px;
        }

        p{
            @include font-secondary;
            @include letter-spacing(-20);
            line-height: 27px;
            margin: 0 0 25px;
            text-align: center;
            @include vs-spacing(font-size, 20px, 18px);
        }

        form{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            width: 100%;

            input{
                height: 60px;
                border-radius: 0;
                background-color: transparent;
                padding: 0 20px;
                width: 100%;
                max-width: 570px;
            }

            button{
                height: 60px;
                width: 60px;
                border: 0;
                background-color: transparent;
            }
        }
    }

    .bottom{
        display: flex;
        justify-content: center;
        align-items: center;
        color: #6F6F6E;
        width: 100%;
        padding:  0 35px;
        @media (max-width: 1200px){
            flex-direction: column;
            gap: 20px;
        }

        .copywrite, .madeby{
            @media (max-width: 1200px){
                order: 2;
            }
            p{
                font-size: 10px;
                font-weight: 300;
                line-height: 1;
                text-transform: uppercase;
                color: #6F6F6E;
                @include letter-spacing(300);
                margin: 0;

                span, a{
                    color: #9B6F54;
                }
            }
        }
        .madeby{            
            @media (max-width: 1200px){
                display: none;
            }
        }

        .quicklinks{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-grow: 1;
            @media (max-width: 1200px){
                order: 1;
            }

            ul{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 20px;
                li{
                    font-size: 10px;
                    font-weight: 300;
                    line-height: 1;
                    text-transform: uppercase;
                    color: #6F6F6E;
                    @include letter-spacing(300);
                    a{
                        color: #6F6F6E;
                    }

                    &::after{
                        content: "|";
                        padding-left: 8px;
                    }

                    &:last-child{
                        &::after{
                            content: "";
                        }
                    }
                }
            }
        }
    }
    .footer_accordion{
        display: none;
        margin-bottom: 20px;

        @media (max-width: 1200px){
            display: block;
        }

        .accordion-item {
            border-bottom: 1px solid #E6E6E6;

            &:first-child{                
                border-top: 1px solid #E6E6E6;
            }

            .accordion-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding:  0 0;
                cursor: pointer;
                height: 50px;
                -webkit-tap-highlight-color: transparent;
                user-select: none;

                h3 {
                    margin: 0;
                    font-size: 14px;
                    line-height: 1;
                    @include letter-spacing(20);
                    color: #969191;
                }

                .arrow.rotate {
                    transform: rotate(180deg);
                }
            }
            .accordion-content {
                display: none;
                padding: 10px 0 30px;

                ul{
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 15px;
                    li{
                        font-size: 14px;
                        line-height: 1;
                        @include letter-spacing(20);
                        color: #969191;
                    }
                }
            }
        }
    }
}