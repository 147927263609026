.shop_title_block{
    padding: 25px 0 45px;
    overflow: visible;
    overflow-x: visible;

    @include breadcumb;
    .page_title{
        @include font-secondary;
        margin: 0 0 20px;
        font-weight: 700;
        color: #0E0E0E;
        @include vs-spacing(font-size, 32px, 20px);
        @include vs-spacing(line-height, 40px, 28px);
    }
    .category_description{
        margin: 0 0 30px;
        width: 100%;
        max-width: 1100px;
        color: #0E0E0E;
        @include crop-text(2);
        @include vs-spacing(font-size, 18px, 16px);
        @include vs-spacing(line-height, 24px, 28px);
        @media (max-width: 1200px){
            margin: 0;
        }
        

        a{
            color: #A38C66;
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }
    .filters{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        gap: 8px;
        width: 100%;

        @media (max-width: 600px){
            flex-direction: column;
        }

        .left{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-wrap: wrap;
            gap: 8px;
            width: calc(100% - 121px);
            @media (max-width: 600px){
                width: 100%;
            }

            .all-filters{
                @media (max-width: 600px){
                    display: none;
                }
            }

            .categroy_slider{
                width: calc(100% - 144px);
                position: relative;
                @media (max-width: 600px){
                    width: 100%;
                }

                &::after{
                    position: absolute;
                    z-index: 10;
                    top: 0;
                    right: 0;
                    content: "";
                    width: 60px;
                    height: 44px;
                    background: rgb(255,255,255);
                    background: linear-gradient(267deg, rgba(255,255,255,1) 41%, rgba(255,255,255,0) 100%);
                    @media (max-width: 600px){
                        display: none;
                    }
                }

                .swiper-slide{
                    width: auto;
                }
            }
        }
        .right{            
            @media (max-width: 600px){
                width: 100%;
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
            }

            .all-filters{
                display: none;
                @media (max-width: 600px){
                    display: inline-flex;
                }
            }
            .sort_menu{
                position: relative;

                a{
                    @media (max-width: 600px){
                        display: inline-flex;
                        width: 100%;
                    }
                }

                ul{  
                    position: absolute;                  
                    z-index: 99;
                    top: 44px;
                    right: 0;
                    background-color: #fff;
                    display: none;
                    grid-template-columns: 1fr;
                    gap: 2px;
                    min-width: 175px;
                    box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
                    padding: 10px 0;
                    &.open{
                        display: grid;
                    }
                    @media (max-width: 600px){
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow-y: auto;
                        gap: 8px;
                        @include scroll_style;
                        &.open{
                            display: block;
                        }
                    }

                    .topbar{
                        display: none;
                        padding: 10px 15px;
                        justify-content: space-between;
                        align-items: center;
                        @media (max-width: 600px){
                            display: flex;
                        }
            
                        h3{
                            font-size: 26px;
                            font-weight: 500;
                            line-height: 1;
                            @include letter-spacing(10);
                            margin: 0;
                        }

                        button{
                            appearance: none;
                            background-color: #fff;
                            border: 0;
                            outline: none;
                        }
                    }


                    li{
                        cursor: pointer;
                        background-color: #fff;
                        transition: $transition;
                        padding: 8px 15px;
                        font-size: 18px;
                        line-height: 1;
                        @media (max-width: 600px){
                            padding: 15px;
                        }

                        &:hover, &.active{
                            background-color: rgb(243, 243, 243);
                        }
                    }
                }
            }
        }
        
        a{
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            font-size: 16px;
            line-height: 1;
            @include letter-spacing(10);
            color: #969191;
            border: 1px solid #969191;
            height: 44px;
            border-radius: 3px;
            padding: 0 16px;
            transition: $transition;
            text-transform: capitalize;

            &:hover, &.active{
                background-color: #13262F;
                border-color: #13262F;
                color: #fff;

                svg{
                    path{
                        fill: #fff;
                    }
                }
            }

            &.all-filters{
                border-color: #13262F;
                color: #13262F;

                &:hover{
                    color: #fff;

                    svg{
                        line{
                            stroke: #fff;
                        }
                    }
                }
            }
        }
    }
}