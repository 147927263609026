.article_grid{
    .article_list{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        column-gap: 20px;
        row-gap: 40px;

        @media (max-width: 2100px){            
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 1400px){            
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 1200px){  
            display: none;
        }
    }
    .slider{
        display: none;
        @media (max-width: 1200px){  
            display: block;
        }

        .swiper{
            overflow: visible;
        }
    }

    &.index_page{
        .article_list{
            @media (max-width: 1200px){  
                grid-template-columns: repeat(2, 1fr);
                display: grid;
            }
            @media (max-width: 600px){  
                grid-template-columns: 1fr !important;
                gap: 40px !important;
            }
        }
    }
}

.article_grid, .article_slider, .interview_slider{
    padding: 0 0 60px;
    width: 100%;
    overflow: hidden;
    
    .article_card{
        .artilce_image{
         width: 100%;
         padding-top: 65%;
         position: relative;
         border-radius: 5px;
         overflow: hidden;
     
         img{
             width: 100%;
             height: 100%;
             position: absolute;
             z-index: 1;
             top: 0;
             left: 0;
             object-fit: cover;
         }
        } 
        .article_text{
         padding: 24px 0 0;
         
         p{
             font-size: 12px;
             line-height: 1;
             font-weight: 500;
             @include letter-spacing(80);
             margin: 0 0 15px;
             color: #A38C66;
             display: inline-flex;
             justify-content: flex-start;
             align-items: center;
             gap: 6px;
             text-transform: uppercase;
         }
         .article_title{
             @include font-secondary;        
             margin: 0;
             @include vs-spacing(font-size, 32px, 28px);
             @include vs-spacing(line-height, 36px, 32px);
         }
        }
     }
}

.interview_slider{
    .article_card{
        position: relative;

        &::after{
            position: absolute;
            content: "";
            width: 1px;
            height: 100%;
            border-right: 1px solid #E6E6E6;
            top: 0;
            right: -15px;

            @media (max-width: 1200px){
                display: none;
            }
        }
    }

    .swiper-slider{
        &:last-child{
            .article_card{
                position: relative;
        
                &::after{
                    display: none;
                }
            }
        }
    }
}

.article_category_Title{
    width: 100%;
    padding: 100px 0 40px;
    @media (max-width: 1200px){
        padding: 80px 0 40px;
    }
    @media (max-width: 600px){
        padding: 60px 0 40px;
    }

    .container{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        h1{
            @include font-secondary;
            margin: 0 auto 20px;
            text-align: center;
            transition: $transition;
            max-width: 50%;
            font-weight: 700;
            @include vs-spacing(font-size, 48px, 34px);
            @include vs-spacing(line-height, 52px, 36px);
    
            @media (max-width: 1200px){
                max-width: 80%;
            }
            @media (max-width: 800px){
                max-width: 90%;
            }
            @media (max-width: 600px){
                max-width: 100%;
            }
        }
    }
}