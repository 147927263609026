.article_products_block{
    padding: 0 0 45px;

    .swiper{        
        overflow: visible;
    }

    .swiper-slide{
        .product_card_mini{
            border: 1px solid #E6E6E6;

            .product_text{
                padding-right: 10px;
            }
        }
    }
}