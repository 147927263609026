.homeHero{
    position: relative;
    padding:  0 0 60px;
    @media (max-width: 600px){
        padding: 0 0 20px;
    }
    .container{        
        @media (max-width: 800px){
            width: 100%;
            margin: 0;
        }
    }
    .inner{
        position: relative;
        @media (max-width: 800px){
            padding-bottom: 20px;
        }
        .img{
            width: 100%;
            padding-bottom: 40%;
            position: relative;
            @media (max-width: 800px){
                padding-bottom: 60%;
            }

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .txt{
            background-color: #fff;
            position: relative;
            z-index: 5;
            width: 65%;
            margin: -115px auto 0;
            padding: 24px 30px;
            min-height: 230px;
            transition: $transition;

            @media (max-width: 1600px){
                width: 80%;
                min-height: min-content;
            }
            @media (max-width: 1200px){
                width: 90%;
            }
            @media (max-width: 800px){
                width: 100%;
                margin: 0;
                padding-bottom: 0;
            }

            h4{
                font-size: 14px;
                line-height: 1;
                @include letter-spacing(100);
                color: #A38C66;
                font-weight: 500;
                text-align: center;
                margin: 0 0 30px;
                text-transform: uppercase;
                @media (max-width: 1200px){
                    margin: 0 0 20px;
                }
            }
            h3{
                @include font-secondary;
                font-weight: 400;
                text-align: center;
                margin: 0 auto 20px;
                max-width: 80%;
                @include vs-spacing(font-size, 48px, 32px);
                @include vs-spacing(line-height, 52px, 34px);
                @media (max-width: 1200px){
                    max-width: 100%;
                }
            }
            p{
                font-size: 12px;
                line-height: 1;
                font-weight: 500;
                @include letter-spacing(100);
                text-align: center;
                margin: 0;
                text-transform: uppercase;
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 15px;
            }
        }
    }
    .featured_departments{
        padding: 30px 16px;
        border-top: 1px solid #E6E6E6;
        border-bottom: 1px solid #E6E6E6;
        display: none;

        @media (max-width: 600px){
            display: block;
        }

        .grid{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 10px;
            padding: 25px 0 0;

            .column{
                .image_wrapper{
                    width: 100%;
                    border-radius: 3px;
                    overflow: hidden;

                    .img{
                        width: 100%;
                        position: relative;
                        padding-top: 100%;

                        img{
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
                .text_wrapper{
                    h4{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        @include letter-spacing(30);
                        text-align: center;
                        margin: 0;
                        padding: 12px 0 15px;
                        color: #484848;
                    }
                }
            }
        }
        .see_all{
            text-align: center;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            @include letter-spacing(100);
            color: #A38C66;
            text-transform: uppercase;

            a{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                gap: 10px;
            }
        }
    }
}