.login_forms{
    .content{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 100%;
        padding: 60px 0;
        min-height: 60vh;

        .form_card{
            width: 100%;
            max-width: 420px;
            border-radius: 10px;
            background-color: #fff;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.12);

            h2{
                font-size: 26px;
                line-height: 1;
                font-weight: 600;
                margin: 0 0 24px;
                padding:  20px 20px 0;
            }
            h4{
                font-size: 14px;
                line-height: 1;
                padding: 0 20px;
                @include letter-spacing(30);   
                color: #969191;
            }
            form{
                padding: 0 20px 30px;
                border-bottom: 1px solid #E6E6E6;
            }
            button[type="submit"]{
                width: 100%;
                border: 0;
                background-color: #13262F;
                height: 44px;
            }
            p{ 
                font-size: 14px;
                line-height: 1;
                @include letter-spacing(30);   
                margin: 0;
                padding: 15px 20px;
                
                &.bg-off-white{
                    background-color: #F7F7F7;
                }
                > a{                    
                    color: #A38C66;
                    text-decoration: underline;
                }
            }
            .sign_up{
                width: 100%;
                padding: 0 20px 20px;
                display: flex;
                justify-content: center;
                align-items: center;

                a{
                    width: 100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                button{
                    width: 100%;
                }
            }
        }
        .or{
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
            max-width: 420px;
            padding: 24px 0;
            .line{
                width: auto;
                height: 1px;
                background-color: #E6E6E6;
                flex-grow: 1;
            }
            span{
                font-size: 16px;
                line-height: 1;
                @include letter-spacing(10);
            }
        }
    }
}

.twoFa_container{
    margin: 100px auto;
    width: 100%;
    max-width: 420px;
    padding: 20px;
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    background-color: #fff;

    input.hidden-input{
        display: none;
    }

    h3{
        text-align: center;
    }

    label{
        text-align: center;
    }

    .otp-container{
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        gap: 20px;

        input{
            text-align: center;
        }
    }
    button{
        &.disabled{
            opacity: 0.6;
            background-color: #F7F7F7;
        }
    }
}

.auth-method-chooser{
    position: fixed;
    z-index: 102;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;
    justify-content: center;
    align-items: center;

    &.open{
        display: flex;
    }

    .auth-overlay{
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
    }

    .auth-method{
        position: relative;
        z-index: 12;
        width: 100%;
        max-width: 520px;
        background-color: #fff;
        padding: 20px;
        border-radius: 10px;

        h3{
            text-align: center;
        }

        input{
            display: none;
        }
        label{
            width: 100%;
            padding: 10px 10px;
            border-radius: 6px;
            border: 1px solid #e6e6e6;
            font-size: 14px;
            line-height: 1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;
            transition: $transition;
            cursor: pointer;

            &:hover{
                border-color: #000;
            }

            svg{
                width: 25px;
                height: auto;
            }
        }
        input:checked + label{
            border-color: #000;
            background-color: #f3f3f3;
        }
        button{
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            &.loading{
                pointer-events: none;
                opacity: 0.6;
            }
        }
    }
    .close--popup{
        position: absolute;
        z-index: 15;
        top: 20px;
        right: 20px;
        cursor: pointer;
    }
    .loading-state{
        position: absolute;
        z-index: 15;
        top: 17px;
        right: 17px;
        cursor: pointer;
        display: none;
        justify-content: center;
        align-items: center;

        &.loading{
            display: flex;
        }
        
        .loading {
            width: 22px;
            height: 22px;
            border-radius: 50%;
            border: 2px solid #ddd;
            border-top-color: #13262F;
            animation: loading 1s linear infinite;
        }
    }
}

@keyframes loading {
    to {
      transform: rotate(360deg);
    }
  }
  