@mixin letter-spacing($spacing) {
    letter-spacing: calc($spacing / 1000) * 1em;
}

/**** VS-SPACING ****/
$resolution-range-bottom: 380px;
$resolution-range-top: 2500px;

@function get-linear-size($mobile-size, $desktop-size) {
    $a: calc(calc($desktop-size - $mobile-size) / calc($resolution-range-top - $resolution-range-bottom));
    $b: -$a * $resolution-range-bottom;
    @return calc(#{$a} * 100vw + #{$b + $mobile-size});
}

@mixin vs-spacing($field, $desktop-size, $mobile-size) {
    & {#{$field}: get-linear-size($mobile-size, $desktop-size)};

    @media screen and (min-width: 1919px) {
        & {#{$field}: $desktop-size};
    }
    @media screen and (max-width: 380px) {
        & {#{$field}: $mobile-size};
    }
}


@mixin font-primary{
    font-family: "proxima-nova", sans-serif;
    font-style: normal;
}

@mixin font-secondary{
    font-family: "mrs-eaves", serif;
    font-style: normal;
}

@mixin crop-text($clamp)  {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $clamp;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin  scroll_style {    
    &::-webkit-scrollbar {
        width: 5px;
    }

    &::-webkit-scrollbar-track {
        box-shadow: none;
        background-color: #bdbdbd;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #4e4e4e;
        border-radius: 3px;
    }
}

@mixin breadcumb {
    .breadcrumbs{
        padding: 0 0 30px;
        ul{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 36px;

            li{
               font-size: 18px;
               line-height: 1;
               position: relative; 
               color: #0E0E0E;

               a{
                transition: $transition;
                &:hover{
                    color: #969191;
                }
               }

               svg{
                position: absolute;
                z-index: 2;
                top: 50%;
                right: -22px;
                transform: translateY(-50%);
               }
            }
        }
    }
}