.home_section_title{
    @include font-secondary;
    @include letter-spacing(50);
    font-weight: 700;
    margin: 0;  
    @include vs-spacing(font-size, 32px, 20px);
    @include vs-spacing(line-height, 40px, 28px);
    
    span{
        @include font-primary;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        line-height: 24px;
        font-weight: 500;
        text-transform: uppercase;
        color: #fff;
        @include letter-spacing(50);
        height: 24px;
        padding: 0 16px;
        margin-left: 30px;
        background-color: #13262F;
        border-radius: 3px;

        @media (max-width: 900px){
            display: none;
        }
    }
}

.home_viewAll{
    @include font-primary;
    font-size: 12px;
    line-height: 1;
    font-weight: 500;
    text-transform: uppercase;
    @include letter-spacing(100);
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    color: #0E0E0E;
    gap: 10px;

    span{
        font: inherit;
        color: inherit;
    }
}

.hometitleBar{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
}