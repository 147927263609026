.explore_videos{
    padding: 60px 0;
    background-color: #13262F;
    @media (max-width: 1200px){
        padding: 20px 0 60px;
    }

    .hometitleBar{
        .home_section_title, .home_viewAll{
            color: #fff;
        }
    }

    .video_flex{
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 30px;

        .thumbnail_overlay{
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: #989897;
            mix-blend-mode: multiply;
            transition: $transition;
        }

        .featured_video{
            width: calc(100%  - 390px);
            position: relative;
            @media (max-width: 1200px){
                width: 100%;
                min-width: 100%;
            }
            .video_thumbnail{
                position: relative;
                width: 100%;
                padding-top: 56%;
                @media (max-width: 1200px){
                    width: calc(100% + 32px);
                    left: -16px;
                }

                img{
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
            .video_text{
                position: absolute;
                z-index: 3;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                cursor: pointer;

                @media (max-width: 1200px){
                    position: relative;
                    height: auto;
                    padding: 30px 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column-reverse;
                    border-bottom: 1px solid #E6E6E6;
                }

                .video_title{
                    position: absolute;
                    z-index: 5;
                    top: 50%;
                    left: 50%;
                    transform: translateY(-50%) translateX(-50%);
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    margin: 0;
                    max-width: 60%;
                    cursor: pointer;
                    @include font-secondary;
                    @include vs-spacing(font-size, 48px, 34px);
                    @include vs-spacing(line-height, 52px, 38px);

                    @media (max-width: 1200px){
                        position: relative;
                        max-width: 80%;
                        top: auto;
                        left: auto;
                        transform: none;
                    }
                    @media(max-width: 600px){
                        width: 100%;
                    }
                }

                .bottom{
                    position: absolute;
                    z-index: 4;
                    bottom: 0;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;
                    gap: 20px;
                    width: 100%;
                    padding: 0 0 30px;
                    @media (max-width: 1200px){
                        position: relative;
                        bottom: auto;
                        left: auto;
                        padding: 0;
                    }

                    svg{
                        cursor: pointer;
                        @media (max-width: 1200px){
                            display: none;
                        }
                    }

                    p{
                        font-size: 12px;
                        line-height: 1;
                        @include letter-spacing(80);
                        font-weight: 500;
                        color: #fff;
                        margin: 0;
                        @media (max-width: 1200px){
                            color: #A38C66;
                            margin: 0 0 20px;
                            text-transform: uppercase;
                            .watch_now, .seperator{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
        .video_list{
            width: 360px;
            height: auto;
            overflow-y: auto;
            padding-right: 15px;
            @media (max-width: 1200px){
                width: 100%;
                min-width: 100%;
                display: grid;
                grid-template-columns: 1fr;
                gap: 15px;
            }
            
            &::-webkit-scrollbar {
                width: 6px;
            }

            &::-webkit-scrollbar-track {
                box-shadow: none;
                background-color: #2B3C44;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #415057;
                outline: none;
            }
        }
    }
}


.video_card{
    position: relative;
    @media (max-width: 1200px){
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
    }

    .video_thumbnail{
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        width: 100%;
        cursor: pointer;
        @media (max-width: 1200px){
            width: 35%;
            min-width: 35%;
        }

        .thumbnail_overlay, svg{
            opacity: 0;
        }
        .video_duration{
            position: absolute;
            z-index: 6;
            top: 10px;
            left: 10px;
            background-color: rgba(255,255,255, 0.8);
            height: 25px;
            padding: 0 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 12px;
            line-height: 1;
            @include letter-spacing(80);
            @media (max-width: 1200px){
                display: none;
            }

        }

        svg{
            position: absolute;
            z-index: 5;
            top: 50%;
            left: 50%;
            transform: translateY(-50%) translateX(-50%);
        }
        .image_wraper{
            position: relative;
            z-index: 1;
            width: 100%;
            padding-top: 65%;
            @media (max-width: 1200px){
                padding-top: 100%;
            }
        }
        img{
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .video_text{
        padding: 20px 0;
        @media (max-width: 1200px){
            padding: 0;
        }
        .video_duration{
            display: none;
            font-size: 12px;
            line-height: 1;
            font-weight: 500;
            @include letter-spacing(80);
            color: #A38C66;
            margin: 0;
            padding: 0 0 15px;
            text-transform: uppercase;
            @media (max-width: 1200px){
                display: block;
            }
        }
        .video_title{
            @include font-secondary;
            color: #fff;
            margin: 0 0 5px;
            @include vs-spacing(font-size, 32px, 20px);
            @include vs-spacing(line-height, 36px, 24px);
        }
    }

    &:hover{
        .video_thumbnail{    
            .thumbnail_overlay, svg{
                opacity: 1;

                @media (max-width: 1200px){
                    display: none;
                }
            }
        }

    }
}

.video_player{
    position: fixed;
    z-index: 9999999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #1a1a1a;
    display: none;

    &.open{
        display: block;
    }

    .video_container{
        position: relative;
        width: 100%;
        height: 100vh;
        display: flex;
        justify-content: center;

        video{
            width: 70%;
            height: auto;
            object-fit: contain;
            cursor: pointer;
        }
    }
    .close-video-player{
        position: absolute;
        z-index: 10;
        top: 15px;
        right: 25px;
        transform: scale(0.8);
        opacity: 0.6;
        cursor: pointer;
        transition: $transition;
        &:hover{
            transform: scale(1.2);
        }
    }

    .controls {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        background-color: rgba(255,255,255, 0.4);
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 60px;

        .playPauseBtn{
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            cursor: pointer;

            .play-btn, .pause-btn{
                width: 14px;
                height: auto;
                transition: $transition;
            }

            .pause-btn{
                width: auto;
                height: 16px;
                display: none;
            }           

            &:hover{
                svg{
                    transform: scale(1.2);
                }
            }
        }
        .volumeBtn{
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            cursor: pointer;

            .mute-this, .unmute-this{
                width: 18px;
                height: auto;
                transition: $transition;
            }

            .unmute-this{
                display: none;
            }

            &:hover{
                svg{
                    transform: scale(1.2);
                }
            }
        }
        .fullscreenBtn{
            width: 60px;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #000;
            border-left: 1px solid rgba(255,255,255, 0.1);
            cursor: pointer;

            .goFullscreen, .exitFullscreen{
                transition: $transition;
            }

            .exitFullscreen{
                display: none;
            }

            &:hover{
                svg{
                    transform: scale(1.2);
                }
            }
        }

        .details{
            width: 360px;
            padding: 0 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p{
                color: #fff;
                display: grid;
                grid-template-columns: 1fr;
                gap: 6px;
                font-size: 14px;
                line-height: 1;
            }
        }

        .seekbar {
            width: calc(100% - 480px);
            height: 60px;
            background-color: #555;
            position: relative;
            cursor: pointer;
            transition: $transition;

            &:hover{
                background-color: rgb(104, 104, 104);
            }
            .seeker {
                width: 0%;
                height: 100%;
                background-color: rgba(255,255,255, 0.8);
                position: absolute;
                top: 0;
                left: 0;
            }
        }
        
    }
}