.static_header{
    padding: 60px 0 45px;
    @media (max-width: 1200px){
        padding: 40px 0;
    }
    .container{
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    .inner{
        width: 100%;
        max-width: 700px;
    }

    h1{
        @include font-secondary;
        margin: 0 0 15px;
        @include vs-spacing(font-size, 48px, 28px);
        @include vs-spacing(line-height, 52px, 32px);
    }
    p{
        line-height: 1;
        margin: 0 0 35px;
        @include vs-spacing(font-size, 18px, 14px);
    }
    .static_hero_img{
        width: 100%;

        .img_wrapper{
            width: 100%;

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
}

.static_content{
    padding: 0 0 45px;
    .container{
        display: flex;
        justify-content: center;
        align-items: flex-start;
        
    }
    .inner{
        width: 100%;
        max-width: 700px;
    }

    h2, h3, h4, h5{
        margin: 0 0 20px;
        @include vs-spacing(font-size, 32px, 22px);
        @include vs-spacing(line-height, 40px, 32px);
    }

    p{
        margin: 0 0 30px;
        @include vs-spacing(font-size, 18px, 14px);
        @include vs-spacing(line-height, 24px, 20px);
    }

    ul{
        display: grid;
        grid-template-columns: 1fr;
        gap: 25px;
        margin: 0 0 30px;

        li{
            padding-left: 25px;
            position: relative;
            @include vs-spacing(font-size, 18px, 14px);
            @include vs-spacing(line-height, 24px, 20px);

            &::after{
                content: "";
                position: absolute;
                z-index: 2;
                top: 8px;
                left: 0;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #000;
            }
        }
    }
}