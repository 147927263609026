@mixin radioIcon {    
    .selector{
        min-width: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        .circle{
            width: 24px;
            height: 24px;
            border: 2px solid #a38c66;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            .round{
                width: 12px;
                height: 12px;
                background-color: #a38c66;
                border-radius: 50%;
                opacity: 0;
                transition: $transition;
            }
        }
    }
}

.checkout-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding: 40px 0;

    > .inner{
        width: 100%;
        max-width: 1100px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 40px;
        @media (max-width: 1200px){
            padding: 0 15px;
        }

        .user_details{
            width: 100%;
            max-width: calc(100% - 465px);
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;

            @media (max-width: 900px){
                max-width: 100%;
            }

            .user_card{
                background-color: #fff;
                border-radius: 10px;
                padding: 20px 25px;
                width: 100%;
                overflow: hidden;

                h3{
                    font-size: 20px;
                    line-height: 1;
                    font-weight: 700;
                    color: #969191;
                    margin: 0 0 15px;
                }
            }
            .user_default{
                .top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding-bottom: 6px;

                    h4{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 600;
                        @include letter-spacing(30);
                        margin: 0;
                    }
                    button{
                        background-color: transparent;
                        border: 0;
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        color: #A38C66;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                        height: auto;
                        width: auto;
                        @media (max-width: 720px) {
                            padding: 0;
                            position: relative;
                            right: -10px;
                            top: -5px;
                        }
                    }
                }
                p{
                    font-size: 14px;
                    line-height: 22px;
                    text-transform: capitalize;
                    @include letter-spacing(30);
                    margin: 0;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    &.dont-transform{
                        text-transform: none;
                    }
                }
            }
            .user_details_change, .user_details_change_form{
                display: none;
                .form_grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 15px;
                    @media (max-width: 600px){
                        grid-template-columns: 1fr;
                    }
                }
                .form_grid_3{
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 15px;
                    @media (max-width: 600px){
                        grid-template-columns: 1fr;
                    }
                }
                .button_row{
                    padding: 10px 0;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 15px;

                    button{
                        min-width: 105px;
                    }
                }
                #payment-form{
                    button{                        
                        appearance: none;
                        height: 36px;
                        border-radius: 4px;
                        cursor: pointer;
                        @include font-primary;
                        font-size: 16px;
                        line-height: 1;
                        @include letter-spacing(10);
                        box-sizing: border-box;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        -webkit-tap-highlight-color: transparent;
                        user-select: none;
                        background-color: #13262F;
                        border: 1px solid #13262F;
                        color: #fff;
                        margin-top: 15px;
                        min-width: 105px;
                    }
                }
            }
            .Addresses{
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                gap: 25px;
                padding: 0 24px 24px;
                position: relative;
                left: -24px;
                width: calc(100% + 48px);
                max-height: 360px;
                overflow-y: auto;
                @include scroll_style;
                
                .address{
                    display: block;
                    input{
                        display: none;
                    }
                    

                    input:checked + label{
                        .left{
                            .selector .circle .round{
                                opacity: 1;
                            }
                        }
                    }

                    label{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 25px;
                        border-radius: 5px;
                        cursor: pointer;
                    }

                    .left{
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 15px;
                        flex-grow: 1;

                        @include radioIcon;

                        .details{
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 1.35;
                            @include letter-spacing(30);
                            display: grid;
                            grid-template-columns: 1fr;
                            gap: 8px;

                            span{
                                &:first-child{
                                    font-weight: 600;
                                }
                                &:last-child{
                                    font-size: 14px;
                                    color: #0E0E0E;
                                    font-weight: 400;
                                }
                            }
                        }
                    }
                    .right{
                        img{
                            opacity: 0;
                            transition: $transition;
                        }
                        button{
                            background-color: transparent;
                            border: 0;
                            width: auto;
                            height: auto;
                            color: #A38C66;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .add_new{
                position: relative;
                left: -24px;
                width: calc(100% + 48px);
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 20px 0;
                border-bottom: 1px solid #f3f3f3;

                button{
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 600;
                    background-color: transparent;
                    border: 0;
                    color: #a38c66;
                    text-decoration: underline;
                    text-underline-offset: 3px;
                    width: auto;
                    height: auto;
                    padding: 0;
                }
            }
        }

        .order_summary{
            width: 100%;
            max-width: 425px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
            @media (max-width: 900px){
                max-width: 100%;
            }

            .product_list{
                background-color: #fff;
                border-radius: 10px;
                padding: 20px 25px;

                h3{
                    font-size: 20px;
                    line-height: 1;
                    font-weight: 600;
                    color: #969191;
                    margin: 0 0 30px;
                }
                
                .products{
                    padding: 0 20px;
                    max-height: 300px;
                    overflow-y: auto;   
                    &::-webkit-scrollbar {
                        width: 6px;
                    }
                    &::-webkit-scrollbar-track {
                        box-shadow: none;
                        background-color: #F4F3EE;
                    }
                
                    &::-webkit-scrollbar-thumb {
                        background-color: #EBE6DE;
                        border-radius: 6px;
                    }
                }
                .product{
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    gap: 20px;
                    padding: 0 0 30px;

                    .products_image{
                        width: 60px;
                        height: 60px;

                        .image_wrapper{
                            width: 60px;
                            height: 60px;

                            img{
                                width: 100%;
                                height: 100%;
                                object-fit: contain;
                            }
                        }
                    }
                    .product_info{
                        flex-grow: 1;
                        padding-right: 40px;
                        .product_title{
                            font-size: 16px;
                            line-height: 20px;
                            font-weight: 500;
                            margin: 0 0 8px;
                        }
                        .product_details{
                            font-size: 14px;
                            line-height: 1;
                            font-weight: 400;
                            color: #969191;
                            margin: 0 0 12px;
                        }
                        .product_cost{
                            font-size: 16px;
                            line-height: 1;
                            font-weight: 500;
                            margin: 0;
                            @include letter-spacing(30);
                        }
                    }
                }
            }
            .order_details{
                background-color: #fff;
                border-radius: 10px;
                padding: 20px 25px;

                ul{
                    display: grid;
                    grid-template-columns: 1fr;
                    gap: 10px;
                    padding-bottom: 20px;
                    li{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 16px;
                        line-height: 1;
                        @include letter-spacing(30);

                        &:last-child{
                            border-top: 1px solid #E6E6E6;
                            padding-top: 20px;
                            margin-top: 10px;
                            font-weight: 18px;
                            font-weight: 600;
                        }
                    }
                }

                button{
                    width: 100%;
                    margin-bottom: 20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;

                    &.disabled{
                        opacity: 0.2;
                    }
                }

                p{
                    font-size: 12px;
                    line-height: 15px;
                    color: #969191;
                    margin: 0 0 18px;
                    @include letter-spacing(30);

                    &:last-child{
                        margin: 0;
                    }
                }
            }
        }
        .saved_cards{
            max-height: 360px;
            overflow-y: auto;
            @include scroll_style;
        }
        
        .saved_card{
            display: block;
            margin-bottom: 15px;
            input{
                display: none;
            }

            label{                
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 30px;        
                min-width: 100%;     
                padding: 15px;   
                cursor: pointer;
                border: 1px solid #E3E3E3;
                border-radius: 8px;
            }

            input:checked + label{
                .selector .circle .round{
                    opacity: 1;
                }
            }
            
            @include radioIcon;

            .left{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;

                .card_details{
                    h4{
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1;
                        margin: 0 0 8px !important;
                        text-transform: capitalize;
                    }
                    p{
                        font-size: 14px;
                        line-height: 1;
                        margin: 0 !important;
                        color: #585858;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
            .right{
                flex-grow: 1;
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
            }
        }
    }
}

#delivery_slot{
    overflow: hidden;
}


.slot_days{
    .day{
        width: 100%;
        max-width: 95px;
        height: 75px;
        border: 1px solid #F7F7F7;
        background-color: #F7F7F7;
        border-radius: 4px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        text-transform: capitalize;
        cursor: pointer;

        &:hover{
            background-color: #F7F7F7;
        }
        &.active{
            background-color: #fff;
        }

        span{
            &:first-child{
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
} 
.slot_times{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 15px 0;

    input{
        display: none;
    }
    .time_slot{
        width: 100%;
        height: 50px;
        border: 1px solid #F7F7F7;
        border-radius: 8px;
        overflow: hidden;
        cursor: pointer;

        label{            
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 50px;
            padding: 0 15px;
            background-color: #F7F7F7;
            border-radius: 8px;
            cursor: pointer;
            transition: $transition;
            font-weight: 500;

            .left{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
            }
            
            @include radioIcon;
        }

        input:checked + label{
            background-color: #fff;
            .selector .circle .round{
                opacity: 1;
            }
        }

        &.unavailable{
            pointer-events: none;
            opacity: 0.5;

            .selector {
                display: none;
            }
        }
    }
}  

#payment_method{
    .method{
        border-bottom: 1px solid #f3f3f3;
        padding-top: 25px;

        &:first-child{
            padding-top: 0;
        }
        > h4{
            font-size: 20px;
            line-height: 1;
            font-weight: 500;
            margin: 0 0 10px;
        }
        > p{
            font-size: 16px;
            line-height: 1;
            font-weight: 400;
            margin: 0 0 25px;
        }
    }
    .add_new{
        padding: 20px 0;
        border-bottom: 1px solid #f3f3f3;
    }
}

.full-btn{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.basket{
    .basket_products{
        width: 100%;
        max-width: calc(100% - 465px);
        display: grid;
        grid-template-columns: 1fr;
        gap: 10px;
        background-color: #fff;
        border-radius: 10px;
        padding: 20px 25px;
        width: 100%;
        overflow: hidden;

        h3{
            font-size: 20px;
            line-height: 1;
            font-weight: 600;
            color: #969191;
            margin: 0 0 30px;
        }
        
        .product{
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            gap: 20px;
            padding: 0 0 30px;

            .products_image{
                width: 60px;
                height: 60px;

                .image_wrapper{
                    width: 60px;
                    height: 60px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
                }
            }
            .product_info{
                flex-grow: 1;
                padding-right: 40px;
                .product_title{
                    font-size: 16px;
                    line-height: 20px;
                    font-weight: 500;
                    margin: 0 0 8px;
                }
                .product_details{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 400;
                    color: #969191;
                    margin: 0 0 12px;
                }
                .product_cost{
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                    margin: 0;
                    @include letter-spacing(30);
                }
            }
            .cart_action{
                width: 70px;
                .product_qty{
                    width: 70px;
                    height: 40px;
                    border-radius: 5px;
                    position: relative;

                    .right{
                        position: absolute;
                        z-index: 2;
                        top: 0;
                        right: 0;
                        display: grid;
                        grid-template-columns: 1fr;

                        a{
                            width: 24px;
                            height: 20px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }
                    }
                }
            }
        }
    }
}