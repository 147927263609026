/*///////////////////////////////////////////////////////////////////////////////////////////////////////////
// -----------------------------------------Base Styles Start-----------------------------------------------*/
@import "../../node_modules/swiper/swiper-bundle.min.css";
@import "../../node_modules/select2/dist/css/select2.min.css";
/**** VS-SPACING ****/
* {
  color: #000;
  box-sizing: border-box;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-weight: 400;
}

body {
  margin: 0 !important;
  width: 100%;
  padding-top: 120px;
  background-color: #fff;
}
@media (max-width: 720px) {
  body {
    padding-top: 165px;
  }
}
body.locked {
  overflow: hidden !important;
}
body.bg-grey {
  background-color: #F7F7F7;
}
body::-webkit-scrollbar {
  width: 5px;
}
body::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
body::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

img {
  display: inline-block;
}

a {
  color: inherit;
  font: inherit;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
a:hover, a:focus, a:visited {
  text-decoration: none;
  color: inherit;
}

span {
  color: inherit;
  font: inherit;
}

ul, ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

input, select, textarea {
  appearance: none;
  width: 100%;
  display: block;
  border: 1px solid #E6E6E6;
  background-color: #fff;
  height: 42px;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
}
input:focus, input:hover, select:focus, select:hover, textarea:focus, textarea:hover {
  outline: none;
  box-shadow: none;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
  background-color: #fff !important;
  color: #000 !important;
}

/* Hide the spinners in Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

textarea {
  padding: 10px;
  width: 100%;
  resize: vertical;
  height: 80px;
  min-height: 60px;
  max-height: 140px;
}

select {
  position: relative;
}

button {
  appearance: none;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
button.dark {
  background-color: #13262F;
  border: 1px solid #13262F;
  color: #fff;
}
button.light {
  background-color: #fff;
  border: 1px solid #E6E6E6;
  color: #000;
}
button.disabled {
  opacity: 0.5;
  pointer-events: none;
}

label {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
}

.form-group {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 0 0 20px;
}

.form_row {
  display: grid;
  gap: 15px;
}
.form_row.two_columns {
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 900px) {
  .form_row.two_columns {
    grid-template-columns: 1fr;
    row-gap: 0;
  }
}

.select_bx {
  position: relative;
  width: 100%;
}
.select_bx select {
  padding-right: 35px;
}
.select_bx svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
}

.type_holder {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
}
.type_holder input {
  display: none;
}
.type_holder .address_type {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  border: 1px solid #e6e6e6;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  border-radius: 4px;
}
.type_holder .address_type label {
  width: 100%;
  height: 42px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.type_holder input:checked + label {
  background-color: #13262F;
  color: #fff;
}

section {
  width: 100%;
  overflow-x: hidden;
}

.error-message {
  font-size: 14px;
  line-height: 1.25;
  font-weight: 400;
  color: red;
}

.overlay {
  background-color: #000;
  opacity: 0.6;
  position: fixed;
  z-index: 60;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.container {
  width: 80%;
  margin: 0 auto;
  display: block;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1600px) {
  .container {
    width: 90%;
  }
}
@media (max-width: 1200px) {
  .container {
    width: calc(100% - 32px);
  }
}

.cms-messages {
  display: none !important;
}

.select2 .selection .select2-selection {
  border: 1px solid #e6e6e6;
  height: 42px;
}
.select2 .selection .select2-selection .select2-selection__rendered, .select2 .selection .select2-selection .select2-selection__arrow {
  height: 42px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.select2-container {
  border-radius: 5px;
  width: 100% !important;
}
.select2-container .select2-dropdown {
  border: 1px solid #e6e6e6;
}
.select2-container .select2-dropdown .select2-search__field {
  border: 1px solid #e6e6e6;
}

.select2-results__options::-webkit-scrollbar {
  width: 5px;
}
.select2-results__options::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.select2-results__options::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}

.iti button {
  padding: 0 !important;
  min-width: auto !important;
}

.iti--inline-dropdown .iti__country-list::-webkit-scrollbar {
  width: 5px;
}
.iti--inline-dropdown .iti__country-list::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.iti--inline-dropdown .iti__country-list::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}

header {
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  display: grid;
  grid-template-columns: 1fr;
  background-color: #fff;
  overflow: hidden;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 720px) {
  header {
    height: fit-content;
    max-height: 165px;
  }
}
header.scrolled {
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.1);
}
header .top_bar {
  width: 100%;
  height: 70px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #F2F2F2;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px;
}
@media (max-width: 1200px) {
  header .top_bar {
    padding: 0 16px;
  }
}
@media (max-width: 720px) {
  header .top_bar {
    justify-content: space-between;
    height: min-content;
    gap: 12px;
    padding: 20px 16px 5px;
    border-bottom: 0;
  }
}
header .top_bar .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}
@media (max-width: 720px) {
  header .top_bar .left {
    order: 1;
  }
}
@media (max-width: 1200px) {
  header .top_bar .left .logo img {
    height: 26px;
    width: auto;
  }
}
header .top_bar .mobile_menu {
  display: none;
  appearance: none;
  border: 0;
  background-color: transparent;
}
@media (max-width: 1200px) {
  header .top_bar .mobile_menu {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
header .top_bar .searchbar {
  flex-grow: 1;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 720px) {
  header .top_bar .searchbar {
    order: 3;
    min-width: 100%;
  }
}
header .top_bar .searchbar form {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}
@media (max-width: 720px) {
  header .top_bar .searchbar form {
    height: min-content;
  }
}
header .top_bar .searchbar form input {
  background-color: #F7F7F7;
  width: 100%;
  border-radius: 10px;
  border-color: transparent;
  padding: 0 50px 0 18px;
  line-height: 1;
  font-weight: 500;
}
header .top_bar .searchbar form input {
  font-size: calc(0.0009433962 * 100vw + 15.641509434px);
}
@media screen and (min-width: 1919px) {
  header .top_bar .searchbar form input {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  header .top_bar .searchbar form input {
    font-size: 16px;
  }
}
header .top_bar .searchbar form button {
  border: 0;
  background-color: transparent;
  position: absolute;
  z-index: 5;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}
header .top_bar .userAction {
  min-width: 100px;
}
@media (max-width: 720px) {
  header .top_bar .userAction {
    order: 2;
  }
}
header .top_bar .userAction ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
}
header .top_bar .userAction ul li {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  position: relative;
}
header .top_bar .userAction ul li.sign_up_btn a {
  background-color: #A38C66;
  height: 40px;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  padding: 0 30px;
}
@media (max-width: 1200px) {
  header .top_bar .userAction ul li.sign_up_btn a {
    display: none;
  }
}
header .top_bar .userAction ul li .basketLines {
  position: absolute;
  z-index: 2;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  top: -7px;
  right: 2px;
  color: #000;
  opacity: 0.6;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
header .top_bar .userAction ul li img {
  width: auto;
  height: 18px;
  margin: 0 5px;
  opacity: 0.6;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
header .top_bar .userAction ul li:hover img, header .top_bar .userAction ul li:hover span {
  opacity: 1;
}
header .bottom_bar {
  width: 100%;
  height: 50px;
  padding: 0 30px;
  box-sizing: border-box;
  border-bottom: 1px solid #F2F2F2;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
@media (max-width: 1200px) {
  header .bottom_bar {
    grid-template-columns: 1fr;
    padding: 0 16px;
  }
}
header .bottom_bar .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 50px;
  gap: 20px;
}
@media (max-width: 1200px) {
  header .bottom_bar .left {
    display: none;
  }
}
header .bottom_bar .left .menu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  line-height: 1;
  font-weight: 500;
}
header .bottom_bar .left .menu {
  font-size: calc(0.0009433962 * 100vw + 13.641509434px);
}
@media screen and (min-width: 1919px) {
  header .bottom_bar .left .menu {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  header .bottom_bar .left .menu {
    font-size: 14px;
  }
}
header .bottom_bar .left .menu span {
  display: inline-block;
  margin-right: 15px;
  font-weight: 500;
}
header .bottom_bar .left .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px;
  gap: 30px;
  box-sizing: border-box;
  border-left: 1px solid #F2F2F2;
  height: 50px;
  line-height: 1;
  font-weight: 400;
}
header .bottom_bar .left .info {
  font-size: calc(0.0009433962 * 100vw + 13.641509434px);
}
@media screen and (min-width: 1919px) {
  header .bottom_bar .left .info {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  header .bottom_bar .left .info {
    font-size: 14px;
  }
}
header .bottom_bar .left .info div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
header .bottom_bar .right {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1200px) {
  header .bottom_bar .right {
    display: none;
  }
}
header .bottom_bar .right .nav {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 30px;
}
header .bottom_bar .right .nav li {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  line-height: 1;
  font-weight: 500;
}
header .bottom_bar .right .nav li {
  font-size: calc(0.0009433962 * 100vw + 13.641509434px);
}
@media screen and (min-width: 1919px) {
  header .bottom_bar .right .nav li {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  header .bottom_bar .right .nav li {
    font-size: 14px;
  }
}
header .bottom_bar .right .nav li a.offer {
  color: #E12C2C;
}
header .bottom_bar .bottom {
  display: none;
}
@media (max-width: 1200px) {
  header .bottom_bar .bottom {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
header .bottom_bar .bottom .info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 20px 0 0;
  gap: 30px;
  box-sizing: border-box;
  height: 50px;
  line-height: 1;
  font-weight: 500;
}
header .bottom_bar .bottom .info {
  font-size: calc(0.0009433962 * 100vw + 13.641509434px);
}
@media screen and (min-width: 1919px) {
  header .bottom_bar .bottom .info {
    font-size: 16px;
  }
}
@media screen and (max-width: 380px) {
  header .bottom_bar .bottom .info {
    font-size: 14px;
  }
}
header .bottom_bar .bottom .info div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
@media (max-width: 600px) {
  header .bottom_bar .bottom .info .address {
    display: none;
  }
}

#cms-top + header {
  top: 46px;
}

footer {
  background-color: #F4F3EE;
  padding: 60px 0 30px;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 1200px) {
  footer {
    padding: 40px 0 30px;
  }
}
footer .logo {
  display: flex;
  justify-content: center;
  align-items: center;
}
footer .newsletter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
}
@media (max-width: 1200px) {
  footer .newsletter {
    padding: 20px 16px;
  }
}
footer .newsletter p {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  letter-spacing: -0.02em;
  line-height: 27px;
  margin: 0 0 25px;
  text-align: center;
}
footer .newsletter p {
  font-size: calc(0.0009433962 * 100vw + 17.641509434px);
}
@media screen and (min-width: 1919px) {
  footer .newsletter p {
    font-size: 20px;
  }
}
@media screen and (max-width: 380px) {
  footer .newsletter p {
    font-size: 18px;
  }
}
footer .newsletter form {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  width: 100%;
}
footer .newsletter form input {
  height: 60px;
  border-radius: 0;
  background-color: transparent;
  padding: 0 20px;
  width: 100%;
  max-width: 570px;
}
footer .newsletter form button {
  height: 60px;
  width: 60px;
  border: 0;
  background-color: transparent;
}
footer .bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6F6F6E;
  width: 100%;
  padding: 0 35px;
}
@media (max-width: 1200px) {
  footer .bottom {
    flex-direction: column;
    gap: 20px;
  }
}
@media (max-width: 1200px) {
  footer .bottom .copywrite, footer .bottom .madeby {
    order: 2;
  }
}
footer .bottom .copywrite p, footer .bottom .madeby p {
  font-size: 10px;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  color: #6F6F6E;
  letter-spacing: 0.3em;
  margin: 0;
}
footer .bottom .copywrite p span, footer .bottom .copywrite p a, footer .bottom .madeby p span, footer .bottom .madeby p a {
  color: #9B6F54;
}
@media (max-width: 1200px) {
  footer .bottom .madeby {
    display: none;
  }
}
footer .bottom .quicklinks {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}
@media (max-width: 1200px) {
  footer .bottom .quicklinks {
    order: 1;
  }
}
footer .bottom .quicklinks ul {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
footer .bottom .quicklinks ul li {
  font-size: 10px;
  font-weight: 300;
  line-height: 1;
  text-transform: uppercase;
  color: #6F6F6E;
  letter-spacing: 0.3em;
}
footer .bottom .quicklinks ul li a {
  color: #6F6F6E;
}
footer .bottom .quicklinks ul li::after {
  content: "|";
  padding-left: 8px;
}
footer .bottom .quicklinks ul li:last-child::after {
  content: "";
}
footer .footer_accordion {
  display: none;
  margin-bottom: 20px;
}
@media (max-width: 1200px) {
  footer .footer_accordion {
    display: block;
  }
}
footer .footer_accordion .accordion-item {
  border-bottom: 1px solid #E6E6E6;
}
footer .footer_accordion .accordion-item:first-child {
  border-top: 1px solid #E6E6E6;
}
footer .footer_accordion .accordion-item .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0;
  cursor: pointer;
  height: 50px;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}
footer .footer_accordion .accordion-item .accordion-header h3 {
  margin: 0;
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  color: #969191;
}
footer .footer_accordion .accordion-item .accordion-header .arrow.rotate {
  transform: rotate(180deg);
}
footer .footer_accordion .accordion-item .accordion-content {
  display: none;
  padding: 10px 0 30px;
}
footer .footer_accordion .accordion-item .accordion-content ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
footer .footer_accordion .accordion-item .accordion-content ul li {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.02em;
  color: #969191;
}

.cart {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.cart.open {
  display: block;
}
.cart .cart_overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  cursor: pointer;
}
.cart .cart_sidebar {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 475px;
  height: 100dvh;
  max-height: 100dvh;
  background-color: #fff;
  border-left: 1px solid #E6E6E6;
  transform: translateX(250%);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 580px) {
  .cart .cart_sidebar {
    width: 100%;
  }
}
.cart .cart_sidebar.open {
  transform: none;
}
.cart .cart_sidebar .topbar {
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #E6E6E6;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  position: sticky;
  top: 0;
}
.cart .cart_sidebar .topbar .gift input {
  display: none;
}
.cart .cart_sidebar .topbar .gift label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: 500;
}
.cart .cart_sidebar .topbar .gift label .checkbox .box {
  stroke: #969191;
  fill: #fff;
}
.cart .cart_sidebar .topbar .gift input:checked + label .checkbox .box {
  stroke: #a38c66;
  fill: #a38c66;
}
.cart .cart_sidebar .topbar h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}
.cart .cart_sidebar .middleContent {
  overflow-y: auto;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 211px);
}
.cart .cart_sidebar .middleContent::-webkit-scrollbar {
  width: 5px;
}
.cart .cart_sidebar .middleContent::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.cart .cart_sidebar .middleContent::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.cart .cart_sidebar .store {
  padding: 25px 0 0;
}
.cart .cart_sidebar .store h3 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 32px;
  margin: 0 0 30px;
  color: #1A1A1A;
}
.cart .cart_sidebar .store .product_list {
  padding: 0 20px;
}
.cart .cart_sidebar .store .product {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0 0 30px;
}
.cart .cart_sidebar .store .product .products_image {
  width: 60px;
  height: 60px;
}
.cart .cart_sidebar .store .product .products_image .image_wrapper {
  width: 60px;
  height: 60px;
}
.cart .cart_sidebar .store .product .products_image .image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart .cart_sidebar .store .product .product_info {
  flex-grow: 1;
  padding-right: 40px;
}
.cart .cart_sidebar .store .product .product_info .product_title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 8px;
}
.cart .cart_sidebar .store .product .product_info .product_details {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #969191;
  margin: 0 0 12px;
}
.cart .cart_sidebar .store .product .product_info .product_cost {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  margin: 0;
  letter-spacing: 0.03em;
}
.cart .cart_sidebar .store .product .cart_action {
  width: 70px;
}
.cart .cart_sidebar .store .product .cart_action .product_qty {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  position: relative;
}
.cart .cart_sidebar .store .product .cart_action .product_qty .right {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr;
}
.cart .cart_sidebar .store .product .cart_action .product_qty .right a {
  width: 24px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart .cart_sidebar .delivery_fee {
  padding: 0 20px;
  height: 34px;
  background-color: #EBE6DE;
  color: #A38C66;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.cart .cart_sidebar .bottombar {
  position: absolute;
  z-index: 10;
  bottom: env(safe-area-inset-bottom, 0);
  right: 0;
  padding: 0 0 20px;
  width: 100%;
  background-color: #fff;
  border-top: 6px solid #fff;
  padding-bottom: env(safe-area-inset-bottom);
}
.cart .cart_sidebar .bottombar .derlivery_slot {
  padding: 0 20px;
  height: 34px;
  background-color: #EBE6DE;
  color: #A38C66;
  border-top: 1px solid #E6E6E6;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.16);
}
.cart .cart_sidebar .bottombar .derlivery_slot .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.cart .cart_sidebar .bottombar .derlivery_slot .left span {
  font-weight: 500;
}
.cart .cart_sidebar .bottombar .derlivery_slot .right a {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #A38C66;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.cart .cart_sidebar .bottombar .cart_summary {
  padding: 20px 20px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.cart .cart_sidebar .bottombar .cart_summary > span:first-child {
  color: #969191;
}
.cart .cart_sidebar .bottombar .cart_actions {
  padding: 0 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.cart .cart_sidebar .bottombar .cart_actions button {
  width: 154px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-color: #13262F;
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
}
.cart .cart_sidebar .bottombar .cart_actions button:first-child {
  border-width: 2px;
}
.cart .cart_sidebar .bottombar .cart_actions button:last-child {
  flex-grow: 1;
}
.cart .extra_products {
  background-color: #F7F7F7;
  width: 360px;
  height: 100vh;
  position: absolute;
  z-index: 8;
  top: 0;
  right: 475px;
  transform: translateX(250%);
  transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);
}
.cart .extra_products.open {
  transform: none;
}
@media (max-width: 900px) {
  .cart .extra_products {
    display: none;
  }
}
.cart .extra_products .top {
  padding: 44px 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.cart .extra_products .top h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 32px;
  margin: 0;
}
.cart .extra_products .top a {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  text-decoration: underline;
  color: #969191;
}
.cart .extra_products .products_list {
  padding: 20px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  height: 100dvh;
  max-height: calc(100dvh - 80px);
  overflow-y: auto;
}
.cart .extra_products .products_list::-webkit-scrollbar {
  width: 5px;
}
.cart .extra_products .products_list::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.cart .extra_products .products_list::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.cart .extra_products .products_list .product_card_mini {
  border: 1px solid #E6E6E6;
  border-radius: 3px;
}
.cart .cart_timeslots {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  transform: translateY(200vh);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.cart .cart_timeslots.open {
  transform: none;
}
.cart .cart_timeslots .topbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cart .cart_timeslots .topbar h3 {
  margin: 0;
}
.cart .cart_timeslots .topbar .cart-timeslot {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cart .cart_timeslots .middle {
  width: 100%;
  padding: 25px 0;
  height: 100vh;
  max-height: calc(100vh - 135px);
}
.cart .cart_timeslots .middle .slot_days {
  padding: 0 20px;
}
.cart .cart_timeslots .middle .slot_times {
  padding: 20px;
}
.cart .cart_timeslots .bottom {
  width: 100%;
  padding: 15px 0 15px;
  position: absolute;
  z-index: 2;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e6e6e6;
}
@media (max-width: 1200px) {
  .cart .cart_timeslots .bottom {
    padding: 15px 0 40px;
  }
}
.cart .cart_timeslots .bottom button {
  width: calc(100% - 40px);
  height: 44px;
}

#cms-top ~ .cart .cart_sidebar .topbar {
  top: 46px;
}
#cms-top ~ .cart .cart_sidebar .middleContent, #cms-top ~ .cart .cart_sidebar .middle {
  position: relative;
  top: 46px;
}
#cms-top ~ .cart .cart_sidebar .middle {
  max-height: calc(100vh - 180px);
}
#cms-top ~ .cart .extra_products .top {
  padding: 65px 20px 0;
}
#cms-top ~ .cart .extra_products .products_list {
  max-height: calc(100dvh - 90px);
}

.cart_notification {
  position: fixed;
  z-index: 99;
  top: 80px;
  right: 30px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
  padding: 10px;
  min-width: 240px;
  display: none;
}
.cart_notification.open {
  display: block;
}
.cart_notification button {
  width: 100%;
}
.cart_notification p {
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.03em;
  margin: 15px 0 10px;
}
.cart_notification p a {
  color: #A38C66;
  text-decoration: underline;
}

.searchBar_overlay {
  position: fixed;
  z-index: 96;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(2px);
  display: none;
  pointer-events: none;
}
.searchBar_overlay.active {
  display: block;
}
@media (max-width: 1200px) {
  .searchBar_overlay {
    display: none;
  }
}

.searchBar_wrapper {
  position: fixed;
  z-index: 101;
  width: 100%;
  height: calc(100% - 60px);
  top: 60px;
  left: 0;
  display: none;
}
.searchBar_wrapper.open {
  display: block;
}
@media (max-width: 1200px) {
  .searchBar_wrapper {
    top: 0;
    height: 100%;
  }
}
.searchBar_wrapper .searchSuggestions {
  position: fixed;
  z-index: 101;
  top: 60px;
  left: 0;
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.16);
}
@media (max-width: 1200px) {
  .searchBar_wrapper .searchSuggestions {
    width: 100%;
    height: 100%;
    top: 0;
    transform: none;
    padding: 30px 0;
  }
}
.searchBar_wrapper .searchSuggestions .topbar {
  display: none;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}
@media (max-width: 1200px) {
  .searchBar_wrapper .searchSuggestions .topbar {
    display: flex;
    padding: 0 20px;
  }
}
.searchBar_wrapper .searchSuggestions .topbar form {
  width: calc(100% - 38px);
  position: relative;
}
.searchBar_wrapper .searchSuggestions .topbar form svg {
  position: absolute;
  z-index: 2;
  top: 11px;
  left: 12px;
}
.searchBar_wrapper .searchSuggestions .topbar form input {
  padding-left: 36px;
  background-color: #F7F7F7;
  border-radius: 10px;
  box-shadow: inset 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
}
.searchBar_wrapper .searchSuggestions p {
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin: 0 0 15px;
  color: #969191;
}
@media (max-width: 1200px) {
  .searchBar_wrapper .searchSuggestions p {
    padding: 10px 20px;
  }
}
.searchBar_wrapper .searchSuggestions p span {
  color: #000;
  word-break: break-all;
  word-wrap: break-word;
}
@media (max-width: 1200px) {
  .searchBar_wrapper .searchSuggestions .categories {
    padding: 0 20px;
  }
}
.searchBar_wrapper .searchSuggestions .categories .swiper-slide {
  border: 1px solid #969191;
  border-radius: 3px;
  height: 44px;
  padding: 0 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #969191;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.searchBar_wrapper .searchSuggestions .categories .swiper-slide:hover {
  background-color: #969191;
  color: #fff;
}
.searchBar_wrapper .searchSuggestions .suggested_products {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  padding: 30px 0 0;
}
@media (max-width: 1200px) {
  .searchBar_wrapper .searchSuggestions .suggested_products {
    grid-template-columns: repeat(2, 1fr);
    padding: 30px 20px 0;
    height: calc(100vh - 350px);
    overflow: auto;
  }
}
@media (max-width: 600px) {
  .searchBar_wrapper .searchSuggestions .suggested_products {
    grid-template-columns: 1fr;
  }
}
.searchBar_wrapper .searchSuggestions .suggested_products .product_card_mini {
  border: 1px solid #E6E6E6;
  border-radius: 3px;
}
.searchBar_wrapper .searchSuggestions .suggested_products .product_card_mini:nth-child(10) {
  display: none;
}
@media (max-width: 1200px) {
  .searchBar_wrapper .searchSuggestions .suggested_products .product_card_mini:nth-child(10) {
    display: block;
  }
}
.searchBar_wrapper .searchSuggestions .see_all_prd {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0 0;
  display: none;
}
.searchBar_wrapper .searchSuggestions .see_all_prd a {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: 10px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.searchBar_wrapper .searchSuggestions .see_all_prd a img {
  margin-left: 10px;
}

#cms-top ~ .searchBar_wrapper .searchSuggestions {
  top: 108px;
}

.productFilters {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.productFilters.open {
  display: block;
}
.productFilters .productFiltersOverlay {
  position: absolute;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
  cursor: pointer;
}
.productFilters .filterList {
  position: absolute;
  z-index: 103;
  top: 0;
  left: 0;
  height: 100dvh;
  width: 436px;
  background-color: #fff;
  transform: translateX(-250%);
  transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 580px) {
  .productFilters .filterList {
    width: 100%;
  }
}
.productFilters .filterList.open {
  transform: none;
}
.productFilters .filterList .topbar {
  padding: 30px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.productFilters .filterList .topbar h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.01em;
  margin: 0;
}
.productFilters .filterList .filterContent {
  padding: 0 25px 20px;
  width: 100%;
  height: auto;
  max-height: calc(100vh - 163px);
}
.productFilters .filterList .filterContent .inner {
  width: 100%;
  height: auto;
  max-height: calc(100vh - 163px);
  overflow-y: auto;
}
.productFilters .filterList .filterContent .inner::-webkit-scrollbar {
  width: 5px;
}
.productFilters .filterList .filterContent .inner::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.productFilters .filterList .filterContent .inner::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.productFilters .filterList .filterContent .inner::-webkit-scrollbar-track {
  background-color: #F4F3EE;
}
.productFilters .filterList .filterContent .inner::-webkit-scrollbar-thumb {
  background-color: #EBE6DE;
}
.productFilters .filterList .bottom {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  width: 100%;
  position: absolute;
  z-index: 10;
  left: 0;
  bottom: env(safe-area-inset-bottom, 0);
  background-color: #fff;
  padding: 20px 25px;
  border-top: 1px solid #E6E6E6;
}
.productFilters .filterList .bottom button {
  width: 100%;
}

.filterAccordion {
  width: 100%;
  margin: 0 auto;
}
.filterAccordion .accordion-item {
  margin-bottom: 10px;
  border-radius: 4px;
  overflow: hidden;
}
.filterAccordion .accordion-item .accordion-header {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid #E6E6E6;
  margin-right: 10px;
}
.filterAccordion .accordion-item .accordion-header h3 {
  font-size: 20px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.01em;
}
.filterAccordion .accordion-item .accordion-content {
  display: none;
  padding: 20px 0;
  margin-right: 10px;
}
.filterAccordion .accordion-item .accordion-content ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
}
.filterAccordion .accordion-item .accordion-content ul li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.filterAccordion .accordion-item .accordion-content ul li span {
  cursor: pointer;
  color: #222222;
}
.filterAccordion .accordion-item .accordion-content ul li svg {
  cursor: pointer;
}
.filterAccordion .accordion-item .accordion-content ul li svg .box {
  fill: #fff;
}
.filterAccordion .accordion-item .accordion-content ul li:nth-child(n+7) {
  display: none;
}
.filterAccordion .accordion-item .accordion-content ul li.active svg .box {
  fill: #13262f;
}
.filterAccordion .accordion-item .accordion-content ul li.readMoreFilters span {
  color: #A38C66;
}
.filterAccordion .accordion-item .arrow {
  width: 12px;
  height: auto;
}
.filterAccordion .accordion-item .rotate {
  transform: rotateZ(180deg);
}

.megamenu {
  position: fixed;
  z-index: 95;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.megamenu.open {
  display: block;
}
.megamenu .megamenu_overlay {
  position: absolute;
  z-index: 96;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
  backdrop-filter: blur(2px);
}
.megamenu .menu {
  position: absolute;
  z-index: 97;
  top: 120px;
  width: 100%;
  background-color: #fff;
  height: 360px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  display: flex;
  gap: 45px;
  padding: 40px 30px;
}
.megamenu .menu .menu_section {
  flex: 1;
  max-width: 280px;
}
.megamenu .menu .menu_section h3 {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 25px;
  color: #0E0E0E;
}
.megamenu .menu .multi_column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  height: auto;
  min-height: 190px;
  max-height: 190px;
  overflow-y: auto;
}
.megamenu .menu .multi_column::-webkit-scrollbar {
  width: 5px;
}
.megamenu .menu .multi_column::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.megamenu .menu .multi_column::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.megamenu .menu .multi_column li {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  max-height: 20px;
  color: #222222;
}
.megamenu .menu .shop_all {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin-top: 20px;
  color: #A38C66;
}

#cms-top ~ .megamenu .menu {
  top: 166px;
}

.burgermenu {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: none;
}
.burgermenu.open {
  display: block;
}
.burgermenu .top {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #F2F2F2;
}
.burgermenu .top .left {
  width: calc(100% - 40px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding-left: 15px;
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.burgermenu .top .right {
  width: 40px;
  height: 40px;
  border-left: 1px solid #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.burgermenu .top .right img {
  width: 16px;
  height: auto;
}
.burgermenu .menu {
  width: 100%;
  padding: 20px 0 100px;
  max-height: calc(100vh - 40px);
  overflow-y: auto;
}
.burgermenu .menu ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 0 20px;
}
.burgermenu .menu ul li {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1a1a1a;
}
.burgermenu h3 {
  padding: 0 20px 30px;
  font-size: 20px;
  line-height: 1;
  margin: 0 0 15px;
  color: #1a1a1a;
  opacity: 0.65;
  font-weight: 600;
}
.burgermenu .shop_all {
  display: inline-block;
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  padding: 30px 20px;
}
.burgermenu .level_one {
  display: none;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.burgermenu .level_one.open {
  display: block;
}
.burgermenu .level_one .top .left svg {
  transform: rotate(180deg);
}

#cms-top ~ .burgermenu .top {
  margin-top: 46px;
}
#cms-top ~ .burgermenu .menu {
  max-height: calc(100vh - 86px);
}

.toast-container {
  position: fixed;
  z-index: 99999;
  top: 135px;
  right: 15px;
}
.toast-container .toast {
  position: relative;
  padding: 10px 0;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  min-width: 320px;
  height: 80px;
}
.toast-container .toast .toast-content {
  width: calc(100% - 50px);
  padding: 0 20px;
}
.toast-container .toast .toast-content h4 {
  font-size: 16px;
  line-height: 1.35;
  font-weight: 500;
  margin: 0 0 8px;
  color: #1a1a1a;
  text-transform: capitalize;
}
.toast-container .toast .toast-content p {
  font-size: 13px;
  line-height: 1;
  font-weight: 400;
  margin: 0;
  color: #1a1a1a;
  opacity: 0.8;
}
.toast-container .toast .toast-close {
  width: 50px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid #f2f2f2;
}

#cms-top ~ .toast-container {
  top: 175px;
}

.product_tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
@media (max-width: 800px) {
  .product_tags {
    display: none;
  }
}
.product_tags .tags {
  width: auto;
  height: 24px;
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.05em;
  padding: 0 10px;
}
.product_tags .tags:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.product_tags .tags.featured {
  background-color: #EBE6DE;
  color: #A38C66;
}
.product_tags .tags.offer {
  background-color: #FF8484;
  color: #fff;
}
.product_tags .tags.bestSeller {
  background-color: #A38C66;
  color: #fff;
}

.product_card {
  border: 1px solid #E6E6E6;
  border-radius: 5px;
}
.product_card .product_image {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.product_card .product_image .product_tags {
  position: absolute;
  z-index: 2;
  top: 10px;
  left: 0;
  width: 100%;
}
.product_card .product_image .image_container {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 90%;
  height: 90%;
}
.product_card .product_image .image_container img {
  padding-top: 10%;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product_card .product_info {
  background-color: #F7F7F7;
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.product_card .product_info .product_qty {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  margin: 0 0 15px;
  color: #969191;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
@media (max-width: 600px) {
  .product_card .product_info .product_qty {
    text-align: left;
    justify-content: flex-start;
  }
}
.product_card .product_info .product_qty span {
  position: relative;
}
.product_card .product_info .product_qty span:first-child::after {
  content: "|";
  position: absolute;
  z-index: 1;
  right: -10px;
}
.product_card .product_info .product_title {
  text-align: center;
  margin: 0 0 20px;
  min-height: 48px;
}
.product_card .product_info .product_title {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .product_card .product_info .product_title {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .product_card .product_info .product_title {
    font-size: 14px;
  }
}
.product_card .product_info .product_title {
  line-height: calc(0.0028301887 * 100vw + 16.9245283019px);
}
@media screen and (min-width: 1919px) {
  .product_card .product_info .product_title {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .product_card .product_info .product_title {
    line-height: 18px;
  }
}
@media (max-width: 600px) {
  .product_card .product_info .product_title {
    text-align: left;
    margin: 0 0 10px;
    min-height: 60px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.product_card .product_info .product_price {
  font-size: 14px;
  line-height: 1;
  font-weight: 600;
  text-align: center;
  margin: 0;
}
@media (max-width: 600px) {
  .product_card .product_info .product_price {
    text-align: left;
  }
}
.product_card .js_add_to_cart, .product_card .in_cart {
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 5;
  width: 28px;
  height: auto;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1200px) {
  .product_card .js_add_to_cart, .product_card .in_cart {
    width: 20px;
  }
}
.product_card .in_cart {
  z-index: 6;
  display: none;
}
.product_card:hover .js_add_to_cart, .product_card:hover .in_cart {
  opacity: 1;
}
.product_card:hover.prd_in_cart .js_add_to_cart {
  display: none;
}
.product_card:hover.prd_in_cart .in_cart {
  display: block;
}

.product_card_mini {
  background-color: #fff;
  border-radius: 3px;
  padding: 2px;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}
.product_card_mini a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
}
.product_card_mini .product_image {
  width: 80px;
  height: 80px;
}
.product_card_mini .product_image .image_wrapper {
  width: 80px;
  height: 80px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.product_card_mini .product_image .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.product_card_mini .product_text {
  width: calc(100% - 95px);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
  padding-right: 20px;
}
.product_card_mini .product_text .product_title {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  margin: 0;
  font-weight: 400;
  color: #1D1D1B;
}
.product_card_mini .product_text .product_cost {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 1;
  margin: 0;
  font-weight: 600;
}
.product_card_mini .js_add_to_cart_mini, .product_card_mini .in_cart_mini {
  position: absolute;
  bottom: 8px;
  right: 8px;
  cursor: pointer;
  z-index: 5;
}
.product_card_mini .in_cart_mini {
  z-index: 6;
  display: none;
}
.product_card_mini.prd_in_cart .js_add_to_cart_mini {
  display: none;
}
.product_card_mini.prd_in_cart .in_cart_mini {
  display: block;
}

.home_section_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  letter-spacing: 0.05em;
  font-weight: 700;
  margin: 0;
}
.home_section_title {
  font-size: calc(0.0056603774 * 100vw + 17.8490566038px);
}
@media screen and (min-width: 1919px) {
  .home_section_title {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .home_section_title {
    font-size: 20px;
  }
}
.home_section_title {
  line-height: calc(0.0056603774 * 100vw + 25.8490566038px);
}
@media screen and (min-width: 1919px) {
  .home_section_title {
    line-height: 40px;
  }
}
@media screen and (max-width: 380px) {
  .home_section_title {
    line-height: 28px;
  }
}
.home_section_title span {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #fff;
  letter-spacing: 0.05em;
  height: 24px;
  padding: 0 16px;
  margin-left: 30px;
  background-color: #13262F;
  border-radius: 3px;
}
@media (max-width: 900px) {
  .home_section_title span {
    display: none;
  }
}

.home_viewAll {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  color: #0E0E0E;
  gap: 10px;
}
.home_viewAll span {
  font: inherit;
  color: inherit;
}

.hometitleBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.homeHero {
  position: relative;
  padding: 0 0 60px;
}
@media (max-width: 600px) {
  .homeHero {
    padding: 0 0 20px;
  }
}
@media (max-width: 800px) {
  .homeHero .container {
    width: 100%;
    margin: 0;
  }
}
.homeHero .inner {
  position: relative;
}
@media (max-width: 800px) {
  .homeHero .inner {
    padding-bottom: 20px;
  }
}
.homeHero .inner .img {
  width: 100%;
  padding-bottom: 40%;
  position: relative;
}
@media (max-width: 800px) {
  .homeHero .inner .img {
    padding-bottom: 60%;
  }
}
.homeHero .inner .img img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeHero .inner .txt {
  background-color: #fff;
  position: relative;
  z-index: 5;
  width: 65%;
  margin: -115px auto 0;
  padding: 24px 30px;
  min-height: 230px;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1600px) {
  .homeHero .inner .txt {
    width: 80%;
    min-height: min-content;
  }
}
@media (max-width: 1200px) {
  .homeHero .inner .txt {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .homeHero .inner .txt {
    width: 100%;
    margin: 0;
    padding-bottom: 0;
  }
}
.homeHero .inner .txt h4 {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #A38C66;
  font-weight: 500;
  text-align: center;
  margin: 0 0 30px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .homeHero .inner .txt h4 {
    margin: 0 0 20px;
  }
}
.homeHero .inner .txt h3 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 80%;
}
.homeHero .inner .txt h3 {
  font-size: calc(0.0075471698 * 100vw + 29.1320754717px);
}
@media screen and (min-width: 1919px) {
  .homeHero .inner .txt h3 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .homeHero .inner .txt h3 {
    font-size: 32px;
  }
}
.homeHero .inner .txt h3 {
  line-height: calc(0.008490566 * 100vw + 30.7735849057px);
}
@media screen and (min-width: 1919px) {
  .homeHero .inner .txt h3 {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .homeHero .inner .txt h3 {
    line-height: 34px;
  }
}
@media (max-width: 1200px) {
  .homeHero .inner .txt h3 {
    max-width: 100%;
  }
}
.homeHero .inner .txt p {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.homeHero .featured_departments {
  padding: 30px 16px;
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
  display: none;
}
@media (max-width: 600px) {
  .homeHero .featured_departments {
    display: block;
  }
}
.homeHero .featured_departments .grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  padding: 25px 0 0;
}
.homeHero .featured_departments .grid .column .image_wrapper {
  width: 100%;
  border-radius: 3px;
  overflow: hidden;
}
.homeHero .featured_departments .grid .column .image_wrapper .img {
  width: 100%;
  position: relative;
  padding-top: 100%;
}
.homeHero .featured_departments .grid .column .image_wrapper .img img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.homeHero .featured_departments .grid .column .text_wrapper h4 {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
  text-align: center;
  margin: 0;
  padding: 12px 0 15px;
  color: #484848;
}
.homeHero .featured_departments .see_all {
  text-align: center;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #A38C66;
  text-transform: uppercase;
}
.homeHero .featured_departments .see_all a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.productslider {
  padding: 0 0 60px;
}
@media (max-width: 600px) {
  .productslider {
    padding: 0 0 50px;
  }
}

.slider {
  position: relative;
}
.slider .swiper-button-next-custom, .slider .swiper-button-prev-custom {
  position: absolute;
  z-index: 10;
  top: 50%;
  transform: translateY(-50%);
  right: -20px;
  background-color: #fff;
  border: 1px solid #E6E6E6;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}
@media (max-width: 800px) {
  .slider .swiper-button-next-custom, .slider .swiper-button-prev-custom {
    display: none;
  }
}
.slider .swiper-button-prev-custom {
  right: auto;
  left: -20px;
}
.slider .swiper-button-disabled {
  pointer-events: none;
}
.slider .swiper-button-disabled img, .slider .swiper-button-disabled svg {
  opacity: 0.5;
}
.slider .swiper-button-lock {
  display: none;
}
@media (max-width: 1000px) {
  .slider .swiper {
    overflow: visible;
  }
}

.recipeSlider {
  padding: 0 0 60px;
}
@media (max-width: 600px) {
  .recipeSlider {
    padding: 0 0 10px;
  }
}
.recipeSlider .recipre_card .recipe_image {
  width: 100%;
  padding-top: 65%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.recipeSlider .recipre_card .recipe_image img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.recipeSlider .recipre_card .recipe_info {
  padding: 25px 0 0;
}
.recipeSlider .recipre_card .recipe_info .recipe_details {
  color: #A38C66;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.08em;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  margin: 0 0 24px;
}
.recipeSlider .recipre_card .recipe_info .recipe_details .serving {
  display: inline-block;
  justify-content: center;
  align-items: center;
  background-color: #EBE6DE;
  height: 18px;
  border-radius: 10px;
  padding: 0 6px;
  line-height: 18px;
}
.recipeSlider .recipre_card .recipe_info .recipe_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  line-height: 1;
  margin: 0;
}
.recipeSlider .recipre_card .recipe_info .recipe_title {
  font-size: calc(0.0018867925 * 100vw + 27.2830188679px);
}
@media screen and (min-width: 1919px) {
  .recipeSlider .recipre_card .recipe_info .recipe_title {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .recipeSlider .recipre_card .recipe_info .recipe_title {
    font-size: 28px;
  }
}

.featured_recipe {
  padding: 60px 0;
}
@media (max-width: 600px) {
  .featured_recipe {
    padding: 60px 0 20px;
  }
}
.featured_recipe .inner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
}
@media (max-width: 720px) {
  .featured_recipe .inner {
    grid-template-columns: 1fr;
  }
}
.featured_recipe .inner a {
  position: relative;
  padding-top: 100%;
}
.featured_recipe .inner .image_container {
  background-color: #EBE6DE;
  border-radius: 5px;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (max-width: 1200px) {
  .featured_recipe .inner .image_container {
    border-radius: 5px;
    overflow: hidden;
  }
}
.featured_recipe .inner .image_container img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured_recipe .inner .text_container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 120px;
}
@media (max-width: 1400px) {
  .featured_recipe .inner .text_container {
    padding-left: 60px;
  }
}
@media (max-width: 1200px) {
  .featured_recipe .inner .text_container {
    padding-left: 0;
  }
}
.featured_recipe .inner .text_container .top .tagline {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #A38C66;
  text-transform: uppercase;
  margin: 0 0 30px;
}
@media (max-width: 1200px) {
  .featured_recipe .inner .text_container .top .tagline {
    text-align: center;
  }
}
.featured_recipe .inner .text_container .top h3 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  line-height: 1;
  margin: 0;
}
.featured_recipe .inner .text_container .top h3 {
  font-size: calc(0.008490566 * 100vw + 26.7735849057px);
}
@media screen and (min-width: 1919px) {
  .featured_recipe .inner .text_container .top h3 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .featured_recipe .inner .text_container .top h3 {
    font-size: 30px;
  }
}
@media (max-width: 1200px) {
  .featured_recipe .inner .text_container .top h3 {
    margin: 0 0 20px;
    text-align: center;
  }
}
.featured_recipe .inner .text_container .bottom .description {
  font-weight: 400;
  margin: 0 0 20px;
}
.featured_recipe .inner .text_container .bottom .description {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .featured_recipe .inner .text_container .bottom .description {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .featured_recipe .inner .text_container .bottom .description {
    font-size: 14px;
  }
}
.featured_recipe .inner .text_container .bottom .description {
  line-height: calc(0.0018867925 * 100vw + 21.2830188679px);
}
@media screen and (min-width: 1919px) {
  .featured_recipe .inner .text_container .bottom .description {
    line-height: 26px;
  }
}
@media screen and (max-width: 380px) {
  .featured_recipe .inner .text_container .bottom .description {
    line-height: 22px;
  }
}
@media (max-width: 1200px) {
  .featured_recipe .inner .text_container .bottom .description {
    text-align: center;
  }
}
@media (max-width: 1200px) {
  .featured_recipe .inner .text_container .bottom .readMore {
    display: none;
  }
}

.featured_product_slider {
  padding: 0 0 60px;
}
@media (max-width: 600px) {
  .featured_product_slider {
    padding: 0 0 10px;
  }
}
.featured_product_slider .inner {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.featured_product_slider .inner .slider {
  width: calc(100% - 480px);
}
@media (max-width: 900px) {
  .featured_product_slider .inner .slider {
    width: 100%;
  }
}
.featured_product_slider .inner a.cat_link {
  display: block;
}
@media (max-width: 900px) {
  .featured_product_slider .inner a.cat_link {
    width: 100%;
  }
}
.featured_product_slider .inner .category_range {
  width: 460px;
  height: inherit;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 900px) {
  .featured_product_slider .inner .category_range {
    width: 100%;
  }
}
.featured_product_slider .inner .category_range .recipe_image {
  width: 100%;
  padding-top: 512px;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 900px) {
  .featured_product_slider .inner .category_range .recipe_image {
    padding-top: 100%;
  }
}
.featured_product_slider .inner .category_range .recipe_image svg {
  position: absolute;
  z-index: 3;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.featured_product_slider .inner .category_range .recipe_image img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured_product_slider .inner .category_range .recipe_text {
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0 0 30px;
}
.featured_product_slider .inner .category_range .recipe_text .recipe_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 32px;
  line-height: 1;
  color: #fff;
  margin: 0 0 16px;
  text-align: center;
}
.featured_product_slider .inner .category_range .recipe_text .readMore {
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  gap: 10px;
  width: 100%;
}

.explore_recipe_categories {
  background-color: #F4F3EE;
  padding: 25px 0 50px;
  margin-bottom: 60px;
}
@media (max-width: 600px) {
  .explore_recipe_categories {
    padding: 0 0 50px;
    margin-bottom: 20px;
  }
}
.explore_recipe_categories .explore_recipe_grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 30px;
  padding: 20px 0 36px;
  border-top: 1px solid #EBE6DE;
  border-bottom: 1px solid #EBE6DE;
}
@media (max-width: 900px) {
  .explore_recipe_categories .explore_recipe_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 600px) {
  .explore_recipe_categories .explore_recipe_grid {
    grid-template-columns: 1fr;
  }
}
.explore_recipe_categories .explore_recipe_grid .recipe_card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .image_wrapper {
  width: 35%;
  min-width: 35%;
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .image_wrapper .img {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .image_wrapper .img img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .category {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  color: #A38C66;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  letter-spacing: -0.02em;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
  font-size: calc(0.0009433962 * 100vw + 17.641509434px);
}
@media screen and (min-width: 1919px) {
  .explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
    font-size: 20px;
  }
}
@media screen and (max-width: 380px) {
  .explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
    font-size: 18px;
  }
}
.explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
  line-height: calc(0.0014150943 * 100vw + 21.4622641509px);
}
@media screen and (min-width: 1919px) {
  .explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
    line-height: 25px;
  }
}
@media screen and (max-width: 380px) {
  .explore_recipe_categories .explore_recipe_grid .recipe_card .text_wrapper .recipe_title {
    line-height: 22px;
  }
}
.explore_recipe_categories .explore_categories {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  padding-top: 50px;
}
@media (max-width: 900px) {
  .explore_recipe_categories .explore_categories {
    grid-template-columns: 1fr;
  }
}
@media (max-width: 600px) {
  .explore_recipe_categories .explore_categories {
    padding-top: 10px;
  }
}
.explore_recipe_categories .explore_categories .slider {
  grid-area: 1/2/2/6;
}
@media (max-width: 900px) {
  .explore_recipe_categories .explore_categories .slider {
    grid-area: 1/1/2/2;
    min-width: 100%;
  }
}
.explore_recipe_categories .categories {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}
@media (max-width: 900px) {
  .explore_recipe_categories .categories {
    display: none;
  }
}
.explore_recipe_categories .categories .home_section_title {
  max-width: 80%;
}
.explore_recipe_categories .categories .bottom ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 18px;
  margin-bottom: 30px;
}
.explore_recipe_categories .categories .bottom ul li {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.05em;
  text-transform: capitalize;
}
.explore_recipe_categories .category_link:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.explore_recipe_categories .category_link:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.explore_recipe_categories .featured_category {
  position: relative;
  overflow: hidden;
}
@media (max-width: 1200px) {
  .explore_recipe_categories .featured_category {
    border-radius: 5px;
    overflow: hidden;
  }
}
.explore_recipe_categories .featured_category .image_wrapper {
  width: 100%;
  padding-top: 135%;
  position: relative;
}
.explore_recipe_categories .featured_category .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.explore_recipe_categories .featured_category .image_wrapper .featured_cat_overlay {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.explore_recipe_categories .featured_category .text_wrapper {
  position: absolute;
  z-index: 5;
  bottom: 0;
  left: 0;
  width: 100%;
}
.explore_recipe_categories .featured_category .text_wrapper .category_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 32px;
  line-height: 1;
  letter-spacing: 0.05em;
  margin: 0 0 18px;
  color: #fff;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
}
.explore_recipe_categories .featured_category .text_wrapper .read_more {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: center;
  gap: 10px;
  width: 100%;
  margin: 0 0 24px;
}
.explore_recipe_categories .mobile_category_slider {
  padding-top: 20px;
  display: none;
}
@media (max-width: 900px) {
  .explore_recipe_categories .mobile_category_slider {
    display: block;
  }
}
.explore_recipe_categories .mobile_category_slider .swiper-slide {
  width: auto;
}
.explore_recipe_categories .mobile_category_slider .swiper-slide a {
  border: 1px solid #969191;
  color: #969191;
  height: 44px;
  padding: 0 22px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
}

.featured_article {
  background-color: #13262F;
  padding: 0 0 50px;
  width: 100%;
  overflow: hidden;
}
@media (max-width: 800px) {
  .featured_article {
    background-color: #fff;
  }
}
@media (max-width: 600px) {
  .featured_article {
    padding: 0 0 10px;
  }
}
.featured_article .container {
  position: relative;
}
@media (max-width: 800px) {
  .featured_article .container:nth-child(2) {
    width: 100%;
  }
}
.featured_article .container .hometitleBar, .featured_article .container .article_featured {
  position: relative;
  z-index: 2;
}
.featured_article .bgWhiteAfter:first-child::after {
  content: "";
  background-color: #fff;
  position: absolute;
  z-index: 0;
  width: 140%;
  height: 100%;
  top: 0;
  left: -20%;
}
@media (max-width: 800px) {
  .featured_article .bgWhiteAfter:first-child::after {
    display: none;
  }
}
.featured_article .bgWhiteAfter:nth-child(2)::after {
  content: "";
  background-color: #fff;
  position: absolute;
  z-index: 0;
  width: 140%;
  height: 30%;
  top: 0;
  left: -20%;
}
@media (max-width: 800px) {
  .featured_article .bgWhiteAfter:nth-child(2)::after {
    display: none;
  }
}
@media (max-width: 800px) {
  .featured_article .article_featured {
    background-color: #13262F;
  }
}
.featured_article .article_featured .inner {
  position: relative;
}
.featured_article .article_featured .inner .img {
  width: 100%;
  padding-bottom: 50%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 800px) {
  .featured_article .article_featured .inner .img {
    border-radius: 0;
  }
}
.featured_article .article_featured .inner .img img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured_article .article_featured .inner .txt {
  background-color: #13262F;
  position: relative;
  z-index: 5;
  width: 65%;
  margin: -115px auto 0;
  padding: 24px 30px;
  min-height: 230px;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1600px) {
  .featured_article .article_featured .inner .txt {
    width: 80%;
  }
}
@media (max-width: 1200px) {
  .featured_article .article_featured .inner .txt {
    width: 90%;
  }
}
@media (max-width: 800px) {
  .featured_article .article_featured .inner .txt {
    width: 100%;
    margin: 0;
    min-height: min-content;
    padding-bottom: 20px;
  }
}
.featured_article .article_featured .inner .txt h4 {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: #A38C66;
  font-weight: 500;
  text-align: center;
  margin: 0 0 30px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .featured_article .article_featured .inner .txt h4 {
    margin: 0 0 20px;
  }
}
.featured_article .article_featured .inner .txt h3 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-weight: 400;
  text-align: center;
  margin: 0 auto 20px;
  max-width: 80%;
  color: #fff;
}
.featured_article .article_featured .inner .txt h3 {
  font-size: calc(0.0075471698 * 100vw + 29.1320754717px);
}
@media screen and (min-width: 1919px) {
  .featured_article .article_featured .inner .txt h3 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .featured_article .article_featured .inner .txt h3 {
    font-size: 32px;
  }
}
.featured_article .article_featured .inner .txt h3 {
  line-height: calc(0.008490566 * 100vw + 30.7735849057px);
}
@media screen and (min-width: 1919px) {
  .featured_article .article_featured .inner .txt h3 {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .featured_article .article_featured .inner .txt h3 {
    line-height: 34px;
  }
}
@media (max-width: 1200px) {
  .featured_article .article_featured .inner .txt h3 {
    max-width: 100%;
  }
}
.featured_article .article_featured .inner .txt p {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  text-align: center;
  margin: 0;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  color: #fff;
}
.featured_article .article_grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 36px;
  padding: 30px 0 0;
}
@media (max-width: 800px) {
  .featured_article .article_grid {
    padding: 30px 16px;
    grid-template-columns: 1fr;
    background-color: #13262F;
  }
}
.featured_article .article_grid a:last-child .article_card::after {
  display: none;
}
.featured_article .article_grid .article_card {
  position: relative;
}
@media (max-width: 800px) {
  .featured_article .article_grid .article_card {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 15px;
  }
}
.featured_article .article_grid .article_card::after {
  content: "";
  background-color: #36464E;
  width: 1px;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  right: -18px;
}
@media (max-width: 800px) {
  .featured_article .article_grid .article_card::after {
    display: none;
  }
}
.featured_article .article_grid .article_card .image_wrapper {
  width: 100%;
}
@media (max-width: 800px) {
  .featured_article .article_grid .article_card .image_wrapper {
    width: 35%;
    min-width: 35%;
    border-radius: 5px;
    overflow: hidden;
  }
}
.featured_article .article_grid .article_card .image_wrapper .img_inner {
  width: 100%;
  padding-top: 65%;
  position: relative;
}
@media (max-width: 800px) {
  .featured_article .article_grid .article_card .image_wrapper .img_inner {
    padding-top: 95%;
    border-radius: 5px;
    overflow: hidden;
  }
}
.featured_article .article_grid .article_card .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.featured_article .article_grid .article_card .text_wrapper {
  padding: 22px 0 0;
}
@media (max-width: 800px) {
  .featured_article .article_grid .article_card .text_wrapper {
    padding: 0;
  }
}
.featured_article .article_grid .article_card .text_wrapper .article_info {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  color: #A38C66;
  margin: 0 0 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
@media (max-width: 800px) {
  .featured_article .article_grid .article_card .text_wrapper .article_info {
    margin: 0 0 15px;
  }
}
.featured_article .article_grid .article_card .text_wrapper .article_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  line-height: 1;
  margin: 0;
  color: #fff;
}
.featured_article .article_grid .article_card .text_wrapper .article_title {
  font-size: calc(0.0066037736 * 100vw + 15.4905660377px);
}
@media screen and (min-width: 1919px) {
  .featured_article .article_grid .article_card .text_wrapper .article_title {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .featured_article .article_grid .article_card .text_wrapper .article_title {
    font-size: 18px;
  }
}
.featured_article .article_grid .article_card .text_wrapper .article_title {
  line-height: calc(0.0066037736 * 100vw + 21.4905660377px);
}
@media screen and (min-width: 1919px) {
  .featured_article .article_grid .article_card .text_wrapper .article_title {
    line-height: 38px;
  }
}
@media screen and (max-width: 380px) {
  .featured_article .article_grid .article_card .text_wrapper .article_title {
    line-height: 24px;
  }
}

.product_hero {
  padding: 30px 0;
}
@media (max-width: 600px) {
  .product_hero {
    padding: 10px 0 30px;
  }
}
.product_hero .inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.product_hero .inner .breadcrumbs {
  padding: 0 0 30px;
}
.product_hero .inner .breadcrumbs ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
}
.product_hero .inner .breadcrumbs ul li {
  font-size: 18px;
  line-height: 1;
  position: relative;
  color: #0E0E0E;
}
.product_hero .inner .breadcrumbs ul li a {
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.product_hero .inner .breadcrumbs ul li a:hover {
  color: #969191;
}
.product_hero .inner .breadcrumbs ul li svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: -22px;
  transform: translateY(-50%);
}
.product_hero .inner .breadcrumbs {
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  padding: 0;
}
.product_hero .inner .send-gift {
  height: 44px;
  background-color: #F4F3EE;
  border-radius: 3px;
  color: #E12C2C;
  border: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  padding: 0 20px;
  position: absolute;
  z-index: 10;
  top: 40px;
  left: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 600px) {
  .product_hero .inner .send-gift {
    top: 30px;
    height: 36px;
    padding: 0 12px;
  }
}
.product_hero .inner .send-gift svg {
  width: 18px;
  height: auto;
}
.product_hero .inner .send-gift:hover {
  background-color: #E12C2C;
  color: #fff;
}
.product_hero .inner .send-gift:hover svg path {
  fill: #fff;
}
.product_hero .inner > .left {
  width: 60%;
  position: relative;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1200px) {
  .product_hero .inner > .left {
    width: 60%;
  }
}
@media (max-width: 800px) {
  .product_hero .inner > .left {
    width: 100%;
  }
}
@media (max-width: 1000px) {
  .product_hero .inner > .left .product_images {
    width: 100%;
    overflow: hidden;
  }
  .product_hero .inner > .left .product_images .swiper-button-next-custom, .product_hero .inner > .left .product_images .swiper-button-prev-custom {
    display: none;
  }
}
.product_hero .inner > .left .slider .image_wrapper {
  width: 100%;
  padding-top: 100%;
  position: relative;
}
.product_hero .inner > .left .slider .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 85%;
  height: 85%;
  object-fit: contain;
}
.product_hero .inner > .right {
  width: 30%;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 1200px) {
  .product_hero .inner > .right {
    width: 40%;
  }
}
@media (max-width: 800px) {
  .product_hero .inner > .right {
    width: 100%;
  }
}
.product_hero .inner > .right .product_details {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.product_hero .inner > .right .product_details .details_card {
  background-color: #F7F7F7;
  border-radius: 5px;
  overflow: hidden;
  padding: 24px 20px;
}
.product_hero .inner > .right .product_details .details_card.no_padd {
  padding: 0;
}
.product_hero .inner > .right .product_details .country_of_origin {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.1em;
  color: #A38C66;
  text-transform: uppercase;
  margin: 0 0 25px;
}
.product_hero .inner > .right .product_details .product_title {
  line-height: 32px;
  font-weight: 600;
  margin: 0 0 20px;
  color: #1A1A1A;
}
.product_hero .inner > .right .product_details .product_title {
  font-size: calc(0.0028301887 * 100vw + 22.9245283019px);
}
@media screen and (min-width: 1919px) {
  .product_hero .inner > .right .product_details .product_title {
    font-size: 30px;
  }
}
@media screen and (max-width: 380px) {
  .product_hero .inner > .right .product_details .product_title {
    font-size: 24px;
  }
}
.product_hero .inner > .right .product_details .product_qty {
  display: none;
}
.product_hero .inner > .right .product_details .product_price {
  line-height: 1;
  font-weight: 600;
  margin: 0;
  color: #1A1A1A;
}
.product_hero .inner > .right .product_details .product_price {
  font-size: calc(0.0009433962 * 100vw + 15.641509434px);
}
@media screen and (min-width: 1919px) {
  .product_hero .inner > .right .product_details .product_price {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .product_hero .inner > .right .product_details .product_price {
    font-size: 16px;
  }
}
.product_hero .inner > .right .product_details .section_title {
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 20px;
  color: #1A1A1A;
}
.product_hero .inner > .right .product_details .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_hero .inner > .right .product_details .product_tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
  padding: 20px 0 0;
}
.product_hero .inner > .right .product_details .product_tags .tag {
  padding: 0 10px;
  border-radius: 3px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.04em;
  height: 36px;
}
.product_hero .inner > .right .product_details .product_tags .tag.filled {
  background-color: #969191;
  color: #fff;
  border: 1px solid #969191;
}
.product_hero .inner > .right .product_details .product_tags .tag.outlined {
  background-color: #fff;
  color: #969191;
  border: 1px dashed #969191;
}
.product_hero .inner > .right .product_details .product_sizes {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
  gap: 6px;
}
.product_hero .inner > .right .product_details .product_sizes input {
  display: none;
}
.product_hero .inner > .right .product_details .product_sizes .size {
  flex-grow: 1;
}
.product_hero .inner > .right .product_details .product_sizes .size label {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border: 1px solid #969191;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #969191;
  border-radius: 3px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  text-transform: uppercase;
}
.product_hero .inner > .right .product_details .product_sizes .size input:checked + label {
  background-color: #A38C66;
  color: #fff;
  border-color: #A38C66;
}
.product_hero .inner > .right .product_details .cart_action {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 10px 0 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #E6E6E6;
}
.product_hero .inner > .right .product_details .cart_action .product_qty {
  width: 100px;
  height: 44px;
  background-color: #E6E6E6;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 3px;
}
.product_hero .inner > .right .product_details .cart_action .product_qty input {
  width: 70px;
  height: 36px;
  border-radius: 5px;
  padding: 0 10px;
}
.product_hero .inner > .right .product_details .cart_action .product_qty .right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 30px;
}
.product_hero .inner > .right .product_details .cart_action .add-to-cart {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-grow: 1;
  width: calc(100% - 110px);
  border: 0;
  background-color: #13262F;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
}
.product_hero .inner > .right .product_details .save_product {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  border: 0;
  height: 44px;
  background-color: #F4F3EE;
  width: 100%;
}
.product_hero .inner > .right .product_details .save_product.saved svg .fill {
  fill: #000000;
}
.product_hero .inner > .right .product_details .subscription_mode {
  display: grid;
  grid-template-columns: 1fr;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option input {
  display: none;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option label {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  padding: 20px;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option input:checked + label {
  background-color: #EFECE8;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option input:checked + label .left .checkbox svg #Rectangle_8973 {
  fill: #A38C66;
  stroke: #A38C66;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option input:checked + label .left .checkbox svg path {
  fill: #fff;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  flex-grow: 1;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .left .text h4 {
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.03em;
  margin: 0 0 10px;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .left .text p {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: #969191;
  margin: 0;
  text-transform: capitalize;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .left .text .select2-container {
  margin: 20px 0 0;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .left .text select {
  margin: 20px 0 0;
  padding: 0 10px;
  border-color: #969191;
  color: #969191;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .right {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  max-width: 130px;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .right p {
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.03em;
  font-weight: 600;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 5px;
}
.product_hero .inner > .right .product_details .subscription_mode .subscription_option .right p span.old_price {
  font-size: 14px;
  color: #969191;
  text-decoration: line-through;
}

.product_details_info {
  border-top: 1px solid #E6E6E6;
  border-bottom: 1px solid #E6E6E6;
}
.product_details_info ul {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}
@media (max-width: 1000px) {
  .product_details_info ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    height: min-content;
  }
}
.product_details_info ul li {
  display: grid;
  grid-template-columns: 1fr;
  min-width: 220px;
  gap: 20px;
  border-right: 1px solid #E6E6E6;
  padding: 0 20px;
}
@media (max-width: 1000px) {
  .product_details_info ul li {
    border-right: 0;
    padding: 25px 0;
    min-width: inherit;
  }
}
.product_details_info ul li:first-child {
  padding-left: 0;
}
@media (max-width: 1000px) {
  .product_details_info ul li:first-child {
    border-bottom: 1px solid #E6E6E6;
  }
}
@media (max-width: 1000px) {
  .product_details_info ul li:nth-child(2) {
    border-bottom: 1px solid #E6E6E6;
  }
}
.product_details_info ul li:last-child {
  border-right: 0;
}
@media (max-width: 1000px) {
  .product_details_info ul li:last-child {
    display: none;
  }
}
.product_details_info ul li span:first-child {
  font-size: 18px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.03em;
}
.product_details_info ul li span:last-child {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: #969191;
}
.product_details_info ul li.review span:first-child {
  color: #A38C66;
}
.product_details_info ul li.review .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_details_info ul li.review .bottom span {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.03em;
  color: #A38C66;
}
.product_details_info ul li.review .bottom span:last-child {
  color: #13262F;
  cursor: pointer;
  font-weight: 500;
}

.product_description {
  padding: 60px 0 0;
}
@media (max-width: 1200px) {
  .product_description {
    padding: 30px 0 0;
  }
}
.product_description p {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.1em;
  font-weight: 500;
  color: #A38C66;
  text-align: center;
  text-transform: uppercase;
  margin: 0 0 35px;
}
@media (max-width: 1200px) {
  .product_description p {
    margin: 0 0 20px;
  }
}
.product_description h3 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  text-align: center;
  margin: 0 auto 60px;
  max-width: 70%;
}
.product_description h3 {
  font-size: calc(0.0103773585 * 100vw + 22.0566037736px);
}
@media screen and (min-width: 1919px) {
  .product_description h3 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .product_description h3 {
    font-size: 26px;
  }
}
.product_description h3 {
  line-height: calc(0.0103773585 * 100vw + 24.0566037736px);
}
@media screen and (min-width: 1919px) {
  .product_description h3 {
    line-height: 50px;
  }
}
@media screen and (max-width: 380px) {
  .product_description h3 {
    line-height: 28px;
  }
}
@media (max-width: 720px) {
  .product_description h3 {
    max-width: calc(100% - 40px);
    margin: 0 0 30px;
  }
}

.related_recipe {
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.related_recipe.article-internal {
  border-bottom: 1px solid #EBE6DE;
}
.related_recipe.article-internal .slider {
  border: 0;
}
.related_recipe.trending .hometitleBar {
  border-top: 1px solid #EBE6DE;
}
.related_recipe.trending .slider {
  border-top: 0;
  border-bottom: 0;
}
.related_recipe .slider {
  border-top: 1px solid #EBE6DE;
  border-bottom: 1px solid #EBE6DE;
  padding: 20px 0 36px;
}
@media (max-width: 1200px) {
  .related_recipe .slider .swiper {
    overflow: visible;
  }
}
.related_recipe .recipe_card {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;
  width: 100%;
}
.related_recipe .recipe_card .image_wrapper {
  width: 35%;
  min-width: 35%;
}
.related_recipe .recipe_card .image_wrapper .img {
  width: 100%;
  padding-top: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.related_recipe .recipe_card .image_wrapper .img img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.related_recipe .recipe_card .text_wrapper .category {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  color: #A38C66;
  text-transform: uppercase;
  margin: 0 0 20px;
}
.related_recipe .recipe_card .text_wrapper .recipe_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  letter-spacing: -0.02em;
  margin: 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}
.related_recipe .recipe_card .text_wrapper .recipe_title {
  font-size: calc(0.0009433962 * 100vw + 17.641509434px);
}
@media screen and (min-width: 1919px) {
  .related_recipe .recipe_card .text_wrapper .recipe_title {
    font-size: 20px;
  }
}
@media screen and (max-width: 380px) {
  .related_recipe .recipe_card .text_wrapper .recipe_title {
    font-size: 18px;
  }
}
.related_recipe .recipe_card .text_wrapper .recipe_title {
  line-height: calc(0.0014150943 * 100vw + 21.4622641509px);
}
@media screen and (min-width: 1919px) {
  .related_recipe .recipe_card .text_wrapper .recipe_title {
    line-height: 25px;
  }
}
@media screen and (max-width: 380px) {
  .related_recipe .recipe_card .text_wrapper .recipe_title {
    line-height: 22px;
  }
}

.product_reviews {
  padding: 0 0 60px;
  width: 100%;
  overflow: hidden;
}
.product_reviews .review_inner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
}
.product_reviews .review_inner .review_info {
  width: 330px;
  background-color: #F4F3EE;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
}
@media (max-width: 1000px) {
  .product_reviews .review_inner .review_info {
    width: 100%;
  }
}
.product_reviews .review_inner .review_info .top {
  padding: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.product_reviews .review_inner .review_info .top .score {
  font-size: 60px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.03em;
  color: #A38C66;
}
.product_reviews .review_inner .review_info .top .score_info .review_totals {
  padding: 5px 0 0;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
  color: #969191;
}
.product_reviews .review_inner .review_info .top .score_info .review_totals a {
  font-weight: 500;
  color: #A38C66;
  text-decoration: underline;
}
.product_reviews .review_inner .review_info .bottom {
  padding: 15px;
  border-top: 1px solid #E6E6E6;
}
.product_reviews .review_inner .review_info .bottom button {
  border: 0;
  background-color: #13262F;
  color: #fff;
  width: 100%;
}
.product_reviews .review_inner .user_reviews {
  width: calc(100% - 350px);
}
@media (max-width: 1000px) {
  .product_reviews .review_inner .user_reviews {
    width: 100%;
  }
}
.product_reviews .review_inner .user_reviews .swiper-slide {
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  overflow: hidden;
  padding: 20px 15px;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_info .user_pic {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_info .user_pic img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_info .user_details .user_name {
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.03em;
  margin: 0 0 10px;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_info .user_details .user_location {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.03em;
  margin: 0;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_stars {
  padding: 15px 0 7px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_stars .stars svg {
  width: 10px;
  height: auto;
}
.product_reviews .review_inner .user_reviews .swiper-slide .user_stars .date_published {
  font-size: 14px;
  line-height: 1;
}
.product_reviews .review_inner .user_reviews .swiper-slide .review_comment {
  font-size: 16px;
  line-height: 22px;
  margin: 0;
}
.product_reviews .review_inner .user_reviews .swiper-slide .review_comment p {
  font: inherit;
  color: inherit;
  margin: 0;
}

.product_details_info + .product_reviews {
  padding-top: 60px;
}

.product-review-modal {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
  display: none;
  justify-content: center;
  align-items: center;
}
.product-review-modal.open {
  display: flex;
}
.product-review-modal .reviewProduct {
  width: 100%;
  max-width: 480px;
  padding: 30px 20px;
  border-radius: 10px;
  background-color: #fff;
  position: relative;
}
.product-review-modal .reviewProduct .close-review {
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 20px;
}
.product-review-modal .reviewProduct h3 {
  line-height: 1;
  margin: 0 0 20px;
  font-weight: 500;
  letter-spacing: 0.03em;
}
.product-review-modal .reviewProduct h3 {
  font-size: calc(0.0037735849 * 100vw + 16.5660377358px);
}
@media screen and (min-width: 1919px) {
  .product-review-modal .reviewProduct h3 {
    font-size: 26px;
  }
}
@media screen and (max-width: 380px) {
  .product-review-modal .reviewProduct h3 {
    font-size: 18px;
  }
}
.product-review-modal .reviewProduct .stars {
  display: flex;
  gap: 5px;
}
.product-review-modal .reviewProduct .stars input[type=radio] {
  display: none;
}
.product-review-modal .reviewProduct .stars label {
  cursor: pointer;
}
.product-review-modal .reviewProduct .stars label svg {
  opacity: 0.25;
  filter: grayscale(1);
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.product-review-modal .reviewProduct .stars label.highlight svg {
  opacity: 1;
  filter: none;
}
.product-review-modal .reviewProduct textarea {
  background-color: transparent;
}
.product-review-modal .reviewProduct .button_row {
  display: flex;
  justify-content: flex-end;
}
.product-review-modal .reviewProduct .button_row button {
  width: 100%;
  max-width: 180px;
  height: 44px;
}

.shop_title_block {
  padding: 25px 0 45px;
  overflow: visible;
  overflow-x: visible;
}
.shop_title_block .breadcrumbs {
  padding: 0 0 30px;
}
.shop_title_block .breadcrumbs ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
}
.shop_title_block .breadcrumbs ul li {
  font-size: 18px;
  line-height: 1;
  position: relative;
  color: #0E0E0E;
}
.shop_title_block .breadcrumbs ul li a {
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.shop_title_block .breadcrumbs ul li a:hover {
  color: #969191;
}
.shop_title_block .breadcrumbs ul li svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: -22px;
  transform: translateY(-50%);
}
.shop_title_block .page_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0 0 20px;
  font-weight: 700;
  color: #0E0E0E;
}
.shop_title_block .page_title {
  font-size: calc(0.0056603774 * 100vw + 17.8490566038px);
}
@media screen and (min-width: 1919px) {
  .shop_title_block .page_title {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .shop_title_block .page_title {
    font-size: 20px;
  }
}
.shop_title_block .page_title {
  line-height: calc(0.0056603774 * 100vw + 25.8490566038px);
}
@media screen and (min-width: 1919px) {
  .shop_title_block .page_title {
    line-height: 40px;
  }
}
@media screen and (max-width: 380px) {
  .shop_title_block .page_title {
    line-height: 28px;
  }
}
.shop_title_block .category_description {
  margin: 0 0 30px;
  width: 100%;
  max-width: 1100px;
  color: #0E0E0E;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}
.shop_title_block .category_description {
  font-size: calc(0.0009433962 * 100vw + 15.641509434px);
}
@media screen and (min-width: 1919px) {
  .shop_title_block .category_description {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .shop_title_block .category_description {
    font-size: 16px;
  }
}
.shop_title_block .category_description {
  line-height: calc(-0.0018867925 * 100vw + 28.7169811321px);
}
@media screen and (min-width: 1919px) {
  .shop_title_block .category_description {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .shop_title_block .category_description {
    line-height: 28px;
  }
}
@media (max-width: 1200px) {
  .shop_title_block .category_description {
    margin: 0;
  }
}
.shop_title_block .category_description a {
  color: #A38C66;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.shop_title_block .filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;
}
@media (max-width: 600px) {
  .shop_title_block .filters {
    flex-direction: column;
  }
}
.shop_title_block .filters .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  width: calc(100% - 121px);
}
@media (max-width: 600px) {
  .shop_title_block .filters .left {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .shop_title_block .filters .left .all-filters {
    display: none;
  }
}
.shop_title_block .filters .left .categroy_slider {
  width: calc(100% - 144px);
  position: relative;
}
@media (max-width: 600px) {
  .shop_title_block .filters .left .categroy_slider {
    width: 100%;
  }
}
.shop_title_block .filters .left .categroy_slider::after {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  content: "";
  width: 60px;
  height: 44px;
  background: rgb(255, 255, 255);
  background: linear-gradient(267deg, rgb(255, 255, 255) 41%, rgba(255, 255, 255, 0) 100%);
}
@media (max-width: 600px) {
  .shop_title_block .filters .left .categroy_slider::after {
    display: none;
  }
}
.shop_title_block .filters .left .categroy_slider .swiper-slide {
  width: auto;
}
@media (max-width: 600px) {
  .shop_title_block .filters .right {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}
.shop_title_block .filters .right .all-filters {
  display: none;
}
@media (max-width: 600px) {
  .shop_title_block .filters .right .all-filters {
    display: inline-flex;
  }
}
.shop_title_block .filters .right .sort_menu {
  position: relative;
}
@media (max-width: 600px) {
  .shop_title_block .filters .right .sort_menu a {
    display: inline-flex;
    width: 100%;
  }
}
.shop_title_block .filters .right .sort_menu ul {
  position: absolute;
  z-index: 99;
  top: 44px;
  right: 0;
  background-color: #fff;
  display: none;
  grid-template-columns: 1fr;
  gap: 2px;
  min-width: 175px;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.15);
  padding: 10px 0;
}
.shop_title_block .filters .right .sort_menu ul.open {
  display: grid;
}
@media (max-width: 600px) {
  .shop_title_block .filters .right .sort_menu ul {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    gap: 8px;
  }
  .shop_title_block .filters .right .sort_menu ul::-webkit-scrollbar {
    width: 5px;
  }
  .shop_title_block .filters .right .sort_menu ul::-webkit-scrollbar-track {
    box-shadow: none;
    background-color: #bdbdbd;
  }
  .shop_title_block .filters .right .sort_menu ul::-webkit-scrollbar-thumb {
    background-color: #4e4e4e;
    border-radius: 3px;
  }
  .shop_title_block .filters .right .sort_menu ul.open {
    display: block;
  }
}
.shop_title_block .filters .right .sort_menu ul .topbar {
  display: none;
  padding: 10px 15px;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 600px) {
  .shop_title_block .filters .right .sort_menu ul .topbar {
    display: flex;
  }
}
.shop_title_block .filters .right .sort_menu ul .topbar h3 {
  font-size: 26px;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.01em;
  margin: 0;
}
.shop_title_block .filters .right .sort_menu ul .topbar button {
  appearance: none;
  background-color: #fff;
  border: 0;
  outline: none;
}
.shop_title_block .filters .right .sort_menu ul li {
  cursor: pointer;
  background-color: #fff;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  padding: 8px 15px;
  font-size: 18px;
  line-height: 1;
}
@media (max-width: 600px) {
  .shop_title_block .filters .right .sort_menu ul li {
    padding: 15px;
  }
}
.shop_title_block .filters .right .sort_menu ul li:hover, .shop_title_block .filters .right .sort_menu ul li.active {
  background-color: rgb(243, 243, 243);
}
.shop_title_block .filters a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #969191;
  border: 1px solid #969191;
  height: 44px;
  border-radius: 3px;
  padding: 0 16px;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  text-transform: capitalize;
}
.shop_title_block .filters a:hover, .shop_title_block .filters a.active {
  background-color: #13262F;
  border-color: #13262F;
  color: #fff;
}
.shop_title_block .filters a:hover svg path, .shop_title_block .filters a.active svg path {
  fill: #fff;
}
.shop_title_block .filters a.all-filters {
  border-color: #13262F;
  color: #13262F;
}
.shop_title_block .filters a.all-filters:hover {
  color: #fff;
}
.shop_title_block .filters a.all-filters:hover svg line {
  stroke: #fff;
}

.product_archive {
  padding: 0 0 60px;
}
.product_archive .product_grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 20px;
}
@media (max-width: 2000px) {
  .product_archive .product_grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media (max-width: 1600px) {
  .product_archive .product_grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1000px) {
  .product_archive .product_grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 700px) {
  .product_archive .product_grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.pagination {
  padding: 0 0 60px;
}
@media (max-width: 1200px) {
  .pagination {
    padding: 0 16px 40px;
  }
}
.pagination .inner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  padding: 30px 0 20px;
  border-top: 1px solid #E6E6E6;
}
.pagination .inner ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 6px;
}
.pagination .inner ul li:first-child {
  margin-right: 10px;
}
.pagination .inner ul li:last-child {
  margin-left: 10px;
}
.pagination .inner ul li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #969191;
  color: #0E0E0E;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
  font-weight: 600;
  padding: 0 25px;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
@media (max-width: 600px) {
  .pagination .inner ul li a {
    height: 30px;
    padding: 0 15px;
  }
}
.pagination .inner ul li a.active, .pagination .inner ul li a:hover {
  background-color: #0E0E0E;
  border-color: #0E0E0E;
  color: #fff;
}
.pagination .inner ul li a.active svg path, .pagination .inner ul li a:hover svg path {
  fill: #fff;
}
.pagination p {
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.03em;
  font-weight: 500;
  text-align: center;
  color: #969191;
  margin: 0;
}

.article_grid .article_list {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 40px;
}
@media (max-width: 2100px) {
  .article_grid .article_list {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (max-width: 1400px) {
  .article_grid .article_list {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1200px) {
  .article_grid .article_list {
    display: none;
  }
}
.article_grid .slider {
  display: none;
}
@media (max-width: 1200px) {
  .article_grid .slider {
    display: block;
  }
}
.article_grid .slider .swiper {
  overflow: visible;
}
@media (max-width: 1200px) {
  .article_grid.index_page .article_list {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
  }
}
@media (max-width: 600px) {
  .article_grid.index_page .article_list {
    grid-template-columns: 1fr !important;
    gap: 40px !important;
  }
}

.article_grid, .article_slider, .interview_slider {
  padding: 0 0 60px;
  width: 100%;
  overflow: hidden;
}
.article_grid .article_card .artilce_image, .article_slider .article_card .artilce_image, .interview_slider .article_card .artilce_image {
  width: 100%;
  padding-top: 65%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.article_grid .article_card .artilce_image img, .article_slider .article_card .artilce_image img, .interview_slider .article_card .artilce_image img {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  object-fit: cover;
}
.article_grid .article_card .article_text, .article_slider .article_card .article_text, .interview_slider .article_card .article_text {
  padding: 24px 0 0;
}
.article_grid .article_card .article_text p, .article_slider .article_card .article_text p, .interview_slider .article_card .article_text p {
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  margin: 0 0 15px;
  color: #A38C66;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
  text-transform: uppercase;
}
.article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
}
.article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
  font-size: calc(0.0018867925 * 100vw + 27.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
    font-size: 28px;
  }
}
.article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
  line-height: calc(0.0018867925 * 100vw + 31.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
    line-height: 36px;
  }
}
@media screen and (max-width: 380px) {
  .article_grid .article_card .article_text .article_title, .article_slider .article_card .article_text .article_title, .interview_slider .article_card .article_text .article_title {
    line-height: 32px;
  }
}

.interview_slider .article_card {
  position: relative;
}
.interview_slider .article_card::after {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  border-right: 1px solid #E6E6E6;
  top: 0;
  right: -15px;
}
@media (max-width: 1200px) {
  .interview_slider .article_card::after {
    display: none;
  }
}
.interview_slider .swiper-slider:last-child .article_card {
  position: relative;
}
.interview_slider .swiper-slider:last-child .article_card::after {
  display: none;
}

.article_category_Title {
  width: 100%;
  padding: 100px 0 40px;
}
@media (max-width: 1200px) {
  .article_category_Title {
    padding: 80px 0 40px;
  }
}
@media (max-width: 600px) {
  .article_category_Title {
    padding: 60px 0 40px;
  }
}
.article_category_Title .container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.article_category_Title .container h1 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0 auto 20px;
  text-align: center;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  max-width: 50%;
  font-weight: 700;
}
.article_category_Title .container h1 {
  font-size: calc(0.0066037736 * 100vw + 31.4905660377px);
}
@media screen and (min-width: 1919px) {
  .article_category_Title .container h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .article_category_Title .container h1 {
    font-size: 34px;
  }
}
.article_category_Title .container h1 {
  line-height: calc(0.0075471698 * 100vw + 33.1320754717px);
}
@media screen and (min-width: 1919px) {
  .article_category_Title .container h1 {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .article_category_Title .container h1 {
    line-height: 36px;
  }
}
@media (max-width: 1200px) {
  .article_category_Title .container h1 {
    max-width: 80%;
  }
}
@media (max-width: 800px) {
  .article_category_Title .container h1 {
    max-width: 90%;
  }
}
@media (max-width: 600px) {
  .article_category_Title .container h1 {
    max-width: 100%;
  }
}

.explore_videos {
  padding: 60px 0;
  background-color: #13262F;
}
@media (max-width: 1200px) {
  .explore_videos {
    padding: 20px 0 60px;
  }
}
.explore_videos .hometitleBar .home_section_title, .explore_videos .hometitleBar .home_viewAll {
  color: #fff;
}
.explore_videos .video_flex {
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 30px;
}
.explore_videos .video_flex .thumbnail_overlay {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #989897;
  mix-blend-mode: multiply;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.explore_videos .video_flex .featured_video {
  width: calc(100% - 390px);
  position: relative;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video {
    width: 100%;
    min-width: 100%;
  }
}
.explore_videos .video_flex .featured_video .video_thumbnail {
  position: relative;
  width: 100%;
  padding-top: 56%;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video .video_thumbnail {
    width: calc(100% + 32px);
    left: -16px;
  }
}
.explore_videos .video_flex .featured_video .video_thumbnail img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.explore_videos .video_flex .featured_video .video_text {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video .video_text {
    position: relative;
    height: auto;
    padding: 30px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column-reverse;
    border-bottom: 1px solid #E6E6E6;
  }
}
.explore_videos .video_flex .featured_video .video_text .video_title {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  text-align: center;
  color: #fff;
  margin: 0;
  max-width: 60%;
  cursor: pointer;
  font-family: "mrs-eaves", serif;
  font-style: normal;
}
.explore_videos .video_flex .featured_video .video_text .video_title {
  font-size: calc(0.0066037736 * 100vw + 31.4905660377px);
}
@media screen and (min-width: 1919px) {
  .explore_videos .video_flex .featured_video .video_text .video_title {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .explore_videos .video_flex .featured_video .video_text .video_title {
    font-size: 34px;
  }
}
.explore_videos .video_flex .featured_video .video_text .video_title {
  line-height: calc(0.0066037736 * 100vw + 35.4905660377px);
}
@media screen and (min-width: 1919px) {
  .explore_videos .video_flex .featured_video .video_text .video_title {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .explore_videos .video_flex .featured_video .video_text .video_title {
    line-height: 38px;
  }
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video .video_text .video_title {
    position: relative;
    max-width: 80%;
    top: auto;
    left: auto;
    transform: none;
  }
}
@media (max-width: 600px) {
  .explore_videos .video_flex .featured_video .video_text .video_title {
    width: 100%;
  }
}
.explore_videos .video_flex .featured_video .video_text .bottom {
  position: absolute;
  z-index: 4;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 0 30px;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video .video_text .bottom {
    position: relative;
    bottom: auto;
    left: auto;
    padding: 0;
  }
}
.explore_videos .video_flex .featured_video .video_text .bottom svg {
  cursor: pointer;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video .video_text .bottom svg {
    display: none;
  }
}
.explore_videos .video_flex .featured_video .video_text .bottom p {
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.08em;
  font-weight: 500;
  color: #fff;
  margin: 0;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .featured_video .video_text .bottom p {
    color: #A38C66;
    margin: 0 0 20px;
    text-transform: uppercase;
  }
  .explore_videos .video_flex .featured_video .video_text .bottom p .watch_now, .explore_videos .video_flex .featured_video .video_text .bottom p .seperator {
    display: none;
  }
}
.explore_videos .video_flex .video_list {
  width: 360px;
  height: auto;
  overflow-y: auto;
  padding-right: 15px;
}
@media (max-width: 1200px) {
  .explore_videos .video_flex .video_list {
    width: 100%;
    min-width: 100%;
    display: grid;
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.explore_videos .video_flex .video_list::-webkit-scrollbar {
  width: 6px;
}
.explore_videos .video_flex .video_list::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #2B3C44;
}
.explore_videos .video_flex .video_list::-webkit-scrollbar-thumb {
  background-color: #415057;
  outline: none;
}

.video_card {
  position: relative;
}
@media (max-width: 1200px) {
  .video_card {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 15px;
  }
}
.video_card .video_thumbnail {
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .video_card .video_thumbnail {
    width: 35%;
    min-width: 35%;
  }
}
.video_card .video_thumbnail .thumbnail_overlay, .video_card .video_thumbnail svg {
  opacity: 0;
}
.video_card .video_thumbnail .video_duration {
  position: absolute;
  z-index: 6;
  top: 10px;
  left: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  height: 25px;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 1;
  letter-spacing: 0.08em;
}
@media (max-width: 1200px) {
  .video_card .video_thumbnail .video_duration {
    display: none;
  }
}
.video_card .video_thumbnail svg {
  position: absolute;
  z-index: 5;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.video_card .video_thumbnail .image_wraper {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-top: 65%;
}
@media (max-width: 1200px) {
  .video_card .video_thumbnail .image_wraper {
    padding-top: 100%;
  }
}
.video_card .video_thumbnail img {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video_card .video_text {
  padding: 20px 0;
}
@media (max-width: 1200px) {
  .video_card .video_text {
    padding: 0;
  }
}
.video_card .video_text .video_duration {
  display: none;
  font-size: 12px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.08em;
  color: #A38C66;
  margin: 0;
  padding: 0 0 15px;
  text-transform: uppercase;
}
@media (max-width: 1200px) {
  .video_card .video_text .video_duration {
    display: block;
  }
}
.video_card .video_text .video_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  color: #fff;
  margin: 0 0 5px;
}
.video_card .video_text .video_title {
  font-size: calc(0.0056603774 * 100vw + 17.8490566038px);
}
@media screen and (min-width: 1919px) {
  .video_card .video_text .video_title {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .video_card .video_text .video_title {
    font-size: 20px;
  }
}
.video_card .video_text .video_title {
  line-height: calc(0.0056603774 * 100vw + 21.8490566038px);
}
@media screen and (min-width: 1919px) {
  .video_card .video_text .video_title {
    line-height: 36px;
  }
}
@media screen and (max-width: 380px) {
  .video_card .video_text .video_title {
    line-height: 24px;
  }
}
.video_card:hover .video_thumbnail .thumbnail_overlay, .video_card:hover .video_thumbnail svg {
  opacity: 1;
}
@media (max-width: 1200px) {
  .video_card:hover .video_thumbnail .thumbnail_overlay, .video_card:hover .video_thumbnail svg {
    display: none;
  }
}

.video_player {
  position: fixed;
  z-index: 9999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #1a1a1a;
  display: none;
}
.video_player.open {
  display: block;
}
.video_player .video_container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
}
.video_player .video_container video {
  width: 70%;
  height: auto;
  object-fit: contain;
  cursor: pointer;
}
.video_player .close-video-player {
  position: absolute;
  z-index: 10;
  top: 15px;
  right: 25px;
  transform: scale(0.8);
  opacity: 0.6;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.video_player .close-video-player:hover {
  transform: scale(1.2);
}
.video_player .controls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: rgba(255, 255, 255, 0.4);
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}
.video_player .controls .playPauseBtn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  cursor: pointer;
}
.video_player .controls .playPauseBtn .play-btn, .video_player .controls .playPauseBtn .pause-btn {
  width: 14px;
  height: auto;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.video_player .controls .playPauseBtn .pause-btn {
  width: auto;
  height: 16px;
  display: none;
}
.video_player .controls .playPauseBtn:hover svg {
  transform: scale(1.2);
}
.video_player .controls .volumeBtn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  cursor: pointer;
}
.video_player .controls .volumeBtn .mute-this, .video_player .controls .volumeBtn .unmute-this {
  width: 18px;
  height: auto;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.video_player .controls .volumeBtn .unmute-this {
  display: none;
}
.video_player .controls .volumeBtn:hover svg {
  transform: scale(1.2);
}
.video_player .controls .fullscreenBtn {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
.video_player .controls .fullscreenBtn .goFullscreen, .video_player .controls .fullscreenBtn .exitFullscreen {
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.video_player .controls .fullscreenBtn .exitFullscreen {
  display: none;
}
.video_player .controls .fullscreenBtn:hover svg {
  transform: scale(1.2);
}
.video_player .controls .details {
  width: 360px;
  padding: 0 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.video_player .controls .details p {
  color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
  font-size: 14px;
  line-height: 1;
}
.video_player .controls .seekbar {
  width: calc(100% - 480px);
  height: 60px;
  background-color: #555;
  position: relative;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.video_player .controls .seekbar:hover {
  background-color: rgb(104, 104, 104);
}
.video_player .controls .seekbar .seeker {
  width: 0%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
}

.article_hero {
  padding: 60px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #E6E6E6;
}
@media (max-width: 800px) {
  .article_hero {
    padding: 30px 0 0;
  }
}
@media (max-width: 600px) {
  .article_hero .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}
.article_hero .article_title {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0 auto 20px;
  text-align: center;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  max-width: 50%;
}
.article_hero .article_title {
  font-size: calc(0.0066037736 * 100vw + 31.4905660377px);
}
@media screen and (min-width: 1919px) {
  .article_hero .article_title {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .article_hero .article_title {
    font-size: 34px;
  }
}
.article_hero .article_title {
  line-height: calc(0.0075471698 * 100vw + 33.1320754717px);
}
@media screen and (min-width: 1919px) {
  .article_hero .article_title {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .article_hero .article_title {
    line-height: 36px;
  }
}
@media (max-width: 1200px) {
  .article_hero .article_title {
    max-width: 80%;
  }
}
@media (max-width: 800px) {
  .article_hero .article_title {
    max-width: 90%;
  }
}
@media (max-width: 600px) {
  .article_hero .article_title {
    max-width: 100%;
    order: 1;
  }
}
.article_hero .article_exerpt {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0 auto 20px;
  text-align: center;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  max-width: 50%;
  letter-spacing: -0.02em;
  color: #969191;
}
.article_hero .article_exerpt {
  font-size: calc(0.0009433962 * 100vw + 21.641509434px);
}
@media screen and (min-width: 1919px) {
  .article_hero .article_exerpt {
    font-size: 24px;
  }
}
@media screen and (max-width: 380px) {
  .article_hero .article_exerpt {
    font-size: 22px;
  }
}
.article_hero .article_exerpt {
  line-height: calc(0.0009433962 * 100vw + 29.641509434px);
}
@media screen and (min-width: 1919px) {
  .article_hero .article_exerpt {
    line-height: 32px;
  }
}
@media screen and (max-width: 380px) {
  .article_hero .article_exerpt {
    line-height: 30px;
  }
}
@media (max-width: 1200px) {
  .article_hero .article_exerpt {
    max-width: 80%;
  }
}
@media (max-width: 800px) {
  .article_hero .article_exerpt {
    max-width: 90%;
  }
}
@media (max-width: 600px) {
  .article_hero .article_exerpt {
    max-width: 100%;
    order: 3;
    padding: 20px 0 0;
  }
}
.article_hero .article_hero_image {
  display: block;
  margin: 0 auto;
  width: 96%;
}
@media (max-width: 800px) {
  .article_hero .article_hero_image {
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
  }
}
@media (max-width: 600px) {
  .article_hero .article_hero_image {
    order: 2;
  }
}
.article_hero .article_hero_image .image_wrapper {
  width: 100%;
  position: relative;
  padding-top: 45%;
  border-radius: 5px;
  overflow: hidden;
}
@media (max-width: 800px) {
  .article_hero .article_hero_image .image_wrapper {
    border-radius: 0;
  }
}
.article_hero .article_hero_image .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article_hero .articel_info {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 96%;
  padding: 15px 0;
}
@media (max-width: 800px) {
  .article_hero .articel_info {
    width: calc(100% + 32px);
    position: relative;
    left: -16px;
    padding: 15px 10px;
  }
}
@media (max-width: 600px) {
  .article_hero .articel_info {
    order: 4;
    border-top: 1px solid #E6E6E6;
  }
}
.article_hero .articel_info ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1100px) {
  .article_hero .articel_info ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
  }
}
.article_hero .articel_info ul li {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  min-width: 240px;
  padding: 0 20px;
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.03em;
  border-right: 1px solid #E6E6E6;
}
@media (max-width: 1100px) {
  .article_hero .articel_info ul li {
    min-width: inherit;
    padding: 0 10px;
  }
}
.article_hero .articel_info ul li:last-child {
  border: 0;
}
@media (max-width: 1100px) {
  .article_hero .articel_info ul li:nth-child(2) {
    border: 0;
  }
}
.article_hero .articel_info ul li span {
  font-weight: 600;
}
.article_hero .articel_info ul li span:last-child {
  font-weight: 400;
  font-size: 14px;
  color: #969191;
}

.article_internal_components {
  padding: 60px 0 45px;
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
  overflow: hidden;
}
.article_internal_components .article_internal {
  width: 100%;
  max-width: 900px;
  display: block;
  margin: 0 auto;
}
.article_internal_components .article_internal p {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
  padding: 0 0 45px;
  letter-spacing: -0.02em;
}
.article_internal_components .article_internal p {
  font-size: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal p {
    font-size: 24px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal p {
    font-size: 20px;
  }
}
.article_internal_components .article_internal p {
  line-height: calc(0.0018867925 * 100vw + 27.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal p {
    line-height: 32px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal p {
    line-height: 28px;
  }
}
.article_internal_components .article_internal h2 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
  padding: 0 0 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.article_internal_components .article_internal h2 {
  font-size: calc(0.004245283 * 100vw + 23.3867924528px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal h2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal h2 {
    font-size: 25px;
  }
}
.article_internal_components .article_internal h2 {
  line-height: calc(0.0037735849 * 100vw + 26.5660377358px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal h2 {
    line-height: 36px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal h2 {
    line-height: 28px;
  }
}
.article_internal_components .article_internal h3 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 25px;
  line-height: 1;
  padding: 0 0 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin: 0;
}
.article_internal_components .article_internal ul, .article_internal_components .article_internal ol {
  margin: 0;
  padding: 0 0 45px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.article_internal_components .article_internal ul li, .article_internal_components .article_internal ol li {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 23px;
  line-height: 1.25;
  padding-left: 30px;
}
.article_internal_components .article_internal ul li {
  position: relative;
}
.article_internal_components .article_internal ul li::before {
  content: "";
  display: inline-block;
  width: 7px;
  min-width: 7px;
  height: 7px;
  background-color: #A38C66;
  transform: rotate(45deg);
  position: absolute;
  z-index: 2;
  left: 3px;
  top: 12px;
}
.article_internal_components .article_internal ol {
  list-style-type: decimal;
  position: relative;
  left: 24px;
}
.article_internal_components .article_internal ol li {
  padding-left: 8px;
}
.article_internal_components .article_internal ol li::marker {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 23px;
  line-height: 1;
  font-weight: 600;
  color: #A38C66;
}
.article_internal_components .article_internal h2, .article_internal_components .article_internal h3, .article_internal_components .article_internal p, .article_internal_components .article_internal ul, .article_internal_components .article_internal ol {
  padding: 0 80px 45px;
}
@media (max-width: 600px) {
  .article_internal_components .article_internal h2, .article_internal_components .article_internal h3, .article_internal_components .article_internal p, .article_internal_components .article_internal ul, .article_internal_components .article_internal ol {
    padding: 0 0 35px;
  }
}
.article_internal_components .article_internal p:first-of-type::first-letter {
  line-height: 1;
}
.article_internal_components .article_internal p:first-of-type::first-letter {
  font-size: calc(0.0108490566 * 100vw + 37.8773584906px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal p:first-of-type::first-letter {
    font-size: 65px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal p:first-of-type::first-letter {
    font-size: 42px;
  }
}
.article_internal_components .article_internal .article_text_block:first-of-type p:first-of-type::first-letter {
  line-height: 1;
}
.article_internal_components .article_internal .article_text_block:first-of-type p:first-of-type::first-letter {
  font-size: calc(0.0108490566 * 100vw + 37.8773584906px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal .article_text_block:first-of-type p:first-of-type::first-letter {
    font-size: 65px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal .article_text_block:first-of-type p:first-of-type::first-letter {
    font-size: 42px;
  }
}
@media (max-width: 600px) {
  .article_internal_components .article_internal ul, .article_internal_components .article_internal ol {
    padding-right: 20px;
  }
}
.article_internal_components .article_internal ul p, .article_internal_components .article_internal ol p {
  padding: 0;
}
.article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
  letter-spacing: -0.02em;
}
.article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
  font-size: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
    font-size: 24px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
    font-size: 20px;
  }
}
.article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
  line-height: calc(0.0018867925 * 100vw + 27.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
    line-height: 32px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal ul p:first-of-type::first-letter, .article_internal_components .article_internal ol p:first-of-type::first-letter {
    line-height: 28px;
  }
}

.article_text_block, .ingredients_block {
  padding: 0;
}
.article_text_block p, .ingredients_block p {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
  padding: 0 0 45px;
  letter-spacing: -0.02em;
}
.article_text_block p, .ingredients_block p {
  font-size: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_text_block p, .ingredients_block p {
    font-size: 24px;
  }
}
@media screen and (max-width: 380px) {
  .article_text_block p, .ingredients_block p {
    font-size: 20px;
  }
}
.article_text_block p, .ingredients_block p {
  line-height: calc(0.0018867925 * 100vw + 27.2830188679px);
}
@media screen and (min-width: 1919px) {
  .article_text_block p, .ingredients_block p {
    line-height: 32px;
  }
}
@media screen and (max-width: 380px) {
  .article_text_block p, .ingredients_block p {
    line-height: 28px;
  }
}
.article_text_block h2, .ingredients_block h2 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
  padding: 0 0 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
}
.article_text_block h2, .ingredients_block h2 {
  font-size: calc(0.004245283 * 100vw + 23.3867924528px);
}
@media screen and (min-width: 1919px) {
  .article_text_block h2, .ingredients_block h2 {
    font-size: 34px;
  }
}
@media screen and (max-width: 380px) {
  .article_text_block h2, .ingredients_block h2 {
    font-size: 25px;
  }
}
.article_text_block h2, .ingredients_block h2 {
  line-height: calc(0.0037735849 * 100vw + 26.5660377358px);
}
@media screen and (min-width: 1919px) {
  .article_text_block h2, .ingredients_block h2 {
    line-height: 36px;
  }
}
@media screen and (max-width: 380px) {
  .article_text_block h2, .ingredients_block h2 {
    line-height: 28px;
  }
}
.article_text_block h3, .ingredients_block h3 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 25px;
  line-height: 1;
  padding: 0 0 20px;
  font-weight: 600;
  letter-spacing: -0.02em;
  margin: 0;
}
.article_text_block ul, .article_text_block ol, .ingredients_block ul, .ingredients_block ol {
  margin: 0;
  padding: 0 0 45px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
}
.article_text_block ul li, .article_text_block ol li, .ingredients_block ul li, .ingredients_block ol li {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 23px;
  line-height: 1.25;
  padding-left: 30px;
}
.article_text_block ul li, .ingredients_block ul li {
  position: relative;
}
.article_text_block ul li::before, .ingredients_block ul li::before {
  content: "";
  display: inline-block;
  width: 7px;
  min-width: 7px;
  height: 7px;
  background-color: #A38C66;
  transform: rotate(45deg);
  position: absolute;
  z-index: 2;
  left: 3px;
  top: 12px;
}
.article_text_block ol, .ingredients_block ol {
  list-style-type: decimal;
  position: relative;
  left: 24px;
}
.article_text_block ol li, .ingredients_block ol li {
  padding-left: 8px;
}
.article_text_block ol li::marker, .ingredients_block ol li::marker {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  font-size: 23px;
  line-height: 1;
  font-weight: 600;
  color: #A38C66;
}
.article_text_block p:last-child, .ingredients_block p:last-child {
  padding: 0;
}

.ingredients_block li.has_product_link {
  position: relative;
}
.ingredients_block li.has_product_link > div {
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.ingredients_block li.has_product_link > div > span {
  text-decoration: underline;
  text-underline-offset: 2px;
  text-decoration-color: #A38C66;
}
.ingredients_block li.has_product_link > div:hover .product_card_mini {
  pointer-events: all;
  opacity: 1;
}
.ingredients_block li.has_product_link .product_card_mini {
  position: absolute;
  z-index: 5;
  top: 98%;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.16);
  min-width: 320px;
  opacity: 0;
  pointer-events: none;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.article_quote_block h4 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
  padding: 0 0 40px;
  font-weight: 600;
  text-align: center;
}
.article_quote_block h4 {
  font-size: calc(0.0028301887 * 100vw + 30.9245283019px);
}
@media screen and (min-width: 1919px) {
  .article_quote_block h4 {
    font-size: 38px;
  }
}
@media screen and (max-width: 380px) {
  .article_quote_block h4 {
    font-size: 32px;
  }
}
.article_quote_block h4 {
  line-height: calc(0.0028301887 * 100vw + 32.9245283019px);
}
@media screen and (min-width: 1919px) {
  .article_quote_block h4 {
    line-height: 40px;
  }
}
@media screen and (max-width: 380px) {
  .article_quote_block h4 {
    line-height: 34px;
  }
}
@media (max-width: 600px) {
  .article_quote_block h4 {
    text-align: left;
  }
}

.article_internal_components .article_internal blockquote {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0;
  padding: 0 0 40px;
  font-weight: 600;
  text-align: center;
}
.article_internal_components .article_internal blockquote {
  font-size: calc(0.0028301887 * 100vw + 30.9245283019px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal blockquote {
    font-size: 38px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal blockquote {
    font-size: 32px;
  }
}
.article_internal_components .article_internal blockquote {
  line-height: calc(0.0028301887 * 100vw + 32.9245283019px);
}
@media screen and (min-width: 1919px) {
  .article_internal_components .article_internal blockquote {
    line-height: 40px;
  }
}
@media screen and (max-width: 380px) {
  .article_internal_components .article_internal blockquote {
    line-height: 34px;
  }
}
@media (max-width: 600px) {
  .article_internal_components .article_internal blockquote {
    text-align: left;
  }
}
.article_internal_components .article_internal blockquote p, .article_internal_components .article_internal blockquote h1, .article_internal_components .article_internal blockquote h2, .article_internal_components .article_internal blockquote h3, .article_internal_components .article_internal blockquote h4, .article_internal_components .article_internal blockquote h5, .article_internal_components .article_internal blockquote h6 {
  font: inherit;
  color: inherit;
  margin: 0;
  text-align: center;
}
.article_internal_components .article_internal blockquote p:first-of-type::first-letter {
  font: inherit;
  color: inherit;
}

.article_image_slider {
  padding: 0 0 45px;
}
.article_image_slider .swiper {
  overflow: visible;
}
.article_image_slider .swiper-slide .image_wrapper {
  width: 100%;
  padding-top: 65%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.article_image_slider .swiper-slide .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article_image_block {
  padding: 0 0 45px;
}
.article_image_block .image_content {
  width: 100%;
  position: relative;
}
.article_image_block .image_content.vertical {
  padding: 0 80px;
}
@media (max-width: 600px) {
  .article_image_block .image_content.vertical {
    padding: 0;
  }
}
.article_image_block .image_content.vertical .image_wrapper {
  padding-top: 124%;
}
.article_image_block .image_content.horizontal {
  padding: 0;
}
.article_image_block .image_content.horizontal .image_wrapper {
  padding-top: 65%;
}
.article_image_block .image_content .image_wrapper {
  width: 100%;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
}
.article_image_block .image_content .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.article_image_block .dual_image {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.article_image_block .dual_image .image_content {
  width: 100%;
}
.article_image_block .dual_image .image_content .image_wrapper {
  width: 100%;
  position: relative;
  padding-top: 65%;
  border-radius: 5px;
  overflow: hidden;
}
.article_image_block .dual_image .image_content .image_wrapper img {
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.article_products_block {
  padding: 0 0 45px;
}
.article_products_block .swiper {
  overflow: visible;
}
.article_products_block .swiper-slide .product_card_mini {
  border: 1px solid #E6E6E6;
}
.article_products_block .swiper-slide .product_card_mini .product_text {
  padding-right: 10px;
}

.login_forms .content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  width: 100%;
  padding: 60px 0;
  min-height: 60vh;
}
.login_forms .content .form_card {
  width: 100%;
  max-width: 420px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
}
.login_forms .content .form_card h2 {
  font-size: 26px;
  line-height: 1;
  font-weight: 600;
  margin: 0 0 24px;
  padding: 20px 20px 0;
}
.login_forms .content .form_card h4 {
  font-size: 14px;
  line-height: 1;
  padding: 0 20px;
  letter-spacing: 0.03em;
  color: #969191;
}
.login_forms .content .form_card form {
  padding: 0 20px 30px;
  border-bottom: 1px solid #E6E6E6;
}
.login_forms .content .form_card button[type=submit] {
  width: 100%;
  border: 0;
  background-color: #13262F;
  height: 44px;
}
.login_forms .content .form_card p {
  font-size: 14px;
  line-height: 1;
  letter-spacing: 0.03em;
  margin: 0;
  padding: 15px 20px;
}
.login_forms .content .form_card p.bg-off-white {
  background-color: #F7F7F7;
}
.login_forms .content .form_card p > a {
  color: #A38C66;
  text-decoration: underline;
}
.login_forms .content .form_card .sign_up {
  width: 100%;
  padding: 0 20px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_forms .content .form_card .sign_up a {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login_forms .content .form_card .sign_up button {
  width: 100%;
}
.login_forms .content .or {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 100%;
  max-width: 420px;
  padding: 24px 0;
}
.login_forms .content .or .line {
  width: auto;
  height: 1px;
  background-color: #E6E6E6;
  flex-grow: 1;
}
.login_forms .content .or span {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
}

.twoFa_container {
  margin: 100px auto;
  width: 100%;
  max-width: 420px;
  padding: 20px;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  background-color: #fff;
}
.twoFa_container input.hidden-input {
  display: none;
}
.twoFa_container h3 {
  text-align: center;
}
.twoFa_container label {
  text-align: center;
}
.twoFa_container .otp-container {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}
.twoFa_container .otp-container input {
  text-align: center;
}
.twoFa_container button.disabled {
  opacity: 0.6;
  background-color: #F7F7F7;
}

.auth-method-chooser {
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
}
.auth-method-chooser.open {
  display: flex;
}
.auth-method-chooser .auth-overlay {
  position: absolute;
  z-index: 10;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.6;
}
.auth-method-chooser .auth-method {
  position: relative;
  z-index: 12;
  width: 100%;
  max-width: 520px;
  background-color: #fff;
  padding: 20px;
  border-radius: 10px;
}
.auth-method-chooser .auth-method h3 {
  text-align: center;
}
.auth-method-chooser .auth-method input {
  display: none;
}
.auth-method-chooser .auth-method label {
  width: 100%;
  padding: 10px 10px;
  border-radius: 6px;
  border: 1px solid #e6e6e6;
  font-size: 14px;
  line-height: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  cursor: pointer;
}
.auth-method-chooser .auth-method label:hover {
  border-color: #000;
}
.auth-method-chooser .auth-method label svg {
  width: 25px;
  height: auto;
}
.auth-method-chooser .auth-method input:checked + label {
  border-color: #000;
  background-color: #f3f3f3;
}
.auth-method-chooser .auth-method button {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.auth-method-chooser .auth-method button.loading {
  pointer-events: none;
  opacity: 0.6;
}
.auth-method-chooser .close--popup {
  position: absolute;
  z-index: 15;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.auth-method-chooser .loading-state {
  position: absolute;
  z-index: 15;
  top: 17px;
  right: 17px;
  cursor: pointer;
  display: none;
  justify-content: center;
  align-items: center;
}
.auth-method-chooser .loading-state.loading {
  display: flex;
}
.auth-method-chooser .loading-state .loading {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid #ddd;
  border-top-color: #13262F;
  animation: loading 1s linear infinite;
}

@keyframes loading {
  to {
    transform: rotate(360deg);
  }
}
.user_account .account_inner {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;
  padding: 35px 0;
}
@media (max-width: 900px) {
  .user_account .account_inner {
    max-width: calc(100% - 40px);
  }
}
.user_account .breadcrumbs {
  padding: 0 0 30px;
}
.user_account .breadcrumbs ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
}
.user_account .breadcrumbs ul li {
  font-size: 18px;
  line-height: 1;
  position: relative;
  color: #0E0E0E;
}
.user_account .breadcrumbs ul li a {
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.user_account .breadcrumbs ul li a:hover {
  color: #969191;
}
.user_account .breadcrumbs ul li svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: -22px;
  transform: translateY(-50%);
}
@media (max-width: 900px) {
  .user_account .breadcrumbs {
    display: none;
  }
}
.user_account .breadcrumbs ul li:last-child {
  pointer-events: none;
  color: #A38C66;
}
.user_account .breadcrumbs ul li:last-child:hover {
  color: #A38C66;
}
.user_account .backToPage {
  padding: 0 0 30px;
  font-size: 18px;
  line-height: 1;
  position: relative;
  color: #0E0E0E;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
@media (max-width: 900px) {
  .user_account .backToPage {
    padding-left: 20px;
  }
}
.user_account h1 {
  line-height: 1;
  margin: 0 0 15px;
  font-weight: 500;
}
.user_account h1 {
  font-size: calc(0.0028301887 * 100vw + 22.9245283019px);
}
@media screen and (min-width: 1919px) {
  .user_account h1 {
    font-size: 30px;
  }
}
@media screen and (max-width: 380px) {
  .user_account h1 {
    font-size: 24px;
  }
}
@media (max-width: 900px) {
  .user_account h1 {
    padding: 0 16px;
  }
}
.user_account .account_content .content_tabs {
  background-color: #fff;
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border: 1px solid #E6E6E6;
  overflow: hidden;
}
@media (max-width: 900px) {
  .user_account .account_content .content_tabs {
    display: none;
  }
}
.user_account .account_content .content_tabs ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.user_account .account_content .content_tabs ul li {
  flex-grow: 1;
  height: 60px;
  border-right: 1px solid #E6E6E6;
}
.user_account .account_content .content_tabs ul li:last-child {
  border: 0;
}
.user_account .account_content .content_tabs ul li a {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  color: #1a1a1a;
}
.user_account .account_content .content_tabs ul li a.active_link, .user_account .account_content .content_tabs ul li a:hover {
  background-color: #EBE6DE;
}
.user_account .account_content .content_tabs ul li a.active_link {
  color: #000;
  font-weight: 600;
}
.user_account .account_content .mobile_tab_nav {
  display: none;
  width: 100%;
  max-width: 480px;
  position: relative;
  padding: 0 0 30px;
}
@media (max-width: 900px) {
  .user_account .account_content .mobile_tab_nav {
    display: block;
  }
}
.user_account .account_content .mobile_tab_nav .selected {
  width: 100%;
  height: 44px;
  border-radius: 3px;
  border: 1px solid #969191;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 18px;
}
.user_account .account_content .mobile_tab_nav .dropdown {
  display: none;
  padding: 15px 18px;
  border: 1px solid #e6e6e6;
  top: 48px;
  left: 16px;
  position: absolute;
  width: calc(100% - 32px);
  z-index: 5;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
}
.user_account .account_content .mobile_tab_nav .dropdown.open {
  display: block;
}
.user_account .account_content .mobile_tab_nav .dropdown ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
}
.user_account .account_content .mobile_tab_nav .dropdown ul li a {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.user_account .account_content .mobile_tab_nav .dropdown ul li a.active_link {
  color: #A38C66;
  pointer-events: none;
}
.user_account .account_content .tab_content {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #E6E6E6;
  position: relative;
  z-index: 3;
  top: -10px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
}
@media (max-width: 900px) {
  .user_account .account_content .tab_content {
    box-shadow: none;
    border-radius: 0;
    top: 0;
  }
}
.user_account .account_content .tab_content .tab_section {
  padding: 30px 20px 20px;
  border-bottom: 1px solid #E6E6E6;
  position: relative;
}
@media (max-width: 900px) {
  .user_account .account_content .tab_content .tab_section {
    border-bottom: 5px solid #E6E6E6;
  }
}
.user_account .account_content .tab_content .tab_section:last-child {
  padding-bottom: 30px;
  border-bottom: 0;
}
.user_account .account_content .tab_content .tab_section.hidden {
  display: none;
}
.user_account .account_content .tab_content .tab_section .button_row {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  padding-top: 26px;
}
.user_account .account_content .tab_content .tab_section .button_row button {
  height: 42px;
}
.user_account .account_content .tab_content h3 {
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  margin: 0 0 15px;
}
.user_account .account_content .tab_content p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.03em;
  margin: 0 0 15px;
  color: #969191;
}
.user_account .account_content .tab_content .default_value, .user_account .account_content .tab_content .start_process {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 900px) {
  .user_account .account_content .tab_content .default_value, .user_account .account_content .tab_content .start_process {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 10px;
  }
}
.user_account .account_content .tab_content .default_value p, .user_account .account_content .tab_content .start_process p {
  font-size: 18px;
  line-height: 1;
  letter-spacing: 0.01em;
  color: #0E0E0E;
  margin: 0;
}
.user_account .account_content .tab_content .default_value a, .user_account .account_content .tab_content .start_process a {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #A38C66;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.user_account .account_content .tab_content .start_process {
  justify-content: flex-end;
  position: absolute;
  bottom: 20px;
  right: 20px;
}
@media (max-width: 900px) {
  .user_account .account_content .tab_content .start_process {
    justify-content: flex-start;
    position: relative;
    bottom: auto;
    right: auto;
  }
}
.user_account .account_content .tab_content .start_process .logout {
  opacity: 0.8;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.user_account .account_content .tab_content .start_process .logout:hover {
  color: red;
  opacity: 1;
}
.user_account .account_content .tab_content .account_forms {
  padding: 30px 20px 20px;
}
.user_account .account_content .tab_content .account_forms h3 {
  padding-bottom: 45px;
}
.user_account .account_content .tab_content .account_forms form small {
  display: none;
}
.user_account .account_content .tab_content .account_forms.address_form form {
  display: block;
}
.user_account .account_content .tab_content .account_forms.address_form form .form_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 20px;
}
@media (max-width: 720px) {
  .user_account .account_content .tab_content .account_forms.address_form form .form_grid {
    grid-template-columns: 1fr;
    gap: 0;
  }
}
.user_account .account_content .tab_content .account_forms.address_form form .button_row {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
@media (max-width: 720px) {
  .user_account .account_content .tab_content .account_forms.address_form form .button_row {
    gap: 10px;
  }
}
@media (max-width: 720px) {
  .user_account .account_content .tab_content .account_forms.address_form form .button_row a {
    min-width: 100%;
  }
}
.user_account .account_content .tab_content .account_forms.address_form form button {
  min-width: 160px;
}
@media (max-width: 720px) {
  .user_account .account_content .tab_content .account_forms.address_form form button {
    min-width: 100%;
  }
}
.user_account .account_content .tab_content .account_forms.card_form button {
  appearance: none;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  background-color: #13262F;
  border: 1px solid #13262F;
  color: #fff;
  min-width: 160px;
  margin-top: 15px;
}
@media (max-width: 720px) {
  .user_account .account_content .tab_content .account_forms.card_form button {
    min-width: 100%;
  }
}
.user_account .account_content .tab_content .change_password_form_wrapper .form-group:last-child {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (max-width: 720px) {
  .user_account .account_content .tab_content .change_password_form_wrapper .form-group:last-child {
    grid-template-columns: 1fr;
  }
}
.user_account .account_content .tab_content form .form_row {
  width: 100%;
}
@media (max-width: 900px) {
  .user_account .account_content .tab_content form {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .user_account .account_content .tab_content form button {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.user_account .account_content .tab_content form button {
  height: 42px;
  padding: 0 20px;
}
.user_account .account_content .tab_content .form-text {
  display: block;
  padding-top: 10px;
}
.user_account .account_content .tab_content .form-text ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 6px;
}
.user_account .account_content .tab_content .form-text ul li {
  font-size: 12px;
  line-height: 1.25;
  opacity: 0.6;
}

ul.preferences {
  display: grid;
  grid-template-columns: 1fr;
  gap: 30px;
  padding: 30px 0;
  width: 100%;
}
@media (max-width: 600px) {
  ul.preferences {
    padding-top: 0;
  }
}
ul.preferences li {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.01em;
}
ul.preferences li input {
  display: none !important;
}
ul.preferences li label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 6px;
}
ul.preferences li input:checked + label svg .box {
  fill: #13262f;
}
ul.preferences li span {
  cursor: pointer;
  color: #222222;
}
ul.preferences li svg {
  cursor: pointer;
}
ul.preferences li svg .box {
  fill: #fff;
}

.button_right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 600px) {
  .button_right button {
    width: 100%;
  }
}

.single_order .order_info {
  background-color: #F7F7F7;
}
@media (max-width: 900px) {
  .single_order .order_info {
    background-color: #fff;
    padding: 20px 16px 0;
  }
}
.single_order .order_info ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 70px;
  padding: 0 20px;
}
@media (max-width: 900px) {
  .single_order .order_info ul {
    flex-direction: column;
    align-items: flex-start;
    height: auto;
    gap: 8px;
    padding: 0 0 20px;
    border-bottom: 1px solid #E6E6E6;
  }
}
.single_order .order_info ul li {
  min-width: 180px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}
@media (max-width: 900px) {
  .single_order .order_info ul li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.single_order .order_info ul li span {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #969191;
}
.single_order .order_info ul li span:last-child {
  color: #000;
  font-weight: 500;
}
.single_order .order_products {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  padding: 15px 20px;
}
@media (max-width: 900px) {
  .single_order .order_products {
    flex-wrap: wrap;
    padding: 15px 16px;
    gap: 5px;
    border-bottom: 5px solid #F7F7F7;
  }
}
.single_order .order_products .left {
  width: calc(100% - 185px);
}
@media (max-width: 900px) {
  .single_order .order_products .left {
    width: 100%;
  }
}
.single_order .order_products .left .product_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}
.single_order .order_products .left .product_list .product {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #F2F2F2;
}
.single_order .order_products .left .product_list .product a {
  display: block;
  width: 100%;
}
.single_order .order_products .left .product_list .product .product_image {
  width: 100%;
  height: 60px;
  position: relative;
}
.single_order .order_products .left .product_list .product .product_image img {
  position: absolute;
  z-index: 2;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 80%;
  height: 80%;
  object-fit: contain;
}
.single_order .order_products .left .product_list .more_products {
  width: 60px;
  height: 60px;
  border-radius: 5px;
  overflow: hidden;
  border: 1px solid #F2F2F2;
  background-color: #F2F2F2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
}
.single_order .order_products .left p {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #969191;
  padding: 24px 0 0;
}
.single_order .order_products .left p a {
  color: #A38C66;
  font-weight: 500;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.single_order .order_products .right {
  width: 155px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
@media (max-width: 900px) {
  .single_order .order_products .right {
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
  }
}
.single_order .order_products .right button {
  width: 100%;
}

.see_all {
  width: 100%;
  padding: 18px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #E6E6E6;
}
@media (max-width: 900px) {
  .see_all {
    border-color: #f7f7f7;
  }
}
.see_all a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.payment_wallet {
  padding: 30px 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 30px;
  border-bottom: 5px solid #F7F7F7;
}
.payment_wallet .left {
  width: calc(100% - 185px);
}
.payment_wallet .left p {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin: 0 0 15px;
  color: #000;
}
.payment_wallet .left h4 {
  font-size: 30px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
}
.payment_wallet .right {
  width: 155px;
}
.payment_wallet .right button {
  width: 100%;
}

.payment_section h3 {
  padding: 20px 20px;
}
.payment_section .saved_card {
  padding: 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  border-bottom: 1px solid #E6E6E6;
}
.payment_section .saved_card .card {
  width: calc(100% - 80px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
}
@media (max-width: 600px) {
  .payment_section .saved_card .card {
    width: 100%;
    min-width: 100%;
  }
}
.payment_section .saved_card .card .card_info {
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
.payment_section .saved_card .card .card_info .card_type {
  text-transform: capitalize;
}
.payment_section .saved_card .card .card_info span {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #969191;
}
.payment_section .saved_card .card .card_info span:last-child {
  color: #000;
  font-weight: 500;
}
.payment_section .saved_card .manage {
  min-width: 60px;
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
@media (max-width: 600px) {
  .payment_section .saved_card .manage {
    width: 100%;
    min-width: 100%;
  }
}
.payment_section .saved_card .manage a {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #A38C66;
  text-decoration: underline;
  text-underline-offset: 2px;
}
.payment_section .saved_card .manage a:first-child {
  min-width: 103px;
}
.payment_section .saved_card .manage span:first-child {
  min-width: 90px;
}

.add_new {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 25px 20px;
}
.add_new button {
  padding-left: 20px;
  padding-right: 20px;
}

.saved_addresses .single_address {
  padding: 20px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 20px;
  border-bottom: 1px solid #E6E6E6;
  width: 100%;
}
.saved_addresses .single_address .address {
  width: calc(100% - 180px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 5px;
}
@media (max-width: 600px) {
  .saved_addresses .single_address .address {
    width: 100%;
    min-width: 100%;
  }
}
.saved_addresses .single_address .address span {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.03em;
  color: #969191;
}
.saved_addresses .single_address .address span:last-child {
  color: #000;
  line-height: 1.25;
  font-weight: 500;
}
.saved_addresses .single_address .manage {
  width: 160px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  gap: 10px;
}
@media (max-width: 720px) {
  .saved_addresses .single_address .manage {
    width: 100%;
    min-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
  }
  .saved_addresses .single_address .manage span {
    position: relative;
    top: 4px;
  }
  .saved_addresses .single_address .manage .bottom {
    position: relative;
    top: 3px;
  }
}
.saved_addresses .single_address .manage a {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.01em;
  color: #A38C66;
  text-decoration: underline;
  text-underline-offset: 2px;
}

.user_pref {
  padding: 30px 20px;
}
.user_pref .button_row {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.user_pref .button_row button {
  min-width: 105px;
}
.user_pref .button_row button.red {
  background-color: #ee4135;
  border-color: #ee4135;
}

.order_cards {
  background-color: #fff;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  overflow: hidden;
  margin: 0 0 10px;
  box-shadow: 0px -1px 4px 0px rgba(0, 0, 0, 0.12);
}
@media (max-width: 900px) {
  .order_cards {
    width: calc(100% - 40px);
    margin: 0 auto 10px;
  }
}
.order_cards .top {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 15px;
  padding-bottom: 30px;
}
@media (max-width: 720px) {
  .order_cards .top {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.order_cards .top h1 {
  margin: 0;
  text-transform: capitalize;
}
@media (max-width: 900px) {
  .order_cards .top h1 {
    padding: 0 !important;
  }
}
.order_cards .top .giftStatus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.order_cards .top .giftStatus span {
  color: #e12c2c;
}
.order_cards h1 {
  font-size: 28px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: -0.01em;
  margin: 0 0 25px;
}
.order_cards h1.green {
  color: #108910;
}
.order_cards h3 {
  font-size: 22px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.03em;
  margin: 0 0 20px;
  color: #373737;
}
.order_cards > div {
  padding: 20px;
}
.order_cards > div > p {
  font-size: 14px;
  line-height: 1.35;
  font-weight: 500;
  margin: 0;
  color: #969191;
  letter-spacing: 0.03em;
}
.order_cards .order_details {
  background-color: #F7F7F7;
}
.order_cards .order_details .order_info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
@media (max-width: 720px) {
  .order_cards .order_details .order_info {
    flex-direction: column;
    gap: 15px;
  }
}
.order_cards .order_details .order_info .left {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
@media (max-width: 720px) {
  .order_cards .order_details .order_info .left {
    flex-direction: column;
    gap: 15px;
    width: 100%;
  }
}
.order_cards .order_details .order_info .right {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
@media (max-width: 720px) {
  .order_cards .order_details .order_info .right {
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
  }
}
.order_cards .order_details .order_info .right .column span {
  display: block;
  text-align: right;
}
@media (max-width: 720px) {
  .order_cards .order_details .order_info .right .column span {
    text-align: left;
  }
}
.order_cards .order_details .order_info .column {
  min-width: 150px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}
@media (max-width: 720px) {
  .order_cards .order_details .order_info .column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
}
.order_cards .order_details .order_info .column span {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.03em;
}
.order_cards .order_details .order_info .column span:first-child {
  color: #969191;
}
.order_cards .order_details .order_info .column span:last-child {
  color: #000;
  font-weight: 500;
}
.order_cards .product_list {
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  padding: 30px 20px 10px;
}
@media (max-width: 600px) {
  .order_cards .product_list {
    gap: 25px;
  }
}
.order_cards .product_list.unfullfilled {
  padding: 10px 20px 30px;
}
.order_cards .product_list .product {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 20px;
}
.order_cards .product_list .product .left {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
}
.order_cards .product_list .product .left .img {
  width: 80px;
  min-width: 80px;
  height: 80px;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
}
.order_cards .product_list .product .left .img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.order_cards .product_list .product .left .details .product_title {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 10px;
}
.order_cards .product_list .product .left .details .product_info {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  margin: 0 0 20px;
  color: #969191;
  letter-spacing: 0.03em;
  text-transform: capitalize;
}
.order_cards .product_list .product .left .details .product_cost {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.03em;
}
.order_cards .product_list .product .right .product_qty {
  font-size: 18px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.03em;
  width: 70px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #E6E6E6;
  border-radius: 5px;
}
@media (max-width: 600px) {
  .order_cards .product_list .product .right .product_qty {
    width: 45px;
  }
}
.order_cards .default {
  background-color: #FAFAFA;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 20px;
}
.order_cards .default h4 {
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.015em;
  margin: 0 0 12px;
}
.order_cards .default p {
  font-size: 14px;
  line-height: 1.35;
  letter-spacing: 0.03em;
  color: #585858;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  margin: 0;
}
.order_cards .order_summary ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 15px;
  border-top: 1px solid #F0F0F0;
  padding-top: 20px;
}
.order_cards .order_summary ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 0.04em;
}
.order_cards .order_summary ul li:last-child {
  border-top: 1px solid #F0F0F0;
  padding: 24px 0 0;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -0.02em;
}
.order_cards .manage_btns {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.order_cards .manage_btns button {
  width: 100%;
  min-width: 120px;
  max-width: 200px;
}

.wishlist_products {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
.wishlist_products .product_card_mini {
  border-bottom: 1px solid #F2F2F2;
  padding-bottom: 10px;
  width: 100%;
}
.wishlist_products .product_card_mini:last-child {
  border-bottom: 0;
}
.wishlist_products .product_card_mini .product_image {
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  overflow: hidden;
}
.wishlist_products .product_card_mini .product_text {
  flex-grow: 1;
  padding-right: 36px;
  padding-top: 5px;
  justify-content: flex-start;
}
.wishlist_products .product_card_mini .product_text .product_title {
  font-size: 18px;
}
.wishlist_products .product_card_mini .product_text .product_title {
  font-size: calc(0.0009433962 * 100vw + 15.641509434px);
}
@media screen and (min-width: 1919px) {
  .wishlist_products .product_card_mini .product_text .product_title {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .wishlist_products .product_card_mini .product_text .product_title {
    font-size: 16px;
  }
}
.wishlist_products .product_card_mini .remove_icon {
  width: 18px;
  height: auto;
  position: absolute;
  z-index: 10;
  top: 10px;
  right: 10px;
}

.confirm-plan-change {
  position: fixed;
  z-index: 102;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.confirm-plan-change.open {
  display: block;
}
.confirm-plan-change .overlay-plan-change {
  position: absolute;
  z-index: 103;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.6;
}
.confirm-plan-change .inner {
  position: absolute;
  z-index: 104;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  background-color: #fff;
  box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 520px;
  padding: 30px 18px;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
}
.confirm-plan-change .inner .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.confirm-plan-change .inner .top h3 {
  font-size: 24px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
}
.confirm-plan-change .inner .top svg {
  cursor: pointer;
}
.confirm-plan-change .inner .buttons_row {
  border-top: 1px solid #E6E6E6;
  padding: 15px 0 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}
.confirm-plan-change .inner .buttons_row button {
  text-transform: capitalize;
}

.checkout-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 40px 0;
}
.checkout-container > .inner {
  width: 100%;
  max-width: 1100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 40px;
}
@media (max-width: 1200px) {
  .checkout-container > .inner {
    padding: 0 15px;
  }
}
.checkout-container > .inner .user_details {
  width: 100%;
  max-width: calc(100% - 465px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
@media (max-width: 900px) {
  .checkout-container > .inner .user_details {
    max-width: 100%;
  }
}
.checkout-container > .inner .user_details .user_card {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 25px;
  width: 100%;
  overflow: hidden;
}
.checkout-container > .inner .user_details .user_card h3 {
  font-size: 20px;
  line-height: 1;
  font-weight: 700;
  color: #969191;
  margin: 0 0 15px;
}
.checkout-container > .inner .user_details .user_default .top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 6px;
}
.checkout-container > .inner .user_details .user_default .top h4 {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 0.03em;
  margin: 0;
}
.checkout-container > .inner .user_details .user_default .top button {
  background-color: transparent;
  border: 0;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: #A38C66;
  text-decoration: underline;
  text-underline-offset: 2px;
  height: auto;
  width: auto;
}
@media (max-width: 720px) {
  .checkout-container > .inner .user_details .user_default .top button {
    padding: 0;
    position: relative;
    right: -10px;
    top: -5px;
  }
}
.checkout-container > .inner .user_details .user_default p {
  font-size: 14px;
  line-height: 22px;
  text-transform: capitalize;
  letter-spacing: 0.03em;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.checkout-container > .inner .user_details .user_default p.dont-transform {
  text-transform: none;
}
.checkout-container > .inner .user_details .user_details_change, .checkout-container > .inner .user_details .user_details_change_form {
  display: none;
}
.checkout-container > .inner .user_details .user_details_change .form_grid, .checkout-container > .inner .user_details .user_details_change_form .form_grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 15px;
}
@media (max-width: 600px) {
  .checkout-container > .inner .user_details .user_details_change .form_grid, .checkout-container > .inner .user_details .user_details_change_form .form_grid {
    grid-template-columns: 1fr;
  }
}
.checkout-container > .inner .user_details .user_details_change .form_grid_3, .checkout-container > .inner .user_details .user_details_change_form .form_grid_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 15px;
}
@media (max-width: 600px) {
  .checkout-container > .inner .user_details .user_details_change .form_grid_3, .checkout-container > .inner .user_details .user_details_change_form .form_grid_3 {
    grid-template-columns: 1fr;
  }
}
.checkout-container > .inner .user_details .user_details_change .button_row, .checkout-container > .inner .user_details .user_details_change_form .button_row {
  padding: 10px 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 15px;
}
.checkout-container > .inner .user_details .user_details_change .button_row button, .checkout-container > .inner .user_details .user_details_change_form .button_row button {
  min-width: 105px;
}
.checkout-container > .inner .user_details .user_details_change #payment-form button, .checkout-container > .inner .user_details .user_details_change_form #payment-form button {
  appearance: none;
  height: 36px;
  border-radius: 4px;
  cursor: pointer;
  font-family: "proxima-nova", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.01em;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
  background-color: #13262F;
  border: 1px solid #13262F;
  color: #fff;
  margin-top: 15px;
  min-width: 105px;
}
.checkout-container > .inner .user_details .Addresses {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 25px;
  padding: 0 24px 24px;
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  max-height: 360px;
  overflow-y: auto;
}
.checkout-container > .inner .user_details .Addresses::-webkit-scrollbar {
  width: 5px;
}
.checkout-container > .inner .user_details .Addresses::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.checkout-container > .inner .user_details .Addresses::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.checkout-container > .inner .user_details .Addresses .address {
  display: block;
}
.checkout-container > .inner .user_details .Addresses .address input {
  display: none;
}
.checkout-container > .inner .user_details .Addresses .address input:checked + label .left .selector .circle .round {
  opacity: 1;
}
.checkout-container > .inner .user_details .Addresses .address label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 25px;
  border-radius: 5px;
  cursor: pointer;
}
.checkout-container > .inner .user_details .Addresses .address .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
  flex-grow: 1;
}
.checkout-container > .inner .user_details .Addresses .address .left .selector {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-container > .inner .user_details .Addresses .address .left .selector .circle {
  width: 24px;
  height: 24px;
  border: 2px solid #a38c66;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-container > .inner .user_details .Addresses .address .left .selector .circle .round {
  width: 12px;
  height: 12px;
  background-color: #a38c66;
  border-radius: 50%;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.checkout-container > .inner .user_details .Addresses .address .left .details {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.35;
  letter-spacing: 0.03em;
  display: grid;
  grid-template-columns: 1fr;
  gap: 8px;
}
.checkout-container > .inner .user_details .Addresses .address .left .details span:first-child {
  font-weight: 600;
}
.checkout-container > .inner .user_details .Addresses .address .left .details span:last-child {
  font-size: 14px;
  color: #0E0E0E;
  font-weight: 400;
}
.checkout-container > .inner .user_details .Addresses .address .right img {
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.checkout-container > .inner .user_details .Addresses .address .right button {
  background-color: transparent;
  border: 0;
  width: auto;
  height: auto;
  color: #A38C66;
  text-decoration: underline;
}
.checkout-container > .inner .user_details .add_new {
  position: relative;
  left: -24px;
  width: calc(100% + 48px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}
.checkout-container > .inner .user_details .add_new button {
  font-size: 16px;
  line-height: 1;
  font-weight: 600;
  background-color: transparent;
  border: 0;
  color: #a38c66;
  text-decoration: underline;
  text-underline-offset: 3px;
  width: auto;
  height: auto;
  padding: 0;
}
.checkout-container > .inner .order_summary {
  width: 100%;
  max-width: 425px;
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
}
@media (max-width: 900px) {
  .checkout-container > .inner .order_summary {
    max-width: 100%;
  }
}
.checkout-container > .inner .order_summary .product_list {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 25px;
}
.checkout-container > .inner .order_summary .product_list h3 {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  color: #969191;
  margin: 0 0 30px;
}
.checkout-container > .inner .order_summary .product_list .products {
  padding: 0 20px;
  max-height: 300px;
  overflow-y: auto;
}
.checkout-container > .inner .order_summary .product_list .products::-webkit-scrollbar {
  width: 6px;
}
.checkout-container > .inner .order_summary .product_list .products::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #F4F3EE;
}
.checkout-container > .inner .order_summary .product_list .products::-webkit-scrollbar-thumb {
  background-color: #EBE6DE;
  border-radius: 6px;
}
.checkout-container > .inner .order_summary .product_list .product {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0 0 30px;
}
.checkout-container > .inner .order_summary .product_list .product .products_image {
  width: 60px;
  height: 60px;
}
.checkout-container > .inner .order_summary .product_list .product .products_image .image_wrapper {
  width: 60px;
  height: 60px;
}
.checkout-container > .inner .order_summary .product_list .product .products_image .image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.checkout-container > .inner .order_summary .product_list .product .product_info {
  flex-grow: 1;
  padding-right: 40px;
}
.checkout-container > .inner .order_summary .product_list .product .product_info .product_title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 8px;
}
.checkout-container > .inner .order_summary .product_list .product .product_info .product_details {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #969191;
  margin: 0 0 12px;
}
.checkout-container > .inner .order_summary .product_list .product .product_info .product_cost {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.03em;
}
.checkout-container > .inner .order_summary .order_details {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 25px;
}
.checkout-container > .inner .order_summary .order_details ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding-bottom: 20px;
}
.checkout-container > .inner .order_summary .order_details ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  line-height: 1;
  letter-spacing: 0.03em;
}
.checkout-container > .inner .order_summary .order_details ul li:last-child {
  border-top: 1px solid #E6E6E6;
  padding-top: 20px;
  margin-top: 10px;
  font-weight: 18px;
  font-weight: 600;
}
.checkout-container > .inner .order_summary .order_details button {
  width: 100%;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.checkout-container > .inner .order_summary .order_details button.disabled {
  opacity: 0.2;
}
.checkout-container > .inner .order_summary .order_details p {
  font-size: 12px;
  line-height: 15px;
  color: #969191;
  margin: 0 0 18px;
  letter-spacing: 0.03em;
}
.checkout-container > .inner .order_summary .order_details p:last-child {
  margin: 0;
}
.checkout-container > .inner .saved_cards {
  max-height: 360px;
  overflow-y: auto;
}
.checkout-container > .inner .saved_cards::-webkit-scrollbar {
  width: 5px;
}
.checkout-container > .inner .saved_cards::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.checkout-container > .inner .saved_cards::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.checkout-container > .inner .saved_card {
  display: block;
  margin-bottom: 15px;
}
.checkout-container > .inner .saved_card input {
  display: none;
}
.checkout-container > .inner .saved_card label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  min-width: 100%;
  padding: 15px;
  cursor: pointer;
  border: 1px solid #E3E3E3;
  border-radius: 8px;
}
.checkout-container > .inner .saved_card input:checked + label .selector .circle .round {
  opacity: 1;
}
.checkout-container > .inner .saved_card .selector {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-container > .inner .saved_card .selector .circle {
  width: 24px;
  height: 24px;
  border: 2px solid #a38c66;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkout-container > .inner .saved_card .selector .circle .round {
  width: 12px;
  height: 12px;
  background-color: #a38c66;
  border-radius: 50%;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.checkout-container > .inner .saved_card .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.checkout-container > .inner .saved_card .left .card_details h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1;
  margin: 0 0 8px !important;
  text-transform: capitalize;
}
.checkout-container > .inner .saved_card .left .card_details p {
  font-size: 14px;
  line-height: 1;
  margin: 0 !important;
  color: #585858;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}
.checkout-container > .inner .saved_card .right {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

#delivery_slot {
  overflow: hidden;
}

.slot_days .day {
  width: 100%;
  max-width: 95px;
  height: 75px;
  border: 1px solid #F7F7F7;
  background-color: #F7F7F7;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}
.slot_days .day:hover {
  background-color: #F7F7F7;
}
.slot_days .day.active {
  background-color: #fff;
}
.slot_days .day span:first-child {
  font-size: 14px;
  font-weight: 400;
}

.slot_times {
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 15px 0;
}
.slot_times input {
  display: none;
}
.slot_times .time_slot {
  width: 100%;
  height: 50px;
  border: 1px solid #F7F7F7;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
}
.slot_times .time_slot label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 15px;
  background-color: #F7F7F7;
  border-radius: 8px;
  cursor: pointer;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  font-weight: 500;
}
.slot_times .time_slot label .left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}
.slot_times .time_slot label .selector {
  min-width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slot_times .time_slot label .selector .circle {
  width: 24px;
  height: 24px;
  border: 2px solid #a38c66;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slot_times .time_slot label .selector .circle .round {
  width: 12px;
  height: 12px;
  background-color: #a38c66;
  border-radius: 50%;
  opacity: 0;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.slot_times .time_slot input:checked + label {
  background-color: #fff;
}
.slot_times .time_slot input:checked + label .selector .circle .round {
  opacity: 1;
}
.slot_times .time_slot.unavailable {
  pointer-events: none;
  opacity: 0.5;
}
.slot_times .time_slot.unavailable .selector {
  display: none;
}

#payment_method .method {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 25px;
}
#payment_method .method:first-child {
  padding-top: 0;
}
#payment_method .method > h4 {
  font-size: 20px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 10px;
}
#payment_method .method > p {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  margin: 0 0 25px;
}
#payment_method .add_new {
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
}

.full-btn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.basket .basket_products {
  width: 100%;
  max-width: calc(100% - 465px);
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 25px;
  width: 100%;
  overflow: hidden;
}
.basket .basket_products h3 {
  font-size: 20px;
  line-height: 1;
  font-weight: 600;
  color: #969191;
  margin: 0 0 30px;
}
.basket .basket_products .product {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  padding: 0 0 30px;
}
.basket .basket_products .product .products_image {
  width: 60px;
  height: 60px;
}
.basket .basket_products .product .products_image .image_wrapper {
  width: 60px;
  height: 60px;
}
.basket .basket_products .product .products_image .image_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.basket .basket_products .product .product_info {
  flex-grow: 1;
  padding-right: 40px;
}
.basket .basket_products .product .product_info .product_title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  margin: 0 0 8px;
}
.basket .basket_products .product .product_info .product_details {
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  color: #969191;
  margin: 0 0 12px;
}
.basket .basket_products .product .product_info .product_cost {
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  margin: 0;
  letter-spacing: 0.03em;
}
.basket .basket_products .product .cart_action {
  width: 70px;
}
.basket .basket_products .product .cart_action .product_qty {
  width: 70px;
  height: 40px;
  border-radius: 5px;
  position: relative;
}
.basket .basket_products .product .cart_action .product_qty .right {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  display: grid;
  grid-template-columns: 1fr;
}
.basket .basket_products .product .cart_action .product_qty .right a {
  width: 24px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.privacy_header {
  background-color: #F7F7F7;
  padding: 90px 0 50px;
}
@media (max-width: 1200px) {
  .privacy_header {
    padding: 40px 0 20px;
  }
}
@media (max-width: 1200px) {
  .privacy_header .container {
    width: calc(100% - 200px);
  }
}
@media (max-width: 720px) {
  .privacy_header .container {
    width: calc(100% - 32px);
  }
}
.privacy_header .breadcrumbs {
  padding: 0 0 30px;
}
.privacy_header .breadcrumbs ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 36px;
}
.privacy_header .breadcrumbs ul li {
  font-size: 18px;
  line-height: 1;
  position: relative;
  color: #0E0E0E;
}
.privacy_header .breadcrumbs ul li a {
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}
.privacy_header .breadcrumbs ul li a:hover {
  color: #969191;
}
.privacy_header .breadcrumbs ul li svg {
  position: absolute;
  z-index: 2;
  top: 50%;
  right: -22px;
  transform: translateY(-50%);
}
.privacy_header h1 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0 0 15px;
}
.privacy_header h1 {
  font-size: calc(0.0094339623 * 100vw + 24.4150943396px);
}
@media screen and (min-width: 1919px) {
  .privacy_header h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_header h1 {
    font-size: 28px;
  }
}
.privacy_header h1 {
  line-height: calc(0.0094339623 * 100vw + 28.4150943396px);
}
@media screen and (min-width: 1919px) {
  .privacy_header h1 {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_header h1 {
    line-height: 32px;
  }
}
.privacy_header p {
  line-height: 1;
  margin: 0;
}
.privacy_header p {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .privacy_header p {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_header p {
    font-size: 14px;
  }
}

.privacy_page {
  padding: 60px 0;
  overflow: visible;
}
.privacy_page .page_inner {
  display: flex;
  justify-content: flex-start;
  gap: 200px;
}
@media (max-width: 1200px) {
  .privacy_page .page_inner {
    justify-content: center;
  }
}
.privacy_page .page_inner .text_content {
  width: calc(100% - 480px);
}
@media (max-width: 1200px) {
  .privacy_page .page_inner .text_content {
    width: calc(100% - 168px);
  }
}
@media (max-width: 720px) {
  .privacy_page .page_inner .text_content {
    width: 100%;
  }
}
.privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
  margin: 0 0 20px;
}
.privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
  font-size: calc(0.0047169811 * 100vw + 20.2075471698px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
    font-size: 22px;
  }
}
.privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
  line-height: calc(0.0037735849 * 100vw + 30.5660377358px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
    line-height: 40px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .text_content h2, .privacy_page .page_inner .text_content h3, .privacy_page .page_inner .text_content h4, .privacy_page .page_inner .text_content h5 {
    line-height: 32px;
  }
}
.privacy_page .page_inner .text_content p {
  margin: 0 0 30px;
}
.privacy_page .page_inner .text_content p {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .text_content p {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .text_content p {
    font-size: 14px;
  }
}
.privacy_page .page_inner .text_content p {
  line-height: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .text_content p {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .text_content p {
    line-height: 20px;
  }
}
.privacy_page .page_inner .text_content ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
}
.privacy_page .page_inner .text_content ul li {
  padding-left: 25px;
  position: relative;
}
.privacy_page .page_inner .text_content ul li {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .text_content ul li {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .text_content ul li {
    font-size: 14px;
  }
}
.privacy_page .page_inner .text_content ul li {
  line-height: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .text_content ul li {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .text_content ul li {
    line-height: 20px;
  }
}
.privacy_page .page_inner .text_content ul li::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
}
.privacy_page .page_inner .page_scroll_links {
  width: 480px;
  position: sticky;
  top: 160px;
  height: fit-content;
}
@media (max-width: 1200px) {
  .privacy_page .page_inner .page_scroll_links {
    display: none;
  }
}
.privacy_page .page_inner .page_scroll_links ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
  max-height: 300px;
  overflow-y: auto;
}
.privacy_page .page_inner .page_scroll_links ul::-webkit-scrollbar {
  width: 5px;
}
.privacy_page .page_inner .page_scroll_links ul::-webkit-scrollbar-track {
  box-shadow: none;
  background-color: #bdbdbd;
}
.privacy_page .page_inner .page_scroll_links ul::-webkit-scrollbar-thumb {
  background-color: #4e4e4e;
  border-radius: 3px;
}
.privacy_page .page_inner .page_scroll_links ul li {
  color: #006CCE;
  line-height: 1;
}
.privacy_page .page_inner .page_scroll_links ul li {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .page_scroll_links ul li {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .page_scroll_links ul li {
    font-size: 14px;
  }
}
.privacy_page .page_inner .page_scroll_links ul li {
  line-height: calc(0.0028301887 * 100vw + 16.9245283019px);
}
@media screen and (min-width: 1919px) {
  .privacy_page .page_inner .page_scroll_links ul li {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .privacy_page .page_inner .page_scroll_links ul li {
    line-height: 18px;
  }
}

#cms-top ~ .privacy_page .page_inner .page_scroll_links {
  top: 190px;
}

.static_header {
  padding: 60px 0 45px;
}
@media (max-width: 1200px) {
  .static_header {
    padding: 40px 0;
  }
}
.static_header .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.static_header .inner {
  width: 100%;
  max-width: 700px;
}
.static_header h1 {
  font-family: "mrs-eaves", serif;
  font-style: normal;
  margin: 0 0 15px;
}
.static_header h1 {
  font-size: calc(0.0094339623 * 100vw + 24.4150943396px);
}
@media screen and (min-width: 1919px) {
  .static_header h1 {
    font-size: 48px;
  }
}
@media screen and (max-width: 380px) {
  .static_header h1 {
    font-size: 28px;
  }
}
.static_header h1 {
  line-height: calc(0.0094339623 * 100vw + 28.4150943396px);
}
@media screen and (min-width: 1919px) {
  .static_header h1 {
    line-height: 52px;
  }
}
@media screen and (max-width: 380px) {
  .static_header h1 {
    line-height: 32px;
  }
}
.static_header p {
  line-height: 1;
  margin: 0 0 35px;
}
.static_header p {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .static_header p {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .static_header p {
    font-size: 14px;
  }
}
.static_header .static_hero_img {
  width: 100%;
}
.static_header .static_hero_img .img_wrapper {
  width: 100%;
}
.static_header .static_hero_img .img_wrapper img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.static_content {
  padding: 0 0 45px;
}
.static_content .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.static_content .inner {
  width: 100%;
  max-width: 700px;
}
.static_content h2, .static_content h3, .static_content h4, .static_content h5 {
  margin: 0 0 20px;
}
.static_content h2, .static_content h3, .static_content h4, .static_content h5 {
  font-size: calc(0.0047169811 * 100vw + 20.2075471698px);
}
@media screen and (min-width: 1919px) {
  .static_content h2, .static_content h3, .static_content h4, .static_content h5 {
    font-size: 32px;
  }
}
@media screen and (max-width: 380px) {
  .static_content h2, .static_content h3, .static_content h4, .static_content h5 {
    font-size: 22px;
  }
}
.static_content h2, .static_content h3, .static_content h4, .static_content h5 {
  line-height: calc(0.0037735849 * 100vw + 30.5660377358px);
}
@media screen and (min-width: 1919px) {
  .static_content h2, .static_content h3, .static_content h4, .static_content h5 {
    line-height: 40px;
  }
}
@media screen and (max-width: 380px) {
  .static_content h2, .static_content h3, .static_content h4, .static_content h5 {
    line-height: 32px;
  }
}
.static_content p {
  margin: 0 0 30px;
}
.static_content p {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .static_content p {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .static_content p {
    font-size: 14px;
  }
}
.static_content p {
  line-height: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .static_content p {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .static_content p {
    line-height: 20px;
  }
}
.static_content ul {
  display: grid;
  grid-template-columns: 1fr;
  gap: 25px;
  margin: 0 0 30px;
}
.static_content ul li {
  padding-left: 25px;
  position: relative;
}
.static_content ul li {
  font-size: calc(0.0018867925 * 100vw + 13.2830188679px);
}
@media screen and (min-width: 1919px) {
  .static_content ul li {
    font-size: 18px;
  }
}
@media screen and (max-width: 380px) {
  .static_content ul li {
    font-size: 14px;
  }
}
.static_content ul li {
  line-height: calc(0.0018867925 * 100vw + 19.2830188679px);
}
@media screen and (min-width: 1919px) {
  .static_content ul li {
    line-height: 24px;
  }
}
@media screen and (max-width: 380px) {
  .static_content ul li {
    line-height: 20px;
  }
}
.static_content ul li::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: 8px;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #000;
}