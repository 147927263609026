.toast-container{
    position: fixed;
    z-index: 99999;
    top: 135px;
    right: 15px;
    .toast{
        position: relative;
        padding: 10px 0;
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        box-shadow: 0 3px 6px 0 rgba(0,0,0,.1);
        width: 100%;
        max-width: 320px;
        min-width: 320px;
        height: 80px;

        .toast-content{
            width: calc(100% - 50px);
            padding: 0 20px;

            h4{
                font-size: 16px;
                line-height: 1.35;
                font-weight: 500;
                margin: 0 0 8px;
                color: #1a1a1a;
                text-transform: capitalize;
            }
            p{
                font-size: 13px;
                line-height: 1;
                font-weight: 400;
                margin: 0;
                color: #1a1a1a;
                opacity: 0.8;
            }
        }

        
        .toast-close{
            width: 50px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            border-left: 1px solid #f2f2f2;
        }
    }
}

#cms-top ~ .toast-container{
    top: 175px;
}