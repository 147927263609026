.productFilters{
    position: fixed;
    z-index: 101;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.open{
        display: block;
    }
    
    .productFiltersOverlay{
        position: absolute;
        z-index: 102;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
        cursor: pointer;
    }
    .filterList{
        position: absolute;
        z-index: 103;
        top: 0;
        left: 0;
        height: 100dvh;
        width: 436px;
        background-color: #fff;
        transform: translateX(-250%);
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        @media (max-width: 580px){
            width: 100%;
        }

        &.open{
            transform: none;
        }

        .topbar{
            padding: 30px 25px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3{
                font-size: 26px;
                font-weight: 500;
                line-height: 1;
                @include letter-spacing(10);
                margin: 0;
            }
        }
        .filterContent{
            padding: 0 25px 20px;
            width: 100%;
            height: auto;
            max-height: calc(100vh - 163px);
            .inner{
                width: 100%;
                height: auto;
                max-height: calc(100vh - 163px);
                overflow-y: auto;
                @include scroll_style;
                &::-webkit-scrollbar-track {
                    background-color: #F4F3EE;
                }
            
                &::-webkit-scrollbar-thumb {
                    background-color: #EBE6DE;
                }
            }
        }
        .bottom{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;
            width: 100%;
            position: absolute;
            z-index: 10;
            left: 0;
            bottom: env(safe-area-inset-bottom, 0);
            background-color: #fff;
            padding: 20px 25px;
            border-top: 1px solid #E6E6E6;

            button{
                width: 100%;
            }
        }
    }
}
.filterAccordion {
    width: 100%;
    margin: 0 auto;
    .accordion-item {
        margin-bottom: 10px;
        border-radius: 4px;
        overflow: hidden;
        .accordion-header {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 0;
            border-bottom: 1px solid #E6E6E6;
            margin-right: 10px;

            h3{
                font-size: 20px;
                font-weight: 500;
                line-height: 1;
                margin: 0;
                @include letter-spacing(10);
            }
          }
          .accordion-content {
            display: none;
            padding: 20px 0;
            margin-right: 10px;

            ul{
                display: grid;
                grid-template-columns: 1fr;
                gap: 30px;
                li{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    font-size: 18px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(10);

                    span{                        
                        cursor: pointer;
                        color: #222222;
                    }

                    svg{
                        cursor: pointer;
                       .box{
                        fill: #fff;
                       } 
                    }
                    &:nth-child(n+7){
                        display: none;
                    }
                    &.active{
                        svg{
                           .box{
                            fill: #13262f;
                           } 
                        }
                    }

                    &.readMoreFilters{
                        span{                            
                            color: #A38C66;
                        }
                    }
                }
            }
          }
          .arrow {
            width: 12px;
            height: auto;
          }
          .rotate {
            transform: rotateZ(180deg);
          }          
      }
  }