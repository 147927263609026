.product_description{
    padding: 60px 0 0;
    @media (max-width: 1200px){
        padding: 30px 0 0;
    }
    p{
        font-size: 14px;
        line-height: 1;
        @include letter-spacing(100);
        font-weight: 500;
        color: #A38C66;
        text-align: center;
        text-transform: uppercase;
        margin: 0 0 35px;
        @media (max-width: 1200px){
            margin: 0 0 20px;
        }
    }
    h3{
        @include font-secondary;
        text-align: center;
        margin: 0 auto 60px;
        max-width: 70%;
        @include vs-spacing(font-size, 48px, 26px);
        @include vs-spacing(line-height, 50px, 28px);

        @media (max-width: 720px){
            max-width: calc(100% - 40px);
            margin: 0 0 30px;
        }
    }
}