.article_image_slider{
    padding: 0 0 45px;
    .swiper{
        overflow: visible;
    }
    .swiper-slide{
        .image_wrapper{
            width: 100%;
            padding-top: 65%;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}