*{
    color: #000;
    box-sizing: border-box;
    @include font-primary;
    font-weight: 400;
}

body{
    margin: 0 !important;
    width: 100%;
    padding-top: 120px;
    background-color: #fff;
    @media (max-width: 720px){
        padding-top: 165px;
    }

    &.locked{
        overflow: hidden !important;
    }
    &.bg-grey{
        background-color: #F7F7F7;
    }
    @include scroll_style;
}


h1, h2, h3, h4, h5, h6{
    font-weight: 400;
}

img{
    display: inline-block;
}

a{
    color: inherit;
    font: inherit;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &:hover, &:focus, &:visited{
        text-decoration: none;
        color: inherit;
    }
}

span{
    color: inherit;
    font: inherit;
}

ul, ol{
    margin: 0;
    padding: 0;
    list-style: none;
}

input, select, textarea{
    appearance: none;
    width: 100%;
    display: block;
    border: 1px solid #E6E6E6;
    background-color: #fff;
    height: 42px;
    border-radius: 5px;
    box-sizing: border-box;
    padding: 0 12px;
    font-size: 16px;
    line-height: 1;
    @include letter-spacing(10);

    &:focus, &:hover{
        outline: none;
        box-shadow: none;
    }
}
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input:-webkit-autofill, input:-webkit-autofill:focus {
    background-color: #fff !important;
    color: #000 !important;
}

/* Hide the spinners in Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
textarea{
    padding: 10px;
    width: 100%;
    resize: vertical;
    height: 80px;
    min-height: 60px;
    max-height: 140px;
}
select{
    position: relative;
}
button{
    appearance: none;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
    @include font-primary;
    font-size: 16px;
    line-height: 1;
    font-weight: 500;
    @include letter-spacing(10);
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    -webkit-tap-highlight-color: transparent;
    user-select: none;

    &.dark{
        background-color: #13262F;
        border: 1px solid #13262F;
        color: #fff;
    }
    &.light{
        background-color: #fff;
        border: 1px solid #E6E6E6;
        color: #000;
    }
    &.disabled{
        opacity: 0.5;
        pointer-events: none;
    }
}
label{
    font-size: 16px;
    line-height: 1;
    @include letter-spacing(10);
}
.form-group{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    padding: 0 0 20px;
}
.form_row{
    display: grid;
    gap: 15px;

    &.two_columns{        
        grid-template-columns: repeat(2, 1fr);
        @media (max-width: 900px){
            grid-template-columns: 1fr;
            row-gap: 0;
        }
    }
}
.select_bx{
    position: relative;
    width: 100%;

    select{
        padding-right: 35px;
    }

    svg{
        position: absolute;
        z-index: 2;
        top: 50%;
        right: 12px;
        transform: translateY(-50%);
    }
}
.type_holder{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    input{
        display: none;
    }
    .address_type{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        border: 1px solid #e6e6e6;
        transition: $transition;
        border-radius: 4px;

        label{
            width: 100%;
            height: 42px;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
    }
    input:checked + label{
        background-color: #13262F;
        color: #fff;
    }

}
section{
    width: 100%;
    overflow-x: hidden;
}
.error-message{
    font-size: 14px;
    line-height: 1.25;
    font-weight: 400;
    color: red;
}
.overlay{
    background-color: #000;
    opacity: 0.6;
    position: fixed;
    z-index: 60;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.container{
    width: 80%;
    margin: 0 auto;
    display: block;
    transition: $transition;

    @media (max-width: 1600px){
        width: 90%;
    }
    @media (max-width: 1200px){
        width: calc(100% - 32px);
    }
}

.cms-messages{
    display: none !important;
}

.select2{
    .selection{
        .select2-selection{
            border: 1px solid #e6e6e6;
            height: 42px;

            .select2-selection__rendered, .select2-selection__arrow{
                height: 42px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
            }
        }
    }
}

.select2-container{
    border-radius: 5px;       
    width: 100% !important;
    @media (max-width: 900px){ 
    }
    .select2-dropdown{
        border: 1px solid #e6e6e6;

        .select2-search__field{
            border: 1px solid #e6e6e6;
        }
    }
}
.select2-results__options{
    @include scroll_style;
}

.iti {
    button{
        padding: 0 !important;
        min-width: auto !important;
    }
}
.iti--inline-dropdown {
    .iti__country-list{
        @include scroll_style;
    }
}