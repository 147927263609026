.product_hero{
    padding: 30px 0;
    @media(max-width: 600px){
        padding: 10px 0 30px;
    }
    .inner{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;

        @include breadcumb;

        .breadcrumbs{
            position: absolute;
            z-index: 5;
            top: 0;
            left: 0;
            padding: 0;
        }

        .send-gift{
            height: 44px;
            background-color: #F4F3EE;
            border-radius: 3px;
            color: #E12C2C;
            border: 0;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            gap: 12px;
            padding: 0 20px;
            position: absolute;
            z-index: 10;
            top: 40px;
            left: 0;
            transition: $transition;
            @media(max-width: 600px){
                top: 30px;
                height: 36px;
                padding: 0 12px;
            }

            svg{
                width: 18px;
                height: auto;
            }

            &:hover{
                background-color: #E12C2C;
                color: #fff;

                svg{
                    path{
                        fill: #fff;
                    }
                }
            }
        }

        > .left{
            width: 60%;
            position: relative;
            transition: $transition;
            @media (max-width: 1200px){
                width: 60%;
            }
            @media (max-width: 800px){
                width: 100%;
            }

            .product_images{
                @media (max-width: 1000px){                    
                    width: 100%;
                    overflow: hidden;

                    .swiper-button-next-custom, .swiper-button-prev-custom{
                        display: none;
                    }
                }

            }

            .slider{
               .image_wrapper{
                    width: 100%;
                    padding-top: 100%;
                    position: relative;

                    img{
                        position: absolute;
                        z-index: 2;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        width: 85%;
                        height: 85%;
                        object-fit: contain;
                    }
               } 
            }
        }
        > .right{
            width: 30%;
            transition: $transition;
            @media (max-width: 1200px){
                width: 40%;
            }
            @media (max-width: 800px){
                width: 100%;
            }

            .product_details{
                display: grid;
                grid-template-columns: 1fr;
                gap: 10px;

                .details_card{
                    background-color: #F7F7F7;
                    border-radius: 5px;
                    overflow: hidden;
                    padding: 24px 20px;

                    &.no_padd{
                        padding: 0;
                    }
                }
                .country_of_origin{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(100);
                    color: #A38C66;
                    text-transform: uppercase;
                    margin: 0 0 25px;
                }
                .product_title{
                    line-height: 32px;
                    font-weight: 600;
                    margin: 0 0 20px;
                    color: #1A1A1A;
                    @include vs-spacing(font-size, 30px, 24px);
                }
                .product_qty{
                    display: none;
                }
                .product_price{
                    line-height: 1;
                    font-weight: 600;
                    margin: 0;
                    color: #1A1A1A;
                    @include vs-spacing(font-size, 18px, 16px);
                }

                .section_title{
                    font-size: 22px;
                    line-height: 1;
                    font-weight: 500;
                    margin: 0 0 20px;
                    color: #1A1A1A;
                }
                .top{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                .product_tags{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 6px;
                    padding: 20px 0 0;

                    .tag{
                        padding: 0 10px;
                        border-radius: 3px;
                        display: inline-flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 16px;
                        line-height: 1;
                        @include letter-spacing(40);
                        height: 36px;

                        &.filled{
                            background-color: #969191;
                            color: #fff;
                            border: 1px solid #969191;
                        }
                        &.outlined{
                            background-color: #fff;
                            color: #969191;
                            border: 1px dashed #969191;
                        }
                    }
                }
                .product_sizes{
                    display: flex;
                    justify-content: center;
                    align-content: center;
                    flex-wrap: wrap;
                    gap: 6px;

                    input{
                        display: none;
                    }
                    .size{
                        flex-grow: 1;

                        label{     
                            width: 100%;                       
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            height: 44px;
                            border: 1px solid #969191;
                            font-size: 16px;
                            line-height: 1;
                            @include letter-spacing(10);
                            color: #969191;
                            border-radius: 3px;
                            cursor: pointer;
                            transition: $transition;
                            text-transform: uppercase;
                        }
                        input:checked+ label{
                            background-color: #A38C66;
                            color: #fff;
                            border-color: #A38C66;
                        }
                    }
                }
                .cart_action{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 0 30px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #E6E6E6;

                    .product_qty{
                        width: 100px;
                        height: 44px;
                        background-color: #E6E6E6;
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        border-radius: 5px; 
                        padding: 3px;

                        input{
                           width: 70px;
                           height: 36px;
                           border-radius: 5px;
                           padding: 0 10px;
                        }
                        .right{
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            flex-wrap: wrap;
                            flex-direction: column;
                            width: 30px;
                        }
                    }
                    .add-to-cart{
                        height: 44px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: 10px;
                        flex-grow: 1;
                        width: calc(100% - 110px);
                        border: 0;
                        background-color: #13262F;
                        color: #fff;
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        @include letter-spacing(10);
                    }
                }
                .save_product{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(10);
                    border: 0;
                    height: 44px;
                    background-color: #F4F3EE;
                    width: 100%;
                    
                    &.saved{
                        svg{
                            .fill{
                                fill: #000000;
                            }
                        }
                    }
                }
                .subscription_mode{
                    display: grid;
                    grid-template-columns: 1fr;

                    .subscription_option{
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        cursor: pointer;

                        input{
                            display: none;
                        }

                        label{
                            width: 100%;
                            display: flex;
                            justify-content: flex-start;
                            cursor: pointer;
                            padding: 20px;
                        }

                        input:checked + label{
                            background-color: #EFECE8;

                            .left{
                                .checkbox{
                                    svg{
                                        #Rectangle_8973{
                                            fill: #A38C66;
                                            stroke: #A38C66;
                                        }
                                        path{
                                            fill: #fff;
                                        }
                                    }
                                }
                            }
                        }

                        .left{
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            gap: 10px;
                            flex-grow: 1;

                            .text{
                                h4{
                                    font-size: 18px;
                                    line-height: 1;
                                    font-weight: 600;
                                    @include letter-spacing(30);
                                    margin: 0 0 10px;
                                }
                                p{
                                  font-size: 14px;
                                  line-height: 1;
                                  font-weight: 400;
                                  @include letter-spacing(30);
                                  color: #969191;
                                  margin: 0; 
                                  text-transform: capitalize; 
                                }
                                .select2-container{
                                    margin: 20px 0 0;
                                }
                                select{
                                    margin: 20px 0 0;
                                    padding: 0 10px;
                                    border-color: #969191;
                                    color: #969191;
                                }
                            }
                        }
                        .right{
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            align-items: flex-end;
                            width: 100%;
                            max-width: 130px;

                            p{
                                font-size: 18px;
                                line-height: 1;
                                @include letter-spacing(30);
                                font-weight: 600;
                                margin: 0;
                                display: flex;
                                flex-direction: column;
                                justify-content: flex-end;
                                align-items: flex-end;
                                gap: 5px;

                                span{
                                    &.old_price{
                                        font-size: 14px;
                                        color: #969191;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}