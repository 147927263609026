.cart_notification{
    position: fixed;
    z-index: 99;
    top: 80px;
    right: 30px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid #E6E6E6;
    box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.16);
    padding: 10px;
    min-width: 240px;
    display: none;

    &.open{
        display: block;
    }

    button{
        width: 100%;
    }
    p{
        font-size: 12px;
        line-height: 1;
        @include letter-spacing(30);
        margin: 15px 0 10px;

        a{
            color: #A38C66;
            text-decoration: underline;
        }
    }
}