.product_reviews{
    padding: 0 0 60px;
    width: 100%;
    overflow: hidden;
    .review_inner{
       display: flex;
       justify-content: flex-start;
       align-items: flex-start;
       flex-wrap: wrap;
       gap: 20px;
       
       .review_info{
            width: 330px;
            background-color: #F4F3EE;
            border-radius: 10px;
            border: 1px solid #E6E6E6;
            @media(max-width: 1000px){
                width: 100%;
            }
            .top{                
                padding: 15px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;

                .score{
                    font-size: 60px;
                    line-height: 1;
                    font-weight: 600;
                    @include letter-spacing(30);
                    color: #A38C66;
                }
                .score_info{
                    .review_totals{
                        padding: 5px 0 0;
                        font-size: 14px;
                        line-height: 1;
                        font-weight: 500;
                        @include letter-spacing(30);
                        color: #969191;

                        a{
                            font-weight: 500;
                            color: #A38C66;
                            text-decoration: underline;
                        }
                    }
                }
            }
            .bottom{
                padding: 15px;
                border-top: 1px solid #E6E6E6;

                button{
                    border: 0;
                    background-color: #13262F;
                    color: #fff;
                    width: 100%;
                }
            }
       }
       .user_reviews{
            width: calc(100% - 350px);

            @media(max-width: 1000px){
                width: 100%;
            }

            .swiper{

            }

            .swiper-slide{
                border: 1px solid #E6E6E6;
                border-radius: 10px;
                overflow: hidden;
                padding: 20px 15px;

                .user_info{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 15px;

                    .user_pic{
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        overflow: hidden;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                    .user_details{
                        .user_name{
                            font-size: 18px;
                            line-height: 1;
                            @include letter-spacing(30);
                            margin: 0 0 10px;
                        }
                        .user_location{
                            font-size: 14px;
                            line-height: 1;
                            @include letter-spacing(30);
                            margin: 0;
                        }
                    }
                }
                .user_stars{
                    padding: 15px 0 7px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    .stars{
                        svg{
                            width: 10px;
                            height: auto;
                        }
                    }
                    .date_published{
                        font-size: 14px;
                        line-height: 1;
                    }
                }
                .review_comment{
                    font-size: 16px;
                    line-height: 22px;
                    margin: 0 ;
                    p{
                        font: inherit;
                        color: inherit;
                        margin: 0 ;
                    }
                }
            }
       }
    }
}
.product_details_info + .product_reviews{
    padding-top: 60px;
}

.product-review-modal{
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.45);
    display: none;
    justify-content: center;
    align-items: center;

    &.open{
        display: flex;
    }

    .reviewProduct{
        width: 100%;
        max-width: 480px;
        padding: 30px 20px;
        border-radius: 10px;
        background-color: #fff;
        position: relative;

        .close-review {
            position: absolute;
            z-index: 2;
            top: 30px;
            right: 20px;
        }

        h3{
            line-height: 1;
            margin: 0 0 20px;
            font-weight: 500;
            @include letter-spacing(30);
            @include vs-spacing(font-size, 26px, 18px);
        }

        .stars{
            display: flex;
            gap: 5px;
            input[type="radio"]{
                display: none;
            }
            label {
                cursor: pointer;
                svg {
                    opacity: 0.25;
                    filter: grayscale(1);
                    transition: $transition;
                }

                &.highlight{
                    svg{
                        opacity: 1;
                        filter: none;
                    }
                }
            }                
        }
        textarea{
            background-color: transparent;
        }
        .button_row{
           display: flex;
           justify-content: flex-end;
           
           button{
                width: 100%;
                max-width: 180px;
                height: 44px;
           }
        }
    }
}