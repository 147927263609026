.recipeSlider{
    padding:  0 0 60px;
    @media (max-width: 600px){
        padding: 0 0 10px;
    }

    .recipre_card{
        .recipe_image{
            width: 100%;
            padding-top: 65%;
            position: relative;
            border-radius: 5px;
            overflow: hidden;

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .recipe_info{
            padding: 25px 0 0;
            .recipe_details{
                color: #A38C66;
                font-size: 12px;
                line-height: 1;
                font-weight: 500;
                text-transform: uppercase;
                @include letter-spacing(80);
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 10px;
                margin: 0 0 24px;

                .serving{
                    display: inline-block;
                    justify-content: center;
                    align-items: center;
                    background-color: #EBE6DE;
                    height: 18px;
                    border-radius: 10px;
                    padding: 0 6px;
                    line-height: 18px;
                }
            }
            .recipe_title{
                @include font-secondary;
                line-height: 1;
                margin: 0;
                @include vs-spacing(font-size, 32px, 28px);
            }
        }
    }
}