
.searchBar_overlay{
    position: fixed;
    z-index: 96;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(2px);
    display: none;
    pointer-events: none;

    &.active{
        display: block;
    }

    @media (max-width: 1200px){
        display: none;
    }
}
.searchBar_wrapper{
    position: fixed;
    z-index: 101;
    width: 100%;
    height: calc(100% - 60px);
    top: 60px;
    left: 0;
    display: none;

    &.open{
        display: block;
    }
    @media (max-width: 1200px){
        top: 0;
        height: 100%;
    }

    .searchSuggestions{
        position: fixed;
        z-index: 101;
        top: 60px;
        left: 0;
        background-color: #fff;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.16);
        @media (max-width: 1200px){
            width: 100%;
            height: 100%;
            top: 0;
            transform: none;
            padding: 30px 0;
        }

        .topbar{
            display: none;
            justify-content: space-between;
            align-items: center;
            gap: 20px;
            padding-bottom: 20px;
            @media(max-width: 1200px){                
                display: flex;
                padding: 0 20px;
            }

            form{
                width: calc(100% - 38px);
                position: relative;

                svg{
                    position: absolute;
                    z-index: 2;
                    top: 11px;
                    left: 12px;
                }
                input{
                    padding-left: 36px;
                    background-color: #F7F7F7;
                    border-radius: 10px;
                    box-shadow: inset 0px 2px 8px 0px rgba(0,0,0,0.05);
                }
            }
        }

        p{
            font-size: 18px;
            line-height: 1;
            font-weight: 500;
            @include letter-spacing(30);
            margin: 0 0 15px;
            color: #969191;
            @media (max-width: 1200px){
                padding: 10px 20px;
            }

            span{
                color: #000;
                word-break: break-all;
                word-wrap: break-word;
            }
        }

        .categories{
            @media (max-width: 1200px){
                padding: 0 20px;
            }
            .swiper-slide{
                border: 1px solid #969191;
                border-radius: 3px;
                height: 44px;
                padding: 0 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                width: auto;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                @include letter-spacing(10);
                color: #969191;
                transition: $transition;

                &:hover{
                    background-color: #969191;
                    color: #fff;
                }
            }
        }
        .suggested_products{
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 12px;
            padding: 30px 0 0;
            @media (max-width: 1200px){
                grid-template-columns: repeat(2, 1fr);
                padding: 30px 20px 0;
                height: calc(100vh - 350px);
                overflow: auto;
            }
            @media (max-width: 600px){
                grid-template-columns: 1fr;
            }

            .product_card_mini{
                border: 1px solid #E6E6E6;
                border-radius: 3px;

                &:nth-child(10){
                    display: none;

                    @media (max-width: 1200px){
                        display: block;
                    }
                }
            }
        }
        .see_all_prd{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px 0 0;
            display: none;

            a{
                display: flex;
                justify-content: center;
                align-items: center;
                grid-area: 10px;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                @include letter-spacing(10);

                img{
                    margin-left: 10px;
                }
            }
        }
    }
}

#cms-top ~ .searchBar_wrapper{
    .searchSuggestions{
        top: 108px;
    }
}