.product_archive{
    padding: 0 0 60px;
    .product_grid{
        display: grid;
        grid-template-columns: repeat(7, 1fr);
        gap: 20px;

        @media (max-width: 2000px){
            grid-template-columns: repeat(5, 1fr);
        }
        @media (max-width: 1600px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media (max-width: 1000px){
            grid-template-columns: repeat(3, 1fr);
        }
        @media (max-width: 700px){
            grid-template-columns: repeat(2, 1fr);
        }
    }
}