.burgermenu{
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: none;

    &.open{
        display: block;
    }

    .top{
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-bottom: 1px solid #F2F2F2;

        .left{
            width: calc(100% - 40px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding-left: 15px;
            font-size: 18px;
            line-height: 1;
            font-weight: 500;
            @include letter-spacing(30);
        }
        .right{
            width: 40px;
            height: 40px;
            border-left: 1px solid #F2F2F2;
            display: flex;
            justify-content: center;
            align-items: center;

            img{
                width: 16px;
                height: auto;
            }
        }
    }
    .menu{
        width: 100%;
        padding: 20px 0 100px;
        max-height: calc(100vh - 40px);
        overflow-y: auto;

        ul{
            display: grid;
            grid-template-columns: 1fr;
            gap: 30px;
            padding: 0 20px;

            li{
                font-size: 16px;
                line-height: 1;
                font-weight: 400;
                display: flex;
                justify-content: space-between;
                align-items: center;
                color: #1a1a1a;
            }
        }
    }
    h3{
        padding: 0 20px 30px;
        font-size: 20px;
        line-height: 1;
        margin: 0 0 15px;
        color: #1a1a1a;
        opacity: 0.65;
        font-weight: 600;
    }

    .shop_all{
        display: inline-block;
        font-size: 16px;
        line-height: 1;
        font-weight: 400;
        padding: 30px 20px;
    }

    .level_one{
        display: none;
        position: fixed;
        z-index: 9999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;

        &.open{
            display: block;
        }
        .top{
            .left{
                svg{
                    transform: rotate(180deg);
                }
            }
        }
    }
}

#cms-top ~ .burgermenu{
    .top{
        margin-top: 46px;
    }
    .menu{
        max-height: calc(100vh - 86px);
    }
}