.article_image_block{
    padding: 0 0 45px;
    .image_content{
        width: 100%;
        position: relative;
        
        &.vertical{            
            padding: 0 80px;
            @media (max-width: 600px){
             padding: 0;
            }

            .image_wrapper{
                padding-top: 124%;
            }
        }
        &.horizontal{
            padding: 0;
            .image_wrapper{
                padding-top: 65%;
            }
        }
        .image_wrapper{
            width: 100%;
            position: relative;
            border-radius: 5px;
            overflow: hidden;
            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .dual_image{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;

        .image_content{
            width: 100%;

            .image_wrapper{
                width: 100%;
                position: relative;
                padding-top: 65%;
                border-radius: 5px;
                overflow: hidden;
                img{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}