.privacy_header{
    background-color: #F7F7F7;
    padding: 90px 0 50px;
    @media (max-width: 1200px){
        padding: 40px 0 20px;
    }
    .container{
        @media (max-width: 1200px){
            width: calc(100% - 200px);
        }
        @media (max-width: 720px){
            width: calc(100% - 32px);
        }
    }

    @include breadcumb;

    h1{
        @include font-secondary;
        margin: 0 0 15px;
        @include vs-spacing(font-size, 48px, 28px);
        @include vs-spacing(line-height, 52px, 32px);
    }
    p{
        line-height: 1;
        margin: 0;
        @include vs-spacing(font-size, 18px, 14px);
    }
}

.privacy_page{
    padding: 60px 0;
    overflow: visible;


    .page_inner{
        display: flex;
        justify-content: flex-start;
        gap: 200px;
        @media (max-width: 1200px){
            justify-content: center;
        }

        .text_content{
            width: calc(100% - 480px);
            @media (max-width: 1200px){
                width: calc(100% - 168px);
            }
            @media (max-width: 720px){
                width: 100%;
            }

            h2, h3, h4, h5{
                margin: 0 0 20px;
                @include vs-spacing(font-size, 32px, 22px);
                @include vs-spacing(line-height, 40px, 32px);
            }

            p{
                margin: 0 0 30px;
                @include vs-spacing(font-size, 18px, 14px);
                @include vs-spacing(line-height, 24px, 20px);
            }

            ul{
                display: grid;
                grid-template-columns: 1fr;
                gap: 25px;

                li{
                    padding-left: 25px;
                    position: relative;
                    @include vs-spacing(font-size, 18px, 14px);
                    @include vs-spacing(line-height, 24px, 20px);

                    &::after{
                        content: "";
                        position: absolute;
                        z-index: 2;
                        top: 8px;
                        left: 0;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background-color: #000;
                    }
                }
            }
        }
        .page_scroll_links{
            width: 480px;
            position: sticky;
            top: 160px;
            height: fit-content;

            @media (max-width: 1200px){
                display: none;
            }

            ul{
                display: grid;
                grid-template-columns: 1fr;
                gap: 16px;
                max-height: 300px;
                overflow-y: auto;
                @include scroll_style;
                li{
                    color: #006CCE;
                    line-height: 1;
                    @include vs-spacing(font-size, 18px, 14px);
                    @include vs-spacing(line-height, 24px, 18px);
                }
            }
        }
    }
}


#cms-top ~ .privacy_page{
    .page_inner{
        .page_scroll_links{
            top: 190px;
        }
    }
}