.pagination{
    padding: 0 0 60px;
    @media (max-width: 1200px){
        padding: 0 16px 40px;
    }
    .inner{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 6px;
        padding: 30px 0 20px;
        border-top: 1px solid #E6E6E6;

        ul{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            gap: 6px;

            li{
                &:first-child{
                    margin-right: 10px;
                }
                &:last-child{
                    margin-left: 10px;
                }
               a{
                display: inline-flex;
                justify-content: center;
                align-items: center;
                height: 44px;
                border-radius: 3px;
                border: 1px solid #969191;
                color: #0E0E0E;
                font-size: 16px;
                line-height: 1;
                @include letter-spacing(10);
                font-weight: 600;
                padding: 0 25px;
                transition: $transition;
                @media (max-width: 600px){
                    height: 30px;
                    padding: 0 15px;
                }

                &.active, &:hover{
                    background-color: #0E0E0E;
                    border-color: #0E0E0E;
                    color: #fff;

                    svg{
                        path{
                            fill: #fff;
                        }
                    }
                }
               } 
            }
        }
    }
    p{
        font-size: 18px;
        line-height: 1;
        @include letter-spacing(30);
        font-weight: 500;
        text-align: center;
        color: #969191;
        margin: 0;
    }
}