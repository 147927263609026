.related_recipe{
    width: 100%;
    max-width: 100%;
    overflow: hidden;

    &.article-internal{
        border-bottom: 1px solid #EBE6DE;

        .slider{
            border: 0;
        }
    }

    &.trending{
        .hometitleBar{
            border-top: 1px solid #EBE6DE;
        }
        .slider{
            border-top: 0;
            border-bottom: 0;
        }
        
    }
    
    .slider{
        border-top: 1px solid #EBE6DE;
        border-bottom: 1px solid #EBE6DE;
        padding: 20px 0 36px;

        .swiper{
            @media (max-width: 1200px){
                overflow: visible;
            }
        }
    }

    .recipe_card{ 
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 15px;
        width: 100%;

        .image_wrapper{
            width: 35%;
            min-width: 35%;

            .img{
                width: 100%;
                padding-top: 100%;
                position: relative;
                border-radius: 5px;
                overflow: hidden;

                img{
                    position: absolute;
                    z-index: 2;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
        .text_wrapper{
            .category{
                font-size: 12px;
                line-height: 1;
                font-weight: 500;
                @include letter-spacing(80);
                color: #A38C66;
                text-transform: uppercase;
                margin: 0 0 20px;
            }
            .recipe_title{
                @include font-secondary;
                @include letter-spacing(-20);
                margin: 0;
                @include crop-text(3);
                @include vs-spacing(font-size, 20px, 18px);
                @include vs-spacing(line-height, 25px, 22px);
            }
        }
    }
}