.article_quote_block{   
    h4{          
        @include font-secondary;
        margin: 0;
        padding: 0 0 40px;
        font-weight: 600;
        text-align: center;
        @include vs-spacing(font-size, 38px, 32px);
        @include vs-spacing(line-height, 40px, 34px);

        @media (max-width: 600px){
            text-align: left;
        }
    }
}

.article_internal_components{
    .article_internal{
        blockquote{      
            @include font-secondary;
            margin: 0;
            padding: 0 0 40px;
            font-weight: 600;
            text-align: center;
            @include vs-spacing(font-size, 38px, 32px);
            @include vs-spacing(line-height, 40px, 34px);
    
            @media (max-width: 600px){
                text-align: left;
            }
            p, h1, h2, h3, h4, h5, h6{
                font: inherit;
                color: inherit;
                margin: 0;
                text-align: center;
            }
            p:first-of-type::first-letter {
                font: inherit;
                color: inherit;
            }
        }
    }
}