@mixin link {    
    a{
        font-size: 16px;
        line-height: 1;
        font-weight: 500;
        @include letter-spacing(10);
        color: #A38C66;
        text-decoration: underline;
        text-underline-offset: 2px;
    }
}

.user_account{
  .account_inner{
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    padding: 35px 0;
    @media (max-width: 900px){
        max-width: calc(100% - 40px);
    }
  }  
  @include breadcumb;
  .breadcrumbs{
    @media (max-width: 900px){
        display: none;
    }
    ul{
       li{
        &:last-child{
            pointer-events: none;
            color: #A38C66;

            &:hover{
                color: #A38C66;
            }
        }
       } 
    }
  }
  .backToPage{
    padding: 0 0 30px;
    font-size: 18px;
    line-height: 1;
    position: relative; 
    color: #0E0E0E;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    @media (max-width: 900px){
        padding-left: 20px;
    }
  }
  h1{
    line-height: 1;
    margin: 0 0 15px;
    font-weight: 500;
    @include vs-spacing(font-size, 30px, 24px);
    @media (max-width: 900px){
        padding: 0 16px;
    }
  }
  .account_content{
    .content_tabs{
        background-color: #fff;
        width: 100%;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border: 1px solid #E6E6E6;
        overflow: hidden;
        @media (max-width: 900px){
            display: none;
        }

        ul{
            display: flex;
            justify-content: flex-start;
            align-items: center;

            li{
                flex-grow: 1;
                height: 60px;
                border-right: 1px solid #E6E6E6;

                &:last-child{
                    border: 0;
                }

                a{
                    height: 60px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(10);
                    transition: $transition;
                    color: #1a1a1a;

                    &.active_link, &:hover{
                        background-color: #EBE6DE;
                    }
                    &.active_link{
                        color: #000;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .mobile_tab_nav{
        display: none;
        width: 100%;
        max-width: 480px;
        position: relative;
        padding: 0 0 30px;
        @media (max-width: 900px){
            display: block;
        }
        .selected{
            width: 100%;
            height: 44px;
            border-radius: 3px;
            border: 1px solid #969191;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 18px;
        }
        .dropdown{
            display: none;
            padding: 15px 18px;
            border: 1px solid #e6e6e6;
            top: 48px;
            left: 16px;
            position: absolute;
            width: calc(100% - 32px);
            z-index: 5;
            background-color: #fff;
            border-radius: 3px;
            box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.12);

            &.open{
                display: block;
            }

            ul{
                display: grid;
                grid-template-columns: 1fr;
                gap: 15px;

                li{    
    
                    a{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        @include letter-spacing(10);
                        transition: $transition;
    
                        &.active_link{
                            color: #A38C66;
                            pointer-events: none;
                        }
                    }
                }
            }
        }
    }
    .tab_content{
        background-color: #fff;
        border-radius: 10px;
        border: 1px solid #E6E6E6;
        position: relative;
        z-index: 3;
        top: -10px;
        box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.12);
        @media (max-width: 900px){
            box-shadow: none;
            border-radius: 0;
            top: 0;
        }

        .tab_section{
            padding: 30px 20px 20px;
            border-bottom: 1px solid #E6E6E6;
            position: relative;
            @media (max-width: 900px){
                border-bottom: 5px solid #E6E6E6;
            }

            &:last-child{
                padding-bottom: 30px;
                border-bottom: 0;
            }
            &.hidden{
                display: none;
            }
            .button_row{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 10px;
                padding-top: 26px;

                button{
                    height: 42px;
                }
            }
        }

        h3{
            font-size: 20px;
            font-weight: 600;
            line-height: 1;
            margin: 0 0 15px;
        }
        p{
            font-size: 14px;
            line-height: 18px;
            @include letter-spacing(30);
            margin: 0 0 15px;
            color: #969191;
        }
        .default_value, .start_process{
            display: flex;
            justify-content: space-between;
            align-items: center;
            @media (max-width: 900px){
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 10px;
            }

            p{
                font-size: 18px;
                line-height: 1;
                @include letter-spacing(10);
                color: #0E0E0E;
                margin: 0;
            }
            @include link;
        }
        .start_process{
            justify-content: flex-end;
            position: absolute;
            bottom: 20px;
            right: 20px;
            @media (max-width: 900px){
                justify-content: flex-start;
                position: relative;
                bottom: auto;
                right: auto;
            }

            .logout{
                opacity: 0.8;
                transition: $transition;

                &:hover{
                    color: red;
                    opacity: 1;
                }
            }
        }
        .account_forms{
            padding: 30px 20px 20px;

            h3{
                padding-bottom: 45px;
            }

            form{
                small{
                    display: none;
                }
            }

            &.address_form form{
                display: block;
                .form_grid{
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    column-gap: 20px;
                    @media(max-width: 720px){
                        grid-template-columns: 1fr;
                        gap: 0;
                    }
                }
                .button_row{
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    flex-wrap: wrap;
                    gap: 20px;
                    @media (max-width: 720px){
                        gap: 10px;
                    }

                    a{                        
                        @media (max-width: 720px){
                            min-width: 100%;
                        }
                    }
                }
                button{
                    min-width: 160px;
                    @media (max-width: 720px){
                        min-width: 100%;
                    }
                }
            }
            &.card_form{
                button{                    
                    appearance: none;
                    height: 36px;
                    border-radius: 4px;
                    cursor: pointer;
                    @include font-primary;
                    font-size: 16px;
                    line-height: 1;
                    @include letter-spacing(10);
                    box-sizing: border-box;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    -webkit-tap-highlight-color: transparent;
                    user-select: none;
                    background-color: #13262F;
                    border: 1px solid #13262F;
                    color: #fff;
                    min-width: 160px;
                    margin-top: 15px;

                    @media (max-width: 720px){
                        min-width: 100%;
                    }
                }
            }
        }
        .change_password_form_wrapper{
            .form-group:last-child{
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 20px;

                @media (max-width: 720px){
                    grid-template-columns: 1fr;
                }
            }
        }
        form{            
            .form_row{
                width: 100%;
            }
            @media (max-width: 900px){
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: flex-start;

                button{
                    padding-left: 20px;
                    padding-right: 20px;
                }
            }
            button{
                height: 42px;
                padding: 0 20px;
            }
        }
        .form-text {
            display: block;
            padding-top: 10px;

            ul{
                display: grid;
                grid-template-columns: 1fr;
                gap: 6px;

                li{
                    font-size: 12px;
                    line-height: 1.25;
                    opacity: 0.6;
                }
            }
        }
    }
  }
}

ul.preferences{
    display: grid;
    grid-template-columns: 1fr;
    gap: 30px;
    padding: 30px 0;
    width: 100%;

    @media (max-width: 600px){
        padding-top: 0;
    }
    li{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 18px;
        line-height: 1;
        font-weight: 400;
        @include letter-spacing(10);
        input{
            display: none !important;
        }

        label{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
        }

        input:checked + label{
            svg{
                .box{
                 fill: #13262f;
                } 
             }
        }

        span{                        
            cursor: pointer;
            color: #222222;
        }

        svg{
            cursor: pointer;
           .box{
            fill: #fff;
           } 
        }

    }
}
.button_right{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    @media (max-width: 600px){
        button{
            width: 100%;
        }
    }
}

.single_order{
    .order_info{
        background-color: #F7F7F7;
        @media (max-width: 900px){
            background-color: #fff;
            padding: 20px 16px 0;
        }
        ul{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            height: 70px;
            padding: 0 20px;
            @media (max-width: 900px){
                flex-direction: column;
                align-items: flex-start;
                height: auto;
                gap: 8px;
                padding: 0 0 20px;
                border-bottom: 1px solid #E6E6E6;
            }

            li{
                min-width: 180px;
                display: grid;
                grid-template-columns: 1fr;
                gap: 8px;
                @media (max-width: 900px){
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                span{
                    font-size: 16px;
                    line-height: 1;
                    @include letter-spacing(30);
                    color: #969191;
                    &:last-child{
                        color: #000;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .order_products{
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 30px;
        padding: 15px 20px;
        @media (max-width: 900px){
            flex-wrap: wrap;
            padding: 15px 16px;
            gap: 5px;
            border-bottom: 5px solid #F7F7F7;
        }

        .left{
            width: calc(100% - 185px);
            @media (max-width: 900px){
                width: 100%;
            }

            .product_list{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                .product{
                    width: 60px;
                    height: 60px;
                    border-radius: 5px;
                    overflow: hidden;
                    border: 1px solid #F2F2F2;
                    a{
                        display: block;
                        width: 100%;
                    }
                    .product_image{
                        width: 100%;
                        height: 60px;
                        position: relative;

                        img{
                            position: absolute;
                            z-index: 2;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                            width: 80%;
                            height: 80%;
                            object-fit: contain;
                        }
                    }
                }
                .more_products{
                    width: 60px;
                    height: 60px;
                    border-radius: 5px;
                    overflow: hidden;
                    border: 1px solid #F2F2F2;
                    background-color: #F2F2F2;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 500;
                }
            }

            p{
                font-size: 16px;
                line-height: 24px;
                @include letter-spacing(30);
                color: #969191;
                padding: 24px 0 0;

                a{
                    color: #A38C66;
                    font-weight: 500;
                    text-decoration: underline;
                    text-underline-offset: 2px;
                }
            }
        }

        .right{
            width: 155px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
            @media (max-width: 900px){
                width: 100%;
                grid-template-columns: repeat(2, 1fr);
            }

            button{
                width: 100%;
            }
        }
    }
}
.see_all{
    width: 100%;
    padding: 18px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #E6E6E6;

    @media (max-width: 900px){
        border-color: #f7f7f7;
    }

    a{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }
}

.payment_wallet{
    padding: 30px 20px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 30px;
    border-bottom: 5px solid #F7F7F7;
    
    .left{
        width: calc(100% - 185px);

        p{
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            @include letter-spacing(30);
            margin: 0 0 15px;
            color: #000;
        }
        h4{
            font-size: 30px;
            line-height: 1;
            font-weight: 500;
            margin: 0;
        }

    }
    .right{
        width: 155px;

        button{
            width: 100%;
        }
    }
}
.payment_section{
    h3{        
        padding: 20px 20px;
    }
    .saved_card{
        padding: 20px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 20px;
        border-bottom: 1px solid #E6E6E6;

        .card{
            width: calc(100% - 80px);
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 25px;

            @media (max-width: 600px){
                width: 100%;
                min-width: 100%;
            }

            .card_info{
                display: grid;
                grid-template-columns: 1fr;
                gap: 5px;

                .card_type{
                    text-transform: capitalize;
                }

                span{
                    font-size: 16px;
                    line-height: 1;
                    @include letter-spacing(30);
                    color: #969191;

                    &:last-child{
                        color: #000;
                        font-weight: 500;
                    }
                }
            }
        }
        .manage{
            min-width: 60px;
            flex-grow: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 10px;

            @media (max-width: 600px){
                width: 100%;
                min-width: 100%;
            }

            @include link;

            a{
                &:first-child{
                    min-width: 103px;
                }
            }
            span{
                &:first-child{
                    min-width: 90px;
                }
            }
        }
    }
}

.add_new{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 25px 20px;

    button{
        padding-left: 20px;
        padding-right: 20px;
    }
}

.saved_addresses{
    .single_address{
        padding: 20px 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: wrap;
        gap: 20px;
        border-bottom: 1px solid #E6E6E6;
        width: 100%;

        .address{
            width: calc(100% - 180px);
            display: grid;
            grid-template-columns: 1fr;
            gap: 5px;

            @media (max-width: 600px){
                width: 100%;
                min-width: 100%;
            }

            span{
                font-size: 16px;
                line-height: 1;
                @include letter-spacing(30);
                color: #969191;

                &:last-child{
                    color: #000;
                    line-height: 1.25;
                    font-weight: 500;
                }
            }
        }

        .manage{
            width: 160px;
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            justify-content: center;
            align-items: flex-end;
            gap: 10px;

            @media (max-width: 720px){
                width: 100%;
                min-width: 100%;
                flex-direction: row;
                justify-content: flex-start;

                span{
                    position: relative;
                    top: 4px;

                }

                .bottom{
                    position: relative;
                    top: 3px;
                }
            }

            @include link;
        }
    }
}
.user_pref{
    padding: 30px 20px;

    .button_row{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        button{
            min-width: 105px;

            &.red{
                background-color: #ee4135;
                border-color: #ee4135;
            }
        }
    }
}

.order_cards{
    background-color: #fff;
    border: 1px solid #E6E6E6;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 10px;
    box-shadow: 0px -1px 4px 0px rgba(0,0,0,0.12);

    @media (max-width: 900px){
        width: calc(100% - 40px);
        margin: 0 auto 10px;
    }

    .top{
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 15px;
        padding-bottom: 30px;

        @media (max-width: 720px){
            flex-direction: column;
            justify-content: flex-start;
        }

        h1{
            margin: 0;
            text-transform: capitalize;
            @media (max-width: 900px){
                padding: 0 !important;
            }
        }

        .giftStatus{
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;

            span{
                color: #e12c2c;
            }
        }
    }

    h1{
        font-size: 28px;
        line-height: 1;
        font-weight: 500;
        @include letter-spacing(-10);
        margin: 0 0 25px;

        &.green{
            color: #108910;
        }
    }
    h3{
        font-size: 22px;
        line-height: 1;
        font-weight: 500;
        @include letter-spacing(30);
        margin: 0 0 20px;
        color: #373737;
    }
    > div{
        padding: 20px;
        > p{
            font-size: 14px;
            line-height: 1.35;
            font-weight: 500;
            margin: 0;
            color: #969191;
            @include letter-spacing(30);
        }
    }
    .order_details{
        background-color: #F7F7F7;

        .order_info{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 720px){
                flex-direction: column;
                gap: 15px;
            }

            .left{
                flex-grow: 1;
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                @media (max-width: 720px){
                    flex-direction: column;
                    gap: 15px;
                    width: 100%;
                }
            }
            .right{
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
                @media (max-width: 720px){
                    justify-content: flex-start;
                    align-items: flex-start;
                    width: 100%;
                }

                .column{
                    span{
                        display: block;
                        text-align: right;
                        @media (max-width: 720px){
                            text-align: left;
                        }
                    }
                }
            }

            .column{
                min-width: 150px;
                display: grid;
                grid-template-columns: 1fr;
                gap: 8px;
                @media (max-width: 720px){
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                }

                span{
                    font-size: 16px;
                    line-height: 1;
                    @include letter-spacing(30);
                    &:first-child{
                        color: #969191;
                    }
                    &:last-child{
                        color: #000;
                        font-weight: 500;
                    }
                }
            }
        }
    }
    .product_list{
        background-color: #fff;
        display: grid;
        grid-template-columns: 1fr;
        gap: 15px;
        padding: 30px 20px 10px;
        @media (max-width: 600px){
            gap: 25px;
        }

        &.unfullfilled{
            padding: 10px 20px 30px;
        }

        .product{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            gap: 20px;

            .left{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 24px;
               .img{
                    width: 80px;
                    min-width: 80px;
                    height: 80px;
                    border: 1px solid #F2F2F2;
                    border-radius: 5px;

                    img{
                        width: 100%;
                        height: 100%;
                        object-fit: contain;
                    }
               } 
               .details{
                    .product_title{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        margin: 0 0 10px;
                    }
                    .product_info{
                        font-size: 14px;
                        line-height: 1;
                        font-weight: 400;
                        margin: 0 0 20px;
                        color: #969191;
                        @include letter-spacing(30);
                        text-transform: capitalize;
                    }
                    .product_cost{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        margin: 0;
                        @include letter-spacing(30);
                    }
               }
            }
            .right{
              .product_qty{
                font-size: 18px;
                line-height: 1;
                font-weight: 500;
                margin: 0;
                @include letter-spacing(30);
                width: 70px;
                height: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border: 1px solid #E6E6E6;
                border-radius: 5px;
                @media (max-width: 600px){
                    width: 45px;
                }
              }  
            }
        }
    }
    .default{
        background-color: #FAFAFA;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding: 20px;

        h4{
            font-size: 16px;
            line-height: 1;
            @include letter-spacing(15);
            margin: 0 0 12px;
        }
        p{
            font-size: 14px;
            line-height: 1.35;
            @include letter-spacing(30);
            color: #585858;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 15px;       
            margin: 0;     
        }
    }
    .order_summary{
        ul{
            display: grid;
            grid-template-columns: 1fr;
            gap: 15px;
            border-top: 1px solid #F0F0F0;
            padding-top: 20px;
            li{
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                @include letter-spacing(40);

                &:last-child{
                    border-top: 1px solid #F0F0F0;
                    padding: 24px 0 0;
                    font-size: 20px;
                    font-weight: 500;
                    @include letter-spacing(-20);
                }
            }
        }
    }
    
    .manage_btns{
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;

        button{
            width: 100%;
            min-width: 120px;
            max-width: 200px;
        }
    }
}
.wishlist_products{
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    .product_card_mini{   
        border-bottom: 1px solid #F2F2F2;
        padding-bottom: 10px;  
        width: 100%;   
        &:last-child{
            border-bottom: 0;
        }
        .product_image{
            border: 1px solid #F2F2F2;
            border-radius: 5px;
            overflow: hidden;
        }
        .product_text{
            flex-grow: 1;
            padding-right: 36px;
            padding-top: 5px;
            justify-content: flex-start;
            .product_title{
                font-size: 18px;
                @include vs-spacing(font-size, 18px, 16px);
            }
        }
        .remove_icon{
            width: 18px;
            height: auto;
            position: absolute;
            z-index: 10;
            top: 10px;
            right: 10px;
        }
    }
}

.confirm-plan-change{
    position: fixed;
    z-index: 102;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.open{
        display: block;
    }

    .overlay-plan-change{
        position: absolute;
        z-index: 103;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        opacity: 0.6;
    }
    .inner{
        position: absolute;
        z-index: 104;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        background-color: #fff;
        box-shadow: 0 -1px 4px 0 rgba(0, 0, 0, .12);
        width: 100%;
        max-width: 520px;
        padding: 30px 18px;
        border: 1px solid #E6E6E6;
        border-radius: 10px;

        .top{
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3{
                font-size: 24px;
                line-height: 1;
                font-weight: 500;
                margin: 0;
            }

            svg{
                cursor: pointer;
            }
        }
        .buttons_row{
            border-top: 1px solid #E6E6E6;
            padding: 15px 0 0;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 10px;

            button{
                text-transform: capitalize;
            }
        }
    }
}