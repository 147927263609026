.article_text_block, .ingredients_block{
   padding: 0; 
   @include article_text;
   p{
    &:last-child{
        padding: 0;
    }
   }
}

.ingredients_block{
    li.has_product_link{
        position: relative;

        > div{
            position: relative;
            display: inline-block;
            cursor: pointer;

            > span{                
                text-decoration: underline;
                text-underline-offset: 2px;
                text-decoration-color: #A38C66;
            }

            &:hover{
                .product_card_mini{
                    pointer-events: all;
                    opacity: 1;
                }
            }
        }

        .product_card_mini{
            position: absolute;
            z-index: 5;
            top: 98%;
            box-shadow: 0px 3px 6px 0px rgba(0,0,0,0.16);
            min-width: 320px;
            opacity: 0;
            pointer-events: none;
            transition: $transition;
        }
    }
}