.cart{
    position: fixed;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &.open{
        display: block;
    }

    .cart_overlay{
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.6;
        cursor: pointer;
    }
    .cart_sidebar{
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        width: 475px;
        height: 100dvh;
        max-height: 100dvh;
        background-color: #fff;
        border-left: 1px solid #E6E6E6;
        transform: translateX(250%);
        transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);
        @media (max-width: 580px){
            width: 100%;
        }

        &.open{
            transform: none;
        }

        .topbar{
            width: 100%;
            height: 60px;
            border-bottom: 1px solid #E6E6E6;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 30px;
            position: sticky;
            top: 0;
            .gift{
                input{
                    display: none;
                }
                label{                    
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    cursor: pointer;
                    font-weight: 500;

                    .checkbox{
                        .box{
                            stroke: #969191;
                            fill: #fff;
                        }
                    }
                }
                input:checked + label{                    
                    .checkbox{
                        .box{
                            stroke: #a38c66;
                            fill: #a38c66;
                        }
                    }
                }
            }
            h3{
                font-size: 20px;
                font-weight: 600;
                line-height: 32px;
                margin: 0;
            }
        }

        .middleContent{
            overflow-y: auto;
            width: 100%;
            height: auto;
            max-height: calc(100vh - 211px);
            @include scroll_style;
        }

        .store{
            padding: 25px 0 0;
            h3{
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;
                padding: 0 20px;
                font-size: 20px;
                font-weight: 700;
                line-height: 32px;
                margin: 0 0 30px;
                color: #1A1A1A;
            }
            .product_list{
                padding: 0 20px;
            }
            .product{
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 20px;
                padding: 0 0 30px;

                .products_image{
                    width: 60px;
                    height: 60px;

                    .image_wrapper{
                        width: 60px;
                        height: 60px;

                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                }
                .product_info{
                    flex-grow: 1;
                    padding-right: 40px;
                    .product_title{
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 500;
                        margin: 0 0 8px;
                    }
                    .product_details{
                        font-size: 14px;
                        line-height: 1;
                        font-weight: 400;
                        color: #969191;
                        margin: 0 0 12px;
                    }
                    .product_cost{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 600;
                        margin: 0;
                        @include letter-spacing(30);
                    }
                }
                .cart_action{
                    width: 70px;
                    .product_qty{
                        width: 70px;
                        height: 40px;
                        border-radius: 5px;
                        position: relative;

                        .right{
                            position: absolute;
                            z-index: 2;
                            top: 0;
                            right: 0;
                            display: grid;
                            grid-template-columns: 1fr;

                            a{
                                width: 24px;
                                height: 20px;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                            }
                        }
                    }
                }
            }
        }
        .delivery_fee{
            padding: 0 20px;
            height: 34px;
            background-color: #EBE6DE;
            color: #A38C66;
            font-size: 16px;
            line-height: 1;
            font-weight: 500;
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .bottombar{
            position: absolute;
            z-index: 10;
            bottom: env(safe-area-inset-bottom, 0);
            right: 0;
            padding: 0 0 20px;
            width: 100%;
            background-color: #fff;
            border-top: 6px solid #fff;
            padding-bottom: env(safe-area-inset-bottom);

            .derlivery_slot{
                padding: 0 20px;
                height: 34px;
                background-color: #EBE6DE;
                color: #A38C66;
                border-top: 1px solid #E6E6E6;
                font-size: 16px;
                line-height: 1;
                font-weight: 500;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.16);

                .left{
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;

                    span{
                        font-weight: 500;
                    }
                }
                .right{
                    a{
                        font-size: 16px;
                        line-height: 1;
                        font-weight: 500;
                        color: #A38C66;
                        text-decoration: underline;
                        text-underline-offset: 2px;
                    }
                }
            }
            .cart_summary{
                padding: 20px 20px 15px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 18px;
                line-height: 1;
                font-weight: 500;
                @include letter-spacing(30);

                > span{
                    &:first-child{
                        color: #969191;
                    }
                }
            }
            .cart_actions{
                padding: 0 20px 20px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 5px;

                button{
                    width: 154px;
                    height: 44px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-color: #13262F;
                    font-size: 16px;
                    line-height: 1;
                    font-weight: 600;

                    &:first-child{
                        border-width: 2px;
                    }

                    &:last-child{
                        flex-grow: 1;
                    }
                }
            }
        }
    }
    .extra_products{
        background-color: #F7F7F7;
        width: 360px;
        height: 100vh;
        position: absolute;
        z-index: 8;
        top: 0;
        right: 475px;
        transform: translateX(250%);
        transition: all 0.75s cubic-bezier(0.19, 1, 0.22, 1);

        &.open{
            transform: none;
        }

        @media (max-width: 900px){
            display: none;
        }

        .top{
            padding: 44px 20px 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-end;

            h3{
                font-size: 20px;
                font-weight: 600;
                line-height: 32px;
                margin: 0 ;
            }
            a{
                font-size: 14px;
                line-height: 1;
                font-weight: 500;
                text-decoration: underline;
                color: #969191;
            }
        }
        .products_list{
            padding: 20px;
            display: grid;
            grid-template-columns: 1fr;
            gap: 10px;
            height: 100dvh;
            max-height: calc(100dvh - 80px);
            overflow-y: auto;
            @include scroll_style;

            .product_card_mini{
                border: 1px solid #E6E6E6;
                border-radius: 3px;
            }
        }
    }

    .cart_timeslots{
        position: absolute;
        z-index: 10;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        transform: translateY(200vh);
        transition: $transition;
        
        &.open{
            transform: none;
        }

        .topbar{
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3{
                margin: 0;
            }

            .cart-timeslot{
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .middle{
            width: 100%;
            padding: 25px 0;
            height: 100vh;
            max-height: calc(100vh - 135px);

            .slot_days{
                padding: 0 20px;
            }
            .slot_times{
                padding: 20px;
            }
        }
        .bottom{
            width: 100%;
            padding: 15px 0 15px;
            position: absolute;
            z-index: 2;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 1px solid #e6e6e6;

            @media (max-width: 1200px){
                padding: 15px 0 40px;
            }

            button{
                width: calc(100% - 40px);
                height: 44px;
            }
        }
    }
}


#cms-top ~ .cart{
    .cart_sidebar{
        .topbar{
            top: 46px;
        }
        .middleContent, .middle{
            position: relative;
            top: 46px;
        }
        .middle{            
            max-height: calc(100vh - 180px);
        }
    }
    .extra_products {
        .top{
            padding: 65px 20px 0;
        }
        .products_list{
            max-height: calc(100dvh - 90px);
        }
    }
}
