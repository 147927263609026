.featured_recipe{
    padding: 60px 0;
    @media (max-width: 600px){
        padding: 60px 0 20px;
    }

    .inner{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
        width: 100%;
        @media (max-width: 720px){
            grid-template-columns: 1fr;
        }

        a{
            position: relative;
            padding-top: 100%;
        }

        .image_container{
            background-color: #EBE6DE;
            border-radius: 5px;
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            @media (max-width: 1200px){
                border-radius: 5px;
                overflow: hidden;
            }

            img{
                position: absolute;
                z-index: 2;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .text_container{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: column;
            padding-left: 120px;

            @media (max-width: 1400px){
                padding-left: 60px;
            }
            @media (max-width: 1200px){
                padding-left: 0;
            }

            .top{
                .tagline{
                    font-size: 14px;
                    line-height: 1;
                    font-weight: 500;
                    @include letter-spacing(100);
                    color: #A38C66;
                    text-transform: uppercase;
                    margin:  0 0 30px;
                    @media (max-width: 1200px){
                        text-align: center;
                    }
                }
                h3{
                    @include font-secondary;
                    line-height: 1;
                    margin: 0;
                    @include vs-spacing(font-size, 48px, 30px);
                    @media (max-width: 1200px){
                     margin: 0 0 20px;
                     text-align: center;
                    }
                }
            }
            .bottom{
                .description{
                    font-weight: 400;
                    margin: 0 0 20px;
                    @include vs-spacing(font-size, 18px, 14px);
                    @include vs-spacing(line-height, 26px, 22px);
                    @media (max-width: 1200px){
                        text-align: center;
                    }
                }
                .readMore{
                    @media (max-width: 1200px){
                        display: none;
                    }

                }
            }
        }
    }
}